import React from "react";
import Logo from "../../assets/logos/e-timer_logo2.png";
import EldLogo from "../../assets/logos/Logo-ELD-02 transparent.png";
import { Link } from "react-router-dom";
import { dictionary } from "../../dictionary";

const TopBar = () => {
  const isLogged = sessionStorage.getItem("isLoggedIn");
  return (
    <div className="nav">
      <span className="logo-wrapper">
        <img src={Logo} alt="logo" />
        <img id="eld_logo" src={EldLogo} alt="logo2" />
      </span>
      {!isLogged ? (
        <div className="nav-btn-wrapper">
          <Link id="log_in" to="/access">
            {dictionary.sign_in}
          </Link>
        </div>
      ) : (
        <div className="nav-btn-wrapper">
          <Link id="sign_up" to="/timesheet">
            {dictionary.your_timesheet}
          </Link>
        </div>
      )}
    </div>
  );
};

export default TopBar;
