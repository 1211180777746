export const SHOW_ELD_DIALOG = "SHOW_ELD_DIALOG";
export const HIDE_ELD_DIALOG = "HIDE_ELD_DIALOG";
export const SHOW_INT_DIALOG = "SHOW_INT_DIALOG";
export const HIDE_INT_DIALOG = "HIDE_INT_DIALOG";
export const SHOW_CTY_DIALOG = "SHOW_CTY_DIALOG";
export const HIDE_CTY_DIALOG = "HIDE_CTY_DIALOG";
export const SHOW_LOG_DIALOG = "SHOW_LOG_DIALOG";
export const HIDE_LOG_DIALOG = "HIDE_LOG_DIALOG";
export const SHOW_OTH_DIALOG = "SHOW_OTH_DIALOG";
export const HIDE_OTH_DIALOG = "HIDE_OTH_DIALOG";

export const showEldDialog = () => {
  return {
    type: SHOW_ELD_DIALOG,
  };
};

export const hideEldDialog = () => {
  return {
    type: HIDE_ELD_DIALOG,
  };
};

export const showIntDialog = () => {
  return {
    type: SHOW_INT_DIALOG,
  };
};

export const hideIntDialog = () => {
  return {
    type: HIDE_INT_DIALOG,
  };
};

export const showCtyDialog = () => {
  return {
    type: SHOW_CTY_DIALOG,
  };
};

export const hideCtyDialog = () => {
  return {
    type: HIDE_CTY_DIALOG,
  };
};

export const showLogDialog = () => {
  return {
    type: SHOW_LOG_DIALOG,
  };
};

export const hideLogDialog = () => {
  return {
    type: HIDE_LOG_DIALOG,
  };
};

export const showOthDialog = () => {
  return {
    type: SHOW_OTH_DIALOG,
  };
};

export const hideOthDialog = () => {
  return {
    type: HIDE_OTH_DIALOG,
  };
};