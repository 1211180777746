import React from "react";
import { dictionary } from "../../../../dictionary";

const LogisticSum = (props) => {
  const properties = props.properties;
  const styles = props.styles;
  const sum =
    properties.summaryHoursLogistic +
    properties.summaryHoursLogisticCountry +
    properties.espLogisticsSum;

  return (
    <>
      <tr>
        <th className="logistics_modules" style={styles.logistics_modules}>
          {dictionary.productivity_table_summary_log_sum}
        </th>
        <td className="logistics_modules" style={styles.logistics_modules}></td>
        <td className="logistics_modules" style={styles.logistics_modules}>
          {sum.toString().replace(".", ",")}
        </td>
        <td className="logistics_modules" style={styles.logistics_modules}>
          0
        </td>
        <td className="logistics_modules" style={styles.logistics_modules}>
          {properties.summaryHoursLogistic.toString().replace(".", ",")}
        </td>
        {properties.countries.map((country, idx) => {
          return (
            <td
              key={idx}
              className="logistics_modules"
              style={styles.logistics_modules}
            >
              {(
                properties.summaryHoursLogisticsSingleCountry(country) +
                properties.espLogisticsCountrySum(country)
              )
                .toString()
                .replace(".", ",")}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default LogisticSum;
