import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import ObjectID from "bson-objectid";
import {
  clearClient,
  clearHour,
  clearModule,
  setClient,
  setHour,
  setModule,
  clearWorkingH,
  clearWpl,
  setWorkingH,
  setWpl,
} from "../../../redux/actions/logActions";
import { dictionary } from "../../../dictionary";
import { useSelector, useDispatch } from "react-redux";
import { fetchInitialPosts } from "../../../redux/thunk/thunk";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { format } from "date-fns";

const WorkPlanner = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contentProps = props.contentProps;
  const [wplArr, setWplArr] = useState(null);
  const clientLog = useSelector((state) => state.client.value);
  const espLog = useSelector((state) => state.esp.value);
  const ctyLog = useSelector((state) => state.cty.value);
  const modTypeLog = useSelector((state) => state.modType.value);
  const stageLog = useSelector((state) => state.stage.value);
  const taskLog = useSelector((state) => state.task.value);
  const hourLog = useSelector((state) => state.hours.value);
  const commentLog = useSelector((state) => state.comment.value);
  const workingHLog = useSelector((state) => state.wh.value);
  const wplLog = useSelector((state) => state.wpl.value);
  const moduleLog = useSelector((state) => state.module.value);
  const [dayClicked, setDayClicked] = useState(false);
  const [startHour, setStartHour] = useState("00:00");
  const [endHour, setEndHour] = useState("00:00");
  const keysToInclude = [
    "eld",
    "ftf",
    "logistics",
    "ma",
    "nuggets",
    "nuggetsint",
    "projects_countries",
    "projects_int",
    "projects_logistics",
  ];
  const absencePicker = [{ name: "Urlop" }, { name: "Nieobecność" }, { name: "Wolne moce" }];
  const [projects, setProjects] = useState(null);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [workSchedule, setWorkSchedule] = useState([]);
  const [plannerHelper, setPlannerHelper] = useState(false);
  const disabledDays = [0, 6];
  const [selectedDays, setSelectedDays] = useState([]);
  const currentYear = new Date().getFullYear();
  const isHolidays = getHolidays(currentYear, currentYear + 5);
  const [disabledElements, setDisabledElements] = useState(isHolidays);
  const [disabledOkBtn, setDisabledOkBtn] = useState(true);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [dropdowndisabled, setDropdownDisabled] = useState(false);

  function getHolidays(startYear, endYear) {
    const holidays = [];
    for (let year = startYear; year <= endYear; year++) {
      holidays.push(
        new Date(year, 0, 1),
        new Date(year, 0, 6),
        new Date(year, 3, 1),
        new Date(year, 4, 1),
        new Date(year, 4, 3),
        new Date(year, 4, 19),
        new Date(year, 4, 30),
        new Date(year, 5, 8),
        new Date(year, 7, 15),
        new Date(year, 10, 1),
        new Date(year, 10, 11),
        new Date(year, 11, 25),
        new Date(year, 11, 26)
      );
    }
    return holidays;
  }

  const generateUniqueId = () => {
    const timestamp = new Date().getTime().toString();
    const randomStr = Math.random().toString(36).substring(2);
    return `${timestamp}${randomStr}`;
  };

  useEffect(() => {
    if (contentProps.eldData !== null) {
      setWplArr(contentProps.eldData[0]?.perso?.slice(7));
      const intother = contentProps.eldData[0].int[0].other;
      const filteredData = keysToInclude.map(
        (key) => contentProps.eldData[0][key]
      );
      setProjects(filteredData.flat().concat(intother, absencePicker));
    }
  }, [contentProps.eldData]);

  const handleDaySelect = (e) => {
    setDayClicked(true);
    setDisabledOkBtn(true);
    setDisabledSaveBtn(true);
    const { value } = e;
    const clickedDay = new Date(value);
    clickedDay.setHours(0, 0, 0, 0);
    const isDisabled = disabledElements.some((day) => {
      const disabledDay = new Date(day);
      disabledDay.setHours(0, 0, 0, 0);
      return disabledDay.getTime() === clickedDay.getTime();
    });

    if (isDisabled) {
      const updateDisabledDays = disabledElements.filter((day) => {
        const disabledDay = new Date(day);
        disabledDay.setHours(0, 0, 0, 0);
        return disabledDay.getTime() !== clickedDay.getTime();
      });
      setDisabledElements(updateDisabledDays);
    } else {
      setDisabledElements([...disabledElements, clickedDay]);
    }
    setSelectedDays(e.value);
    setPlannerHelper(true);
  };

  const reset = () => {
    setDisabledSaveBtn(false);
    setSelectedDays([]);
    setPlannerHelper(false);
    dispatch(clearHour());
    dispatch(clearWorkingH());
    dispatch(clearWpl());
    dispatch(clearModule());
    setDropdownDisabled(false);
    setDayClicked(false);
    setStartHour("00:00");
    setEndHour("00:00");
  };

  const handleOK = () => {
    let temp = {
      id: generateUniqueId(),
      day: selectedDays,
      workH: workingHLog,
      workP: wplLog,
      other: moduleLog,
    };
    setDisabledSaveBtn(false);
    setWorkSchedule([...workSchedule, temp]);
    setSelectedDays([]);
    setPlannerHelper(false);
    dispatch(setWorkingH(startHour, endHour));
    setDayClicked(false);
    setDropdownDisabled(false);
  };

  const handleDelete = (elem) => {
    setWorkSchedule(workSchedule.filter((entry) => entry.id !== elem.id));
    setDisabledElements(
      disabledElements.filter(
        (entry) => entry.getTime() !== elem.day[0].getTime()
      )
    );
  };

  const handleSave = () => {
    let logsToSave = [];
    workSchedule.forEach((sch) => {
      let log = {
        id: ObjectID().toHexString(),
        user: contentProps.loggedUser,
        client:
          sch.other === "Urlop" || sch.other === "Nieobecność"
            ? clientLog === null
              ? ""
              : clientLog.toUpperCase()
            : "",
        esp: espLog === null ? "" : espLog,
        country: ctyLog === null ? "" : ctyLog,
        module_type: modTypeLog === null ? "" : modTypeLog,
        module: sch.other === null ? "" : sch.other,
        stage: stageLog === null ? "" : stageLog,
        task: taskLog === null ? "" : taskLog,
        hours:
          sch.other === "Urlop" || sch.other === "Nieobecność"
            ? 8
            : hourLog === null
            ? 0
            : hourLog,
        wpl: sch.workP === null ? "" : sch.workP,
        workingh: sch.workH === null ? "" : sch.workH,
        comment: commentLog === null ? "" : commentLog,
        date: contentProps.fixDateLog(sch.day[0]),
        cw: contentProps.getCw(contentProps.fixDateLog(sch.day[0])),
      };
      logsToSave.push(log);
    });
    Promise.all(
      logsToSave.map((log) =>
        fetch("https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts", {
          method: "POST",
          mode: 'cors',
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(log),
        })
      )
    ).then(() => {
      dispatch(fetchInitialPosts(contentProps.loggedUser));
    });
    contentProps.setValuesOnInitialState();
    setSelectedDays([]);
    setDisabledElements(isHolidays);
    navigate("/timesheet");
  };

  const handleDisablePlannedDates = () => {
    const covertedDisabledDates = contentProps.userPosts.map((post) => {
      const [year, month, day] = post.date.split("-");
      return new Date(year, month - 1, day);
    });
    setDisabledElements([...disabledElements, ...covertedDisabledDates]);
  };

  useEffect(() => {
    handleDisablePlannedDates();
  }, [contentProps.userPosts]);

  useEffect(() => {
    const isModuleLogLeave =
      moduleLog === "Urlop" || moduleLog === "Nieobecność";
    const isWorkingHLogAndWplLogFilled =
      workingHLog !== null && wplLog !== null;

    setDropdownDisabled(isModuleLogLeave);
    setDisabledOkBtn(!(isModuleLogLeave || isWorkingHLogAndWplLogFilled));
  }, [workingHLog, wplLog, moduleLog, dayClicked]);

  return (
    <div className="workplanner-wrapper">
      <Dialog
        header={dictionary.work_planner_header}
        visible={true}
        onHide={() => {
          reset();
          navigate("/timesheet");
        }}
        style={{ width: "50vw" }}
        closable="false"
      >
        <div className="p-field">
          <label>{dictionary.work_planner_calendar_label}</label>
          <Calendar
            disabledDays={disabledDays}
            inline
            selectionMode="multiple"
            value={selectedDays}
            onChange={handleDaySelect}
            disabledDates={disabledElements}
          />
          {plannerHelper ? (
            <div className="planner-helper-wrapper">
              <label>{dictionary.work_planner_form_hours}</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="time-picker-wrapper">
                  <div className="col col-start">
                    <MobileTimePicker
                      label={
                        startHour
                          ? startHour
                          : dictionary.work_planner_form_hours_start
                      }
                      openTo="hours"
                      ampm={false}
                      disabled={dropdowndisabled}
                      onChange={(newValue) => {
                        const formattedValue = format(newValue.$d, "HH:mm");
                        setStartHour(formattedValue);
                        dispatch(setWorkingH(formattedValue, endHour));
                      }}
                    />
                  </div>
                  <div className="col">
                    <MobileTimePicker
                      label={
                        endHour
                          ? endHour
                          : dictionary.work_planner_form_hours_end
                      }
                      openTo="hours"
                      ampm={false}
                      disabled={dropdowndisabled}
                      onChange={(newValue) => {
                        const formattedValue = format(newValue.$d, "HH:mm");
                        setEndHour(formattedValue);
                        dispatch(setWorkingH(startHour, formattedValue));
                      }}
                    />
                  </div>
                </div>
              </LocalizationProvider>
              <label>{dictionary.work_planner_form_work}</label>
              <Dropdown
                style={{ width: "100%" }}
                value={wplLog}
                options={wplArr}
                disabled={dropdowndisabled}
                onChange={(e) => {
                  dispatch(setWpl(e.value.name));
                  dispatch(clearClient());
                }}
                optionLabel="name"
                placeholder={
                  wplLog !== null
                    ? wplLog
                    : dictionary.work_planner_form_placeholder_work
                }
              />
              <label>{dictionary.work_planner_form_proj}</label>
              <Dropdown
                style={{ width: "100%" }}
                value={moduleLog}
                options={projects}
                filter
                onChange={(e) => {
                  dispatch(setModule(e.value.name));
                  if (
                    e.value.name === "Urlop" ||
                    e.value.name === "Nieobecność"
                  ) {
                    dispatch(setClient("OTHER"));
                    dispatch(clearWpl());
                    dispatch(clearWorkingH());
                    dispatch(setHour(8));
                  }
                }}
                optionLabel="name"
                placeholder={
                  moduleLog !== null
                    ? moduleLog
                    : dictionary.work_planner_form_placeholder_proj
                }
              />
              <div className="field field-button-wrapper">
                <Button
                  label={dictionary.work_planner_form_submmit}
                  disabled={disabledOkBtn}
                  onClick={() => handleOK()}
                />
              </div>
            </div>
          ) : null}
        </div>
        <h3 className="table-preview-title">
          {dictionary.work_planner_preview_title}
        </h3>
        <small>{dictionary.work_planner_preview_info}</small>
        <table className="schedule-preview-table">
          <thead>
            <tr>
              <th>{dictionary.work_planner_preview_table_th1}</th>
              <th>{dictionary.work_planner_preview_table_th2}</th>
              <th>{dictionary.work_planner_preview_table_th3}</th>
              <th>{dictionary.work_planner_preview_table_th4}</th>
            </tr>
          </thead>
          <tbody>
            {workSchedule.map((sch, index) => {
              return (
                <tr key={index}>
                  <td role="cell">{days[sch.day[0].getDay()]}</td>
                  <td role="cell">{sch.workH ? sch.workH : ""}</td>
                  <td role="cell">{sch.workP ? sch.workP : ""}</td>
                  <td role="cell">{sch.other ? sch.other : ""}</td>
                  <td role="cell">
                    <Button
                      icon="pi pi-times"
                      onClick={() => handleDelete(sch)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="field field-button-wrapper">
          <Button
            label={dictionary.dialog_save_btn}
            icon="pi pi-save"
            disabled={disabledSaveBtn}
            className="widget-btn"
            onClick={() => handleSave()}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default WorkPlanner;
