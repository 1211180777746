import React, { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import CreateLogComponent from "../createlog/CreateLogComponent.js";
import UserLogsTable from "../tables/UserLogsTable.js";
import Logo from "../../assets/logos/e-timer_logo2.png";
import WorkPlanner from "./workplanner/WorkPlanner.js";
import ExportDialog from "../export/ExportDialog.js";
import Logout from "../logout/LogOut.js";
import MobileOverlay from "./MobileOverlay.js";
import { useNavigate } from "react-router-dom";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Toast } from "primereact/toast";
import Widget from "./widget/Widget.js";
import { useSelector, useDispatch } from "react-redux";
import { hideEldDialog, hideIntDialog, hideCtyDialog, hideLogDialog, hideOthDialog } from "../../redux/actions/dialogActions.js";
import {
  clearClient,
  clearComment,
  clearCty,
  clearDate,
  clearEsp,
  clearHour,
  clearModType,
  clearModule,
  clearStage,
  clearTask,
  clearWorkingH,
  clearWpl,
} from "../../redux/actions/logActions.js";
import { fetchEldData } from "../../redux/thunk/thunk.js";
import { fetchInitialPosts } from "../../redux/thunk/thunk";
import { dictionary } from "../../dictionary.js";

const Content = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eldData = useSelector((state) => state.fetch.data);
  const userPosts = useSelector((state) => state.posts.data);
  const loggedUser = sessionStorage.getItem("userName");
  const admin = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")).admin : null;
  const [disabled, setDisabled] = useState(false);
  const [widgetVisible, setWidgetVisible] = useState(false);
  const [formSubmitted, setFormSubbmited] = useState(false);
  const clientLog = useSelector((state) => state.client.value);
  const dateLog = new Date(useSelector((state) => state.date.value));
  const espLog = useSelector((state) => state.esp.value);
  const ctyLog = useSelector((state) => state.cty.value);
  const modTypeLog = useSelector((state) => state.modType.value);
  const stageLog = useSelector((state) => state.stage.value);
  const taskLog = useSelector((state) => state.task.value);
  const hourLog = useSelector((state) => state.hours.value);
  const commentLog = useSelector((state) => state.comment.value);
  const moduleLog = useSelector((state) => state.module.value);
  const [postEditing, setPostEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const widgetInfo = useSelector((state) => state.widget);
  const infoLength = widgetInfo ? widgetInfo.length : 0;
  const toast = useRef(null);

  const fixDateLog = (date) => {
    let yyy = date.getFullYear();
    let mm = "" + (date.getMonth() + 1);
    let dd = "" + date.getDate();
    if (mm.length < 2) mm = "0" + mm;
    if (dd.length < 2) dd = "0" + dd;
    return [yyy, mm, dd].join("-");
  };

  useEffect(() => {
    if (loggedUser === null) {
      navigate("/home");
    }
  }, [loggedUser]);

  useEffect(() => {
    dispatch(fetchEldData());
    dispatch(fetchInitialPosts(loggedUser));
  }, [dispatch, loggedUser]);

  const checkIfDayIsPlanned = (day) => {
    let date = contentProps.fixDateLog(day);
    let isPlanned = false;
    userPosts.forEach((post) => {
      if (post.date === date) {
        isPlanned = true;
      }
    });
    return isPlanned;
  };

  const showMsg = () => {
    toast.current.show({
      severity: "warn",
      summary: dictionary.toast_summary_warn,
      detail: dictionary.toast_create_log_detail,
      life: 7000,
    });
  };

  const setValuesOnInitialState = () => {
    dispatch(clearDate());
    dispatch(clearClient());
    dispatch(clearModule());
    dispatch(clearCty());
    dispatch(clearModType());
    dispatch(clearStage());
    dispatch(clearTask());
    dispatch(clearHour());
    dispatch(clearComment());
    dispatch(clearEsp());
    dispatch(clearWpl());
    dispatch(clearWorkingH());
    setDisabled(false);
    setEditId(null);
    setPostEditing(false);
    setFormSubbmited(false);
    dispatch(hideEldDialog());
    dispatch(hideIntDialog());
    dispatch(hideCtyDialog());
    dispatch(hideLogDialog());
    dispatch(hideOthDialog());
  };

  const getCw = (date) => {
    let dt = date.split("-");
    let dateStr = dt[1] + "/" + dt[2] + "/" + dt[0];
    let conv = new Date(dateStr);
    conv.setHours(0, 0, 0, 0);
    conv.setDate(conv.getDate() + 4 - (conv.getDay() || 7)); //set monday as first day of week
    let startDate = new Date(conv.getFullYear(), 0, 1);
    let days = Math.floor((conv - startDate) / (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);
    if (weekNumber === 0) weekNumber = 52; //fix 1st of January
    return weekNumber;
  };

  const onRowEditComplete = () => {
    let log = {
      user: loggedUser,
      client: clientLog.toUpperCase(),
      esp: espLog === null ? "" : espLog,
      country: ctyLog === null ? "" : ctyLog,
      module_type: modTypeLog === null ? "" : modTypeLog,
      module: moduleLog,
      stage: stageLog === null ? "" : stageLog,
      task: taskLog === null ? "" : taskLog,
      hours: hourLog,
      comment: commentLog === null ? "" : commentLog,
      date: fixDateLog(dateLog),
      cw: getCw(fixDateLog(dateLog)),
    };

    fetch(`https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts/${editId}`, {
      method: "PATCH",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(log),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchInitialPosts(contentProps.loggedUser));
      }
    });
    setValuesOnInitialState();
  };

  const contentProps = {
    userPosts: userPosts,
    loggedUser: loggedUser,
    eldData: eldData,
    sortLogsByDates: props.sortLogsByDates,
    postEditing: postEditing,
    disabled: disabled,
    formSubmitted: formSubmitted,
    checkIfDayIsPlanned: checkIfDayIsPlanned,
    showMsg: showMsg,
    setPostEditing: setPostEditing,
    fixDateLog: fixDateLog,
    getCw: getCw,
    onRowEditComplete: onRowEditComplete,
    setValuesOnInitialState: setValuesOnInitialState,
    setDisabled: setDisabled,
    setFormSubbmited: setFormSubbmited,
    setEditId: setEditId,
  };

  return (
    <div className="content-container">
      <MobileOverlay />
      <Toast ref={toast} className="toast-message" />
      <div className="content-left-bar">
        <div className="left-bar-menu">
          <img src={Logo} alt="app logo" />
          <Divider />
          <h1>{dictionary.content_hello + loggedUser}</h1>
          <Divider />
          <CreateLogComponent contentProps={contentProps} />
          <Divider />
          <h2>{dictionary.content_tools}</h2>
          <div className="workplanner-wrapper">
            <Button label={dictionary.work_planner_btn} icon="pi pi-calendar-plus" onClick={() => navigate("/timesheet/workplanner")} />
          </div>
          <div className="export-wrapper">
            <Button label={dictionary.export_btn} icon="pi pi-file-export" onClick={() => navigate("/timesheet/exportdata")} />
          </div>
          {admin ? (
            <div className="admin-wrapper">
              <Button icon="pi pi-cog" label={dictionary.admin_panel_btn} onClick={() => navigate("/adminpanel")} />
            </div>
          ) : null}
          <div className="logout-wrapper">
            <Button icon="pi pi-sign-out" label={dictionary.logout_btn_label} onClick={() => navigate("/timesheet/logout")} />
          </div>
          <Divider />
          <p id="app-version">{dictionary.app_version}</p>
        </div>
      </div>
      <div className="content-table">
        <UserLogsTable contentProps={contentProps} />
        <div className="badge-wrapper">
          <div className="badge-position">
            <Badge value={infoLength} severity="success"></Badge>
          </div>
          <Button icon="pi pi-bell p-overlay-badge" className="widget-btn" onClick={() => setWidgetVisible(true)} />
        </div>
        {widgetVisible ? <Widget widgetInfo={widgetInfo} widgetVisible={widgetVisible} setWidgetVisible={setWidgetVisible} /> : null}
      </div>
      <Routes>
        <Route path="workplanner" element={<WorkPlanner contentProps={contentProps} />} />
        <Route path="exportdata" element={<ExportDialog eldData={eldData} />} />
        <Route path="logout" element={<Logout />} />
      </Routes>
    </div>
  );
};

export default Content;
