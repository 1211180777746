import { SET_MODULE, CLEAR_MODULE } from "../actions/logActions";

const initModule = {
  value: null,
};

const moduleLogReducer = (state = initModule, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MODULE:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_MODULE:
      return { value: null };
    default:
      return state;
  }
};

export default moduleLogReducer;
