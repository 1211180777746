const initWidgetInfo = [
  {
    title: "Release TK40",
    vnr: "0.2.5.9",
    info: "Naprawiono błędne wyświetlanie miesiąca w productivity teams. Teraz dane przedstawiane są prawidłowo dla poprzedniego miesiąca. Naprawiono rok obrotowy w tych raportach. Dane przedstawiane w tabeli są od Marca br do Marca kolejnego roku.",
  },
  {
    title: "Release TK32",
    vnr: "0.2.5.7",
    info: "Naprawiono opcję dodawania modułów w panelu admin. Od teraz można dodawać moduły w ścieżce Countries/Other.",
  },
];

const widgetReducer = (state = initWidgetInfo, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default widgetReducer;