import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useSelector, useDispatch } from 'react-redux';
import { hideLogDialog } from '../../redux/actions/dialogActions.js';
import {
  clearDate,
  setComment,
  setDate,
  setStage,
  setHour,
  setModule,
  setTask,
} from '../../redux/actions/logActions.js';
import { dictionary } from '../../dictionary.js';
import DialogOverlay from './DialogOverlay.js';

const LogisticsComponent = (props) => {
  const dispatch = useDispatch();
  const dialogProps = props.dialogProps;
  const [dateLog, setDateLog] = useState(new Date(useSelector((state) => state.date.value)));
  const moduleLog = useSelector((state) => state.module.value);
  const stageLog = useSelector((state) => state.stage.value);
  const hourLog = useSelector((state) => state.hours.value);
  const commentLog = useSelector((state) => state.comment.value);
  const taskLog = useSelector((state) => state.task.value);
  const [logisticSorted, setLogisticSorted] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    if (dialogProps.eldData[0].length !== 0) {
      setLogisticSorted(dialogProps.sortData(dialogProps.eldData[0].logistics));
    }
  }, [dialogProps.eldData]);

  const showMsg = () => {
    toast.current.show({
      severity: 'warn',
      summary: dictionary.toast_summary_warn,
      detail: dictionary.toast_create_log_detail,
      life: 7000,
    });
  };

  return (
    <>
      <Dialog
        header={dictionary.log_dialog_header}
        visible={dialogProps.isDialogOpen}
        style={{ width: '50vw' }}
        onHide={() => {
          dialogProps.setValuesOnInitialState();
          dialogProps.setShowOverlay(true);
          dispatch(hideLogDialog());
        }}
      >
        <div className="field field-wrapper">
          <Toast ref={toast} />
          <label>{dictionary.dialog_label_date}</label>
          {dialogProps.showOverlay && <DialogOverlay dialogProps={dialogProps} />}
          <Calendar
            disabledDays={dialogProps.disabledDays}
            style={{ width: '100%' }}
            placeholder={dateLog}
            value={dateLog}
            onChange={(e) => {
              if (dialogProps.checkIfDayIsPlanned(e.value)) {
                dispatch(setDate(e.value));
                setDateLog(e.value);
                dialogProps.setShowOverlay(false);
              } else {
                showMsg();
                dispatch(clearDate());
                setDateLog(new Date());
              }
            }}
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_module}</label>
          <Dropdown
            style={{ width: '100%' }}
            value={moduleLog}
            options={logisticSorted}
            filter
            onChange={(e) => {
              dispatch(setModule(e.value.name));
            }}
            optionLabel="name"
            placeholder={moduleLog !== null ? moduleLog : dictionary.dialog_placeholder_module}
            className={moduleLog === null && dialogProps.formSubmitted ? 'p-invalid' : null}
          />
          {moduleLog === null && dialogProps.formSubmitted ? (
            <small className="p-error">{dictionary.dialog_error_module}</small>
          ) : null}
          {moduleLog === 'Drobne_projekty' ? (
            <>
              <Dropdown
                style={{ width: '100%', marginTop: '0.5rem' }}
                value={taskLog}
                options={dialogProps.eldData[0].projects_logistics.sort((a, b) => a.name.localeCompare(b.name))} //INT_Drobne_projekty
                filter
                onChange={(e) => {
                  dispatch(setTask(e.value.name));
                }}
                optionLabel="name"
                placeholder={taskLog !== null ? taskLog : dictionary.dialog_placeholder_task}
                className={taskLog === null && dialogProps.formSubmitted ? 'p-invalid' : null}
              />
              {taskLog === null && dialogProps.formSubmitted ? (
                <small className="p-error">{dictionary.dialog_error_task}</small>
              ) : null}
            </>
          ) : (
            <>
              <>
                <label>{dictionary.dialog_label_stage}</label>
                <Dropdown
                  style={{ width: '100%' }}
                  value={stageLog}
                  options={dialogProps.eldData[0].stages_development}
                  filter
                  onChange={(e) => {
                    dispatch(setStage(e.value.name));
                  }}
                  optionLabel="name"
                  placeholder={stageLog !== null ? stageLog : dictionary.dialog_placeholder_stage}
                  className={stageLog === null && dialogProps.formSubmitted ? 'p-invalid' : null}
                />
                {stageLog === null && dialogProps.formSubmitted ? (
                  <small className="p-error">{dictionary.dialog_error_stage}</small>
                ) : null}
              </>
            </>
          )}
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_hours}</label>
          <Dropdown
            style={{ width: '100%' }}
            value={hourLog}
            options={dialogProps.eldData[0].hours}
            onChange={(e) => {
              dispatch(setHour(e.value.hour));
            }}
            optionLabel="hour"
            placeholder={hourLog !== 0 ? hourLog : dictionary.dialog_placeholder_hours}
            className={hourLog === 0 && dialogProps.formSubmitted ? 'p-invalid' : null}
          />
          {hourLog === 0 && dialogProps.formSubmitted ? (
            <small className="p-error">{dictionary.dialog_error_hours}</small>
          ) : null}
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_comment}</label>
          <InputText
            style={{ width: '100%' }}
            value={commentLog !== null ? commentLog : ""}
            placeholder={dictionary.dialog_placeholder_comment}
            onChange={(e) => {
              dispatch(setComment(e.target.value));
            }}
          />
        </div>
        <div className="field field-wrapper">
          {!dialogProps.postEditing ? (
            <Button
              label={dictionary.dialog_save_btn}
              icon="pi pi-save"
              className="field-report-btn"
              onClick={() => {
                dialogProps.setFormSubbmited(true);
                dialogProps.setShowOverlay(true);
                if (moduleLog !== null && stageLog !== null && hourLog !== 0) {
                  dialogProps.onLogSave();
                } else if (moduleLog === 'Drobne_projekty' && taskLog !== null && hourLog !== 0){
                  dialogProps.onLogSave();
                }
              }}
            />
          ) : (
            <Button
              label={dictionary.dialog_update_btn}
              icon="pi pi-save"
              className="field-report-btn"
              onClick={() => {
                dialogProps.setFormSubbmited(true);
                dialogProps.setShowOverlay(true);
                if (moduleLog !== null && stageLog !== null && hourLog !== 0) {
                  dialogProps.onRowEditComplete();
                } else if (moduleLog === 'Drobne_projekty' && taskLog !== null && hourLog !== 0){
                  dialogProps.onRowEditComplete();
                }
              }}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default LogisticsComponent;
