import React from "react";
import { dictionary } from "../../../../dictionary";

const ESPSales = (props) => {
  const properties = props.properties;
  const styles = props.styles;

  return (
    <>
      <tr>
        <th className="nugget" style={styles.nugget}>
          {dictionary.productivity_table_summary_esp_sales}
        </th>
        <td className="nugget" style={styles.nugget}></td>
        <td className="nugget" style={styles.nugget}>
          {properties.espSalesSum.toString().replace(".", ",")}
        </td>
        <td className="nugget" style={styles.nugget}></td>
        <td className="nugget" style={styles.nugget}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="nugget" style={styles.nugget}>
              {properties.espSalesCountrySum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default ESPSales;
