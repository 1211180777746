import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { dictionary } from '../../../dictionary';

const DeskAvailability = () => {
  const [posts, setPosts] = useState([]);
  const today = new Date();
  const dateString = today.toISOString().split("T")[0];
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowString = tomorrow.toISOString().split("T")[0];
  const totalDesks = 23;
  const [occupiedDesksToday, setOccupiedDesksToday] = useState(0);
  const [occupiedDesksTomorrow, setOccupiedDesksTomorrow] = useState(0);

  const handleFetchData = async () => {
    const response = await fetch(
      `https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts/availability/${dateString}`, {
        method: 'GET',
        mode: 'cors',
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) {
      const err = new Error(response.message || "Failed to fetch posts!");
      throw err;
    }
    const logs = [];

    for (const key in responseData) {
      const log = {
        id: responseData[key]._id,
        user: responseData[key].user,
        client: responseData[key].client,
        esp: responseData[key].esp,
        country: responseData[key].country,
        module_type: responseData[key].module_type,
        module: responseData[key].module,
        stage: responseData[key].stage,
        task: responseData[key].task,
        hours: responseData[key].hours,
        wpl: responseData[key].wpl,
        workingh: responseData[key].workingh,
        comment: responseData[key].comment,
        date: responseData[key].date,
        cw: responseData[key].cw,
      };
      logs.push(log);
    }
    setPosts(logs);
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    const occupiedToday = posts.filter(
      (post) => post.wpl === "Biuro" && post.date === dateString
    ).length;
    const occupiedTomorrow = posts.filter(
      (post) => post.wpl === "Biuro" && post.date === tomorrowString
    ).length;
    setOccupiedDesksToday(occupiedToday);
    setOccupiedDesksTomorrow(occupiedTomorrow);
  }, [posts]);

  const dataToday = {
    datasets: [
      {
        data: [occupiedDesksToday, totalDesks - occupiedDesksToday],
        backgroundColor:
          occupiedDesksToday / totalDesks >= 0.9
            ? ["#FF4772", "#2F6368"]
            : occupiedDesksToday / totalDesks >= 0.6
            ? ["#fff000", "#2F6368"]
            : ["#37D67A", "#2F6368"],
      },
    ],
  };

  const dataTomorrow = {
    datasets: [
      {
        data: [occupiedDesksTomorrow, totalDesks - occupiedDesksTomorrow],
        backgroundColor:
          occupiedDesksTomorrow / totalDesks >= 0.9
            ? ["#FF4772", "#2F6368"]
            : occupiedDesksTomorrow / totalDesks >= 0.6
            ? ["#fff000", "#2F6368"]
            : ["#37D67A", "#2F6368"],
      },
    ],
  };

  return (
    <div className="desk-availability">
      <div className="col">
        <div className="chart-wrapper">
          <p>{dictionary.admin_chart_label1}{dateString}</p>
          <Chart type="doughnut" data={dataToday} />
        </div>
        <div className="legend">
          <ul>
            <li>
              <div
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor:
                    occupiedDesksToday / totalDesks >= 0.9
                      ? "#FF4772"
                      : occupiedDesksToday / totalDesks >= 0.6
                      ? "#fff000"
                      : "#37D67A",
                }}
              ></div>
              <span>{dictionary.admin_chart_label2}{totalDesks - occupiedDesksToday}</span>
              <span>{Math.round((occupiedDesksToday / totalDesks) * 100)}%</span>
            </li>
            <li>
              <div
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: "#2F6368",
                }}
              ></div>
              <span>{dictionary.admin_chart_label3}{totalDesks}</span>
              <span>100%</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="col">
        <div className="chart-wrapper">
          <p>
            {dictionary.admin_chart_label1}{tomorrowString}
          </p>
          <Chart type="doughnut" data={dataTomorrow} />
        </div>
        <div className="legend">
          <ul>
            <li>
              <div
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor:
                    occupiedDesksToday / totalDesks >= 0.9
                      ? "#FF4772"
                      : occupiedDesksToday / totalDesks >= 0.6
                      ? "#fff000"
                      : "#37D67A",
                }}
              ></div>
              <span>{dictionary.admin_chart_label2}{totalDesks - occupiedDesksTomorrow}</span>
              <span>{Math.round((occupiedDesksTomorrow / totalDesks) * 100)}%</span>
            </li>
            <li>
              <div
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: "#2F6368",
                }}
              ></div>
              <span>{dictionary.admin_chart_label3}{totalDesks}</span>
              <span>100%</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DeskAvailability;
