import React from "react";

const ProductivityCounties = (props) => {
  const properties = props.properties;

  const countriesFtFLocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "FtF" && data.esp === "")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryFtFLocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "FtF" && data.country === country.name && data.esp === "")
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesFtF20LocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "FtF_2.0" && data.esp === "")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryFtF20LocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "FtF_2.0" && data.country === country.name && data.esp === "")
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesMaLocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "MA" && data.esp === "")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryMaLocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "MA" && data.country === country.name && data.esp === "")
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesMa20LocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "MA_2.0" && data.esp === "")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryMa20LocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "MA_2.0" && data.country === country.name && data.esp === "")
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesMa30LocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "MA_3.0" && data.esp === "")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryMa30LocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "MA_3.0" && data.country === country.name && data.esp === "")
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesNuggetsAUDILocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "Nugget AUDI" && data.esp === "")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryNuggetsAUDILocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "Nugget AUDI" && data.country === country.name && data.esp === "")
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesNuggetsINTLocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "Nugget INT" && data.esp === "")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryNuggetsINTLocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "Nugget INT" && data.country === country.name && data.esp === "")
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesOtherModulesLocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "Other" && data.esp === "")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryOtherModulesLocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "Other" && data.country === country.name && data.esp === "")
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesOtherTasksLocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "Other" && data.module === "Drobne_projekty")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryOtherTasksLocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "Other" && data.module === "Drobne_projekty" && data.country === country.name)
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesHourManagementLocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "Hour management")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryHourManagementLocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "Hour management" && data.country === country.name)
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesLogisticsLocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "Logistics" && data.esp === "")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryLogisticsLocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "Logistics" && data.country === country.name && data.esp === "")
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const countriesLogisticsOtherTasksLocalizationSum = properties.monthlyData
    .filter((data) => data.client === "COUNTRY" && data.module_type === "Logistics" && data.module === "Drobne_projekty")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const singlecountryLogisticsOtherTasksLocalizationSum = (country) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "COUNTRY" && data.module_type === "Logistics" && data.module === "Drobne_projekty" && data.country === country.name)
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };
  return (
    <>
      <tr>
        <th>Countries</th>
      </tr>
      <tr>
        <th className="ftf" style={properties.styles.ftf}>
          FtF - Localization/Yearly update
        </th>
        <td className="ftf" style={properties.styles.ftf}>
          21000776
        </td>
        <td className="ftf" style={properties.styles.ftf}>
          {countriesFtFLocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="ftf" style={properties.styles.ftf}>
              {singlecountryFtFLocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="ftf" style={properties.styles.ftf}>
          FtF 2.0 - Localization
        </th>
        <td className="ftf" style={properties.styles.ftf}></td>
        <td className="ftf" style={properties.styles.ftf}>
          {countriesFtF20LocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="ftf" style={properties.styles.ftf}>
              {singlecountryFtF20LocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="ma" style={properties.styles.ma}>
          MA - Localization/Yearly update
        </th>
        <td className="ma" style={properties.styles.ma}></td>
        <td className="ma" style={properties.styles.ma}>
          {countriesMaLocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="ma" style={properties.styles.ma}>
              {singlecountryMaLocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="ma" style={properties.styles.ma}>
          MA 2.0 - Localization/Yearly update
        </th>
        <td className="ma" style={properties.styles.ma}></td>
        <td className="ma" style={properties.styles.ma}>
          {countriesMa20LocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="ma" style={properties.styles.ma}>
              {singlecountryMa20LocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="ma" style={properties.styles.ma}>
          MA 3.0 - Localization/Yearly update
        </th>
        <td className="ma" style={properties.styles.ma}></td>
        <td className="ma" style={properties.styles.ma}>
          {countriesMa30LocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="ma" style={properties.styles.ma}>
              {singlecountryMa30LocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="nugget" style={properties.styles.nugget}>
          {" "}
          Nuggets AUDI - Localization
        </th>
        <td className="nugget" style={properties.styles.nugget}></td>
        <td className="nugget" style={properties.styles.nugget}>
          {countriesNuggetsAUDILocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="nugget" style={properties.styles.nugget}>
              {singlecountryNuggetsAUDILocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="nuggetint" style={properties.styles.nuggetint}>
          {" "}
          Nuggets INT - Localization
        </th>
        <td className="nuggetint" style={properties.styles.nuggetint}></td>
        <td className="nuggetint" style={properties.styles.nuggetint}>
          {countriesNuggetsINTLocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="nuggetint" style={properties.styles.nuggetint}>
              {singlecountryNuggetsINTLocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="other" style={properties.styles.other}>
          {" "}
          Other modules
        </th>
        <td className="other" style={properties.styles.other}></td>
        <td className="other" style={properties.styles.other}>
          {(countriesOtherModulesLocalizationSum - countriesOtherTasksLocalizationSum).toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="other" style={properties.styles.other}>
              {(singlecountryOtherModulesLocalizationSum(country) - singlecountryOtherTasksLocalizationSum(country)).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="other" style={properties.styles.other}>
          {" "}
          Other tasks
        </th>
        <td className="other" style={properties.styles.other}></td>
        <td className="other" style={properties.styles.other}>
          {countriesOtherTasksLocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="other" style={properties.styles.other}>
              {singlecountryOtherTasksLocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="other" style={properties.styles.other}>
          Country Hour management
        </th>
        <td className="other" style={properties.styles.other}></td>
        <td className="other" style={properties.styles.other}>
          {countriesHourManagementLocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="other" style={properties.styles.other}>
              {singlecountryHourManagementLocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="logistics" style={properties.styles.logistics}>
          {" "}
          Logistics - Localization/Yearly update
        </th>
        <td className="logistics" style={properties.styles.logistics}></td>
        <td className="logistics" style={properties.styles.logistics}>
          {(countriesLogisticsLocalizationSum - countriesLogisticsOtherTasksLocalizationSum).toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="logistics" style={properties.styles.logistics}>
              {(singlecountryLogisticsLocalizationSum(country) - singlecountryLogisticsOtherTasksLocalizationSum(country)).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
      <tr>
        <th className="logistics" style={properties.styles.logistics}>
          {" "}
          Logistics - Other tasks
        </th>
        <td className="logistics" style={properties.styles.logistics}></td>
        <td className="logistics" style={properties.styles.logistics}>
          {countriesLogisticsOtherTasksLocalizationSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="logistics" style={properties.styles.logistics}>
              {singlecountryLogisticsOtherTasksLocalizationSum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default ProductivityCounties;
