export const ADD_POST = "ADD_POST";
export const REMOVE_POST = "REMOVE_POST";
export const UPDATE_POST = "UPDATE_POST";

export const FETCH_USER_POSTS_REQUEST = "FETCH_USER_POSTS_REQUEST";
export const FETCH_USER_POSTS_SUCCESS = "FETCH_USER_POSTS_SUCCESS";
export const FETCH_USER_POSTS_FAILURE = "FETCH_USER_POSTS_FAILURE";

export const fetchUserPostsRequest = () => {
  return {
    type: FETCH_USER_POSTS_REQUEST,
  };
};

export const fetchUserPostsSuccess = (data) => {
  return {
    type: FETCH_USER_POSTS_SUCCESS,
    payload: data,
  };
};

export const fetchUserPostsFailure = (error) => {
  return {
    type: FETCH_USER_POSTS_FAILURE,
    payload: error,
  };
};
