import React, { useState, useEffect } from "react";
import EldLogo from "../../../assets/logos/Logo-ELD-02 transparent.png";
import UsersWorkPlan from "./UsersWorkPlan";
import UserManagement from "./UserManagement";
import ModuleManagement from "./ModuleManagement";
import FooterMenu from "../FooterMenu";
import { useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { useSelector, useDispatch } from "react-redux";
import { fetchEldData } from "../../../redux/thunk/thunk";
import { dictionary } from "../../../dictionary";
import MobileOverlay from "../MobileOverlay";

const AdminPanel = () => {
  const dispatch = useDispatch();
  const admin = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")).admin : null;
  const eldData = useSelector((state) => state.fetch.data);
  const [showWorkPlan, setShowWorkPlan] = useState(true);
  const [showUserManagement, setShowUserManagement] = useState(false);
  const [showModuleManagement, setShowModuleManagement] = useState(false);
  const year = new Date().getFullYear();
  const [selectedWeek, setSelectedWeek] = useState(Math.ceil((new Date() - new Date(year, 0, 1)) / (7 * 24 * 60 * 60 * 1000)));
  const navigate = useNavigate();
  const end = <img alt="logo" src={EldLogo} className="mr-2"></img>;
  const items = [
    {
      label: dictionary.admin_panel_nav_label1,
      icon: "pi pi-users",
      command: () => {
        setShowWorkPlan(true);
        setShowUserManagement(false);
        setShowModuleManagement(false);
      },
    },
    {
      label: dictionary.admin_panel_nav_label2,
      icon: "pi pi-user-edit",
      command: () => {
        setShowWorkPlan(false);
        setShowUserManagement(true);
        setShowModuleManagement(false);
      },
    },
    {
      label: dictionary.admin_panel_nav_label3,
      icon: "pi pi-cog",
      command: () => {
        setShowWorkPlan(false);
        setShowUserManagement(false);
        setShowModuleManagement(true);
      },
    },
    {
      label: dictionary.admin_panel_nav_label4,
      icon: "pi pi-times",
      command: () => {
        setSelectedWeek(null);
        navigate("/timesheet");
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchEldData());
  }, [dispatch]);

  useEffect(() => {
    if (!admin) {
      navigate("/home");
    }
  }, [admin]);

  return (
    <div className="admin-container">
      <MobileOverlay />
      <Menubar model={items} end={end} />
      {showWorkPlan ? <UsersWorkPlan eldData={eldData} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} /> : null}
      {showUserManagement ? <UserManagement eldData={eldData} /> : null}
      {showModuleManagement ? <ModuleManagement eldData={eldData} /> : null}
      <FooterMenu />
    </div>
  );
};

export default AdminPanel;
