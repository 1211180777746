import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const DropdownComponent = ({ name, style, value, options, onChange, optionLabel, placeholder, className, disabled }) => (
  <Dropdown
    name={name}
    style={style}
    value={value}
    options={options}
    onChange={onChange}
    optionLabel={optionLabel}
    placeholder={placeholder}
    className={className}
    disabled={disabled}
  />
);

export default DropdownComponent;
