export const FETCH_ELD_DATA_REQUEST = "FETCH_ELD_DATA_REQUEST";
export const FETCH_ELD_DATA_SUCCESS = "FETCH_ELD_DATA_SUCCESS";
export const FETCH_ELD_DATA_FAILURE = "FETCH_ELD_DATA_FAILURE";

export const fetchEldDataRequest = () => {
  return {
    type: FETCH_ELD_DATA_REQUEST,
  };
};

export const fetchEldDataSuccess = (data) => {
  return {
    type: FETCH_ELD_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchEldDataFailure = (error) => {
  return {
    type: FETCH_ELD_DATA_FAILURE,
    payload: error,
  };
};
