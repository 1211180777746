import React from 'react';
import { dictionary } from '../../dictionary.js';

const MinorProjects = (props) => {
  const properties = props.properties;

  const groupedProjects = properties.minorProjects.reduce((grouped, item) => {
    const key = item.task;
    if (!grouped[key]) {
      grouped[key] = {...item};
    } else {
      grouped[key].hours += item.hours;
    }
    return grouped;
  }, {});

  const projects = Object.values(groupedProjects).sort((a, b) => a.task.localeCompare(b.task));

  const styles = {
    header: {
      background: '#FFF2CC',
      border: 'solid black',
      borderWidth: 'thin',
      width: 'auto',
    },
    gray: {
      background: '#D9D9D9',
      border: 'solid black',
      borderWidth: 'thin',
    },
    empty: {
      border: 'solid black',
      borderWidth: 'thin',
      textAlign: 'right',
    },
    green: {
      background: '#C6E0B4',
      border: 'solid black',
      borderWidth: 'thin',
      fontWeight: 'bold',
    },
    greenSum: {
      background: '#C6E0B4',
      border: 'solid black',
      borderWidth: 'thin',
      textAlign: 'right',
      fontWeight: 'bold',
    },
  };

  return (
    <table>
      <thead>
        <tr>
          <th className="header" style={styles.header}>
          {properties.client !== 'INT' ?  dictionary.export_table_minor_proj_country : dictionary.export_table_minor_proj_int}
          </th>
          <th className="header" style={styles.header}>
            {dictionary.export_table_th_hours}
          </th>
          {/* <th className="header" style={styles.header}>
            {dictionary.export_table_th_costs + '(' + properties.costRate + dictionary.currency + ')'}
          </th> */}
        </tr>
      </thead>
      <tbody>
        {projects !== null
          ? projects.map((item, index) => (
              <tr key={index}>
                <td className="gray" style={styles.gray}>
                  {item.task === '' ? dictionary.export_table_task_missing : item.task}
                </td>
                <td className="empty" style={styles.empty}>
                  {item.hours.toString().replace('.', ',')}
                </td>
                {/* <td className="empty" style={styles.empty}>
                  {(item.hours * properties.costRate).toString().replace('.', ',') + dictionary.currency}
                </td> */}
              </tr>
            ))
          : null}
        <tr>
          <td className="green" style={styles.green}>
            {dictionary.export_table_summary}
          </td>
          <td className="green" style={styles.greenSum}>
            {projects
              .reduce((total, obj) => obj.hours + total, 0)
              .toString()
              .replace('.', ',')}
          </td>
          {/* <td className="green" style={styles.greenSum}>
            {(projects.reduce((total, obj) => obj.hours + total, 0) * properties.costRate)
              .toString()
              .replace('.', ',') + dictionary.currency}
          </td> */}
        </tr>
      </tbody>
    </table>
  );
};

export default MinorProjects;
