import React from "react";
import { dictionary } from "../../../../dictionary";

const IntEmployeesHours = (props) => {
  const properties = props.properties;
  const styles = props.styles;

  return (
    <>
      <tr>
        <th className="nugget" style={styles.nugget}>
          {dictionary.productivity_table_summary_int_empl_hours}
        </th>
        <td className="nugget" style={styles.nugget}></td>
        <td className="nugget" style={styles.nugget}>
          {(properties.workingDays * properties.intEmployees * properties.intRate).toString().replace(".", ",")}
        </td>
        <td className="nugget" style={styles.nugget}></td>
        <td className="nugget" style={styles.nugget}></td>
        {properties.countries.map((_, idx) => {
          return <td key={idx} className="nugget" style={styles.nugget}></td>;
        })}
      </tr>
    </>
  );
};

export default IntEmployeesHours;
