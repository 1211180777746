export const SET_DATE = "SET_DATE";
export const CLEAR_DATE = "CLEAR_DATE";
export const SET_MODULE = "SET_MODULE";
export const CLEAR_MODULE = "CLEAR_MODULE";
export const SET_CLIENT = "SET_CLIENT";
export const CLEAR_CLIENT = "CLEAR_CLIENT";
export const SET_CTY = "SET_CTY";
export const CLEAR_CTY = "CLEAR_CTY";
export const SET_MODTYPE = "SET_MODTYPE";
export const CLEAR_MODTYPE = "CLEAR_MODTYPE";
export const SET_STAGE = "SET_STAGE";
export const CLEAR_STAGE = "CLEAR_STAGE";
export const SET_HOUR = "SET_HOUR";
export const CLEAR_HOUR = "CLEAR_HOUR";
export const SET_WPL = "SET_WPL";
export const CLEAR_WPL = "CLEAR_WPL";
export const SET_COMMENT = "SET_COMMENT";
export const CLEAR_COMMENT = "CLEAR_COMMENT";
export const SET_TASK = "SET_TASK";
export const CLEAR_TASK = "CLEAR_TASK";
export const SET_ESP = "SET_ESP";
export const CLEAR_ESP = "CLEAR_ESP";
export const SET_WORKINGH = "SET_WORKINGH";
export const CLEAR_WORKINGH = "CLEAR_WORKINGH";

export const setDate = (obj) => {
  return {
    type: SET_DATE,
    payload: obj,
  };
};

export const clearDate = () => {
  return {
    type: CLEAR_DATE,
  };
};

export const setModule = (string) => {
  return {
    type: SET_MODULE,
    payload: string,
  };
};

export const clearModule = () => {
  return {
    type: CLEAR_MODULE,
  };
};

export const setClient = (string) => {
  return {
    type: SET_CLIENT,
    payload: string,
  };
};

export const clearClient = () => {
  return {
    type: CLEAR_CLIENT,
  };
};

export const setCty = (string) => {
  return {
    type: SET_CTY,
    payload: string,
  };
};

export const clearCty = () => {
  return {
    type: CLEAR_CTY,
  };
};

export const setModType = (string) => {
  return {
    type: SET_MODTYPE,
    payload: string,
  };
};

export const clearModType = () => {
  return {
    type: CLEAR_MODTYPE,
  };
};

export const setStage = (string) => {
  return {
    type: SET_STAGE,
    payload: string,
  };
};

export const clearStage = () => {
  return {
    type: CLEAR_STAGE,
  };
};

export const setHour = (number) => {
  return {
    type: SET_HOUR,
    payload: number,
  };
};

export const clearHour = () => {
  return {
    type: CLEAR_HOUR,
  };
};

export const setWpl = (arr) => {
  return {
    type: SET_WPL,
    payload: arr,
  };
};

export const clearWpl = () => {
  return {
    type: CLEAR_WPL,
  };
};

export const setComment = (string) => {
  return {
    type: SET_COMMENT,
    payload: string,
  };
};

export const clearComment = () => {
  return {
    type: CLEAR_COMMENT,
  };
};

export const setTask = (string) => {
  return {
    type: SET_TASK,
    payload: string,
  };
};

export const clearTask = () => {
  return {
    type: CLEAR_TASK,
  };
};

export const setEsp = (string) => {
  return {
    type: SET_ESP,
    payload: string,
  };
};

export const clearEsp = () => {
  return {
    type: CLEAR_ESP,
  };
};

export const setWorkingH = (startHour, endHour) => {
  return {
    type: SET_WORKINGH,
    payload: `${startHour || '00:00'} - ${endHour || '00:00'}`,
  };
};

export const clearWorkingH = () => {
  return {
    type: CLEAR_WORKINGH,
  };
};