import React from "react";
import { Calendar } from "primereact/calendar";
import { dictionary } from "../../../dictionary";

const CalendarComponent = ({
  style,
  id,
  placeholder,
  view,
  dateFormat,
  value,
  value2,
  onChange,
  onChange2,
  showIcon,
  disabled,
}) => (
  <>
    <label>{dictionary.export_calendar_from}</label>
    <Calendar
      style={style}
      id={id}
      placeholder={placeholder}
      view={view}
      dateFormat={dateFormat}
      value={value}
      onChange={onChange}
      showIcon={showIcon}
    />
    <label>{dictionary.export_calendar_to}</label>
    <Calendar
      style={style}
      id={id}
      placeholder={placeholder}
      view={view}
      dateFormat={dateFormat}
      value={value2}
      onChange={onChange2}
      showIcon={showIcon}
      disabled={disabled}
    />
  </>
);

export default CalendarComponent;
