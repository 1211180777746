import React from "react";
import { dictionary } from "../../../../dictionary";

const CostsLogistic = (props) => {
  const properties = props.properties;
  const styles = props.styles;

  return (
    <>
      <tr>
        <th className="logistics_modules" style={styles.logistics_modules}>
          {dictionary.productivity_table_summary_cost_log}
        </th>
        <td className="logisticsRate" style={styles.logisticsRate}>
          {properties.costRate + dictionary.currency}
        </td>
        <td className="logistics_modules" style={styles.logistics_modules}>
          {(properties.sumAllCountriesCostsLogisticArray + properties.costLogisicsINT)
            .toFixed(2)
            .toString()
            .replace(".", ",") + dictionary.currency}
        </td>
        <td className="logistics_modules" style={styles.logistics_modules}>
          0.00{dictionary.currency}
        </td>
        <td className="logistics_modules" style={styles.logistics_modules}>
          {properties.costLogisicsINT.toString().replace(".", ",") + dictionary.currency}
        </td>
        {properties.countries.map((country, idx) => {
          let ctySum =
            properties.summaryHoursLogisticsSingleCountry(country) +
            properties.espLogisticsCountrySum(country);
          return (
            <td
              key={idx}
              className="logistics_modules"
              style={styles.logistics_modules}
            >
              {(ctySum * properties.costRate * properties.costConstant)
                .toFixed(2)
                .toString()
                .replace(".", ",") + dictionary.currency}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default CostsLogistic;
