import React from "react";
import { dictionary } from "../../../../dictionary";

const CountrySum = (props) => {
  const properties = props.properties;
  const styles = props.styles;
  const sum =
    properties.salesModuleSum +
    properties.summaryHoursLogistic +
    properties.allCountriesCountrySum;

  return (
    <>
      <tr>
        <th className="ctysum" style={styles.ctysum}>
          {dictionary.productivity_table_summary_country_sum}
        </th>
        <td className="ctysum" style={styles.ctysum}></td>
        <td className="ctysum" style={styles.ctysum}>
          {sum.toString().replace(".", ",")}
        </td>
        <td className="ctysum" style={styles.ctysum}>
          {properties.salesModuleSum.toString().replace(".", ",")}
        </td>
        <td className="ctysum" style={styles.ctysum}>
          {properties.summaryHoursLogistic.toString().replace(".", ",")}
        </td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="ctysum" style={styles.ctysum}>
              {(
                properties.summarySalesAllCountiresSingleCountry(country) +
                properties.espSalesCountrySum(country) +
                properties.summaryHoursLogisticsSingleCountry(country) +
                properties.espLogisticsCountrySum(country)
              )
                .toString()
                .replace(".", ",")}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default CountrySum;
