import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import * as XLSX from 'xlsx';
import { dictionary } from "../../../dictionary";

const ModuleManagement = (props) => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedModuleType, setSelectedModuleType] = useState(null);
  const [addMinorProj, setAddMinorProj] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [formSubmitted, setFormSubbmited] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const keysToFind = ['eld', 'ftf', 'ftf_2.0', 'other', 'logistics', 'ma', 'nugget_audi', 'nugget_int', 'projects_int', 'projects_logistics', 'projects_countries'];
  const clients = [
    { label: "ELD", value: "ELD" },
    { label: "INT", value: "INT" },
    { label: "Countries", value: "Countries" },
    { label: "Logistics", value: "Logistics" },
    { label: "Other", value: "Other" },
  ];

  const moduleTypes = [
    { label: "Ftf", value: "Ftf" },
    { label: "Ftf_2_0", value: "Ftf_2_0" },
    { label: "Logistics", value: "Logistics" },
    { label: "MA", value: "MA" },
    { label: "Nugget_AUDI", value: "Nugget_AUDI" },
    { label: "Nugget_INT", value: "Nugget_INT" },
    { label: "Other", value: "Other" },
  ];

  const addSuccessMsg = () => {
    toast.current.show({
      severity: "success",
      summary: dictionary.toast_summary_success,
      detail: dictionary.admin_panel_toast_detail + moduleName,
    });
  };

  const reset = () => {
    setSelectedClient(null);
    setSelectedModuleType(null);
    setModuleName("");
    setFormSubbmited(false);
    setAddMinorProj(false);
  };
  const handleClientChange = (e) => {
    setSelectedClient(e.value);
  };

  const handleModuleTypeChange = (e) => {
    setSelectedModuleType(e.value);
  };

  const findKeys = (obj, keysToFind) => {
    const result = {};
    const helper = (subObj, path) => {
        if (typeof subObj !== 'object' || subObj === null) return;
        for (let key in subObj) {
            const newPath = path ? `${path}.${key}` : key;

            if (keysToFind.includes(key)) {
                if (key === 'countries' && Array.isArray(subObj[key])) {
                    result[newPath] = [subObj[key][0]];
                    continue;
                }
                if (Array.isArray(subObj[key]) && subObj[key].length === 0) {
                  continue;
                }
                result[newPath] = subObj[key];
            }
            helper(subObj[key], newPath);
        }
    }
    helper(obj, '');
    return result;
};

const foundKeys = findKeys(props.eldData[0], keysToFind);

for (let key in foundKeys) {
  if (key.startsWith('countries.')) {
      const parts = key.split('.');
      if (parts[1] !== '0') {
          delete foundKeys[key];
      }
  }
};

const exportToExcel = () => {
  const dataForExcel = [];
  let maxLength = 0;
  for (let key in foundKeys) {
    if (foundKeys[key].length > maxLength) {
      maxLength = foundKeys[key].length;
    }
  };
  for (let i = 0; i < maxLength; i++) {
    const obj = {};
    for (let key in foundKeys) {
      obj[key] = foundKeys[key][i] ? foundKeys[key][i].name : '';
    }
    dataForExcel.push(obj);
  };

  const ws = XLSX.utils.json_to_sheet(dataForExcel);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Modules Summary " + new Date().getFullYear());
  XLSX.writeFile(wb, new Date().getFullYear() + "_" + "modules_summary.xlsx");
};

  const handleAddModule = (client, type) => {
    setLoading(true);
    const cli = client.toLowerCase();
    const modType = type !== null ? type.toLowerCase() : null;
    const data = JSON.parse(JSON.stringify(props.eldData[0]));
    const log = {
      name: moduleName,
    };

    let patchData = {};
    if (cli in data && modType !== null && !addMinorProj) {
      switch (cli) {
        case "int":
          data[cli][0][modType].push(log);
          patchData = { [cli]: data[cli] };
          break;
        case "countries":
          data[cli].forEach((mod) => {
            mod[modType].push(log);
          });
          patchData = { [cli]: data[cli] };
          break;
        default:
          data[cli].push(log);
          patchData = { [cli]: data[cli] };
          break;
      }
    } else {
      switch (cli) {
        case "countries":
        case "int":
          data["projects_" + cli].push(log);
          patchData = { ["projects_" + cli]: data["projects_" + cli] };
          break;
        case "eld":
          data[cli].push(log);
          patchData = { [cli]: data[cli] };
          break;
          case "other":
          data['perso'].push(log);
          patchData = { ['perso']: data['perso'] };
          break;
        default:
          data["projects_" + cli].push(log);
          patchData = { ["projects_" + cli]: data["projects_" + cli] };
          break;
      }
    }

    fetch(`https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/eld/${data._id}`, {
      method: "PATCH",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(patchData),
    }).then((response) => {
      if (response.ok) {
        addSuccessMsg();
        reset();
        setLoading(false);
        return response.json();
      } else {
        setLoading(false);
        console.error("Error while PATCH:" + response.statusText);
      }
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <h2>{dictionary.admin_panel_module_mgmt_header}</h2>
      <div className="management-wrapper mgmt-module">
        {loading && (
          <div className="loading-wrapper">
              <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="2" animationDuration="3s" />
          </div>
        )}
        <div className="row">
          <div className="col">
          <p>{dictionary.admin_panel_mod_mgmt_info}</p>
          <div className="p-field">
            <label>{dictionary.admin_panel_label_client}</label>
            <Dropdown
              className={
                selectedClient === null && formSubmitted ? "p-invalid" : null
              }
              value={selectedClient}
              options={clients}
              onChange={handleClientChange}
              placeholder={dictionary.admin_panel_placeholder_client}
            />
            {selectedClient === null && formSubmitted ? (
              <small className="p-error">{dictionary.dialog_error_client}</small>
            ) : null}
          </div>
          {selectedClient !== "ELD" && selectedClient !== "Other" ? (
            <div className="p-field">
              <label>{dictionary.admin_panel_label_mod_type}</label>
              <Dropdown
                className={
                  selectedModuleType === null && formSubmitted
                    ? "p-invalid"
                    : null
                }
                value={selectedModuleType}
                options={moduleTypes}
                onChange={handleModuleTypeChange}
                placeholder={dictionary.admin_panel_placeholder_mod_type}
              />
              {selectedModuleType === "Other" ? (
                <>
                  <Button
                    className="minor-proj-btn"
                    label={
                      !addMinorProj
                        ? dictionary.admin_panel_add_minor_proj_btn
                        : dictionary.admin_panel_add_minor_proj_back_btn
                    }
                    onClick={() => setAddMinorProj((prevState) => !prevState)}
                  />
                  <small>{dictionary.admin_panel_label_minor_info_label}</small>
                </>
              ) : null}
              {selectedModuleType === null && formSubmitted ? (
                <small className="p-error">
                  {dictionary.dialog_error_module_types}
                </small>
              ) : null}
            </div>
          ) : null}
          <div className="p-field">
            {!addMinorProj ? (
              <label>{dictionary.admin_panel_label_module}</label>
            ) : (
              <label>{dictionary.admin_panel_label_minor_proj}</label>
            )}
            <small>{dictionary.admin_panel_info_module}</small>
            <InputText
              className={moduleName === "" && formSubmitted ? "p-invalid" : null}
              value={moduleName}
              onChange={(e) => setModuleName(e.target.value)}
              placeholder={dictionary.admin_panel_placeholder_module}
            />
            {moduleName === "" && formSubmitted ? (
              <small className="p-error">{dictionary.dialog_error_module}</small>
            ) : null}
          </div>
          <div className="p-field b-field">
            <Button
              label={dictionary.admin_panel_add_module_btn}
              onClick={() => {
                setFormSubbmited(true);
                if (
                  moduleName !== "" &&
                  selectedModuleType !== null &&
                  selectedClient !== null
                ) {
                  handleAddModule(selectedClient, selectedModuleType);
                } else if (selectedClient === "ELD" || selectedClient === "Other" && moduleName !== "") {
                  handleAddModule(selectedClient, selectedModuleType);
                }
              }}
            />
          </div>
          </div>
        <div className="col col-2">
        <div>
          <p>{dictionary.admin_panel_export_info}</p>
          <Button
            label={dictionary.export_dialog_exp_btn}
            icon="pi pi-file-excel"
            onClick={exportToExcel}
          />
        </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default ModuleManagement;
