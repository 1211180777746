import { FETCH_ELD_DATA_REQUEST, FETCH_ELD_DATA_SUCCESS, FETCH_ELD_DATA_FAILURE } from "../actions/dataActions";

const initData = {
  data: null,
  isLoading: false,
  error: null,
};

const dataReducer = (state = initData, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ELD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_ELD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    case FETCH_ELD_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
