import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { dictionary } from "../../dictionary";
import { useNavigate } from "react-router-dom";
import MobileOverlay from "../common/MobileOverlay";

const Access = () => {
    const [resError, setResError] = useState(null);
    const [username, setUsername] = useState('eld_user');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const navigate = useNavigate();

    const addSuccessMsg = () => {
      toast.current.show({
        severity: "success",
        summary: dictionary.toast_summary_success,
        detail: dictionary.toast_access,
      });
    };

    const handleAccess = async (event) => {
      event.preventDefault();
      setLoading(true);
      try{
        const response = await fetch('https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/auth', {
          method: 'POST',
          mode: 'cors',
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            password:password
          })
        });
  
        if(response.ok){
          addSuccessMsg();
          setResError(null);
          const timer = setTimeout(() => {
              setLoading(false);
              navigate("/login", { replace: true });
          }, 1000);
          return () => clearTimeout(timer);
        }else{
          const message = await response.text();
          setResError(message);
          console.error(message);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    return(
        <>
            <MobileOverlay />
            <Dialog
              header={dictionary.login_access}
              style={{ width: "50vw" }}
              closable = {false}
              visible = {true}
            >
            {loading && (
                <div className="loading-wrapper">
                    <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="2" animationDuration="3s" />
                </div>
            )}
              <form className="p-fluid login-access" onSubmit={handleAccess} >
                <div className="p-field">
                    <label htmlFor="username">{dictionary.login_access_user}</label>
                    <InputText id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="p-field">
                    <label htmlFor="password">{dictionary.login_access_password}</label>
                    <Password id="password" placeholder={dictionary.login_access_password_enter} value={password} feedback={false} onChange={(e) => setPassword(e.target.value)} className={resError ? "p-invalid" : null} toggleMask/>
                    {resError ? <small className="p-error">{resError}</small> : null}
                </div>
                <Button type="submit" label={dictionary.login_access_btn} />
              </form>
              <Toast ref={toast} />
            </Dialog>
        </>
    );
}

export default Access;