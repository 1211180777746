import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import SumTeamReport from "./SumTeamReport";
import EldLogo from "../../../assets/logos/Logo-ELD-02 transparent.png";
import { ProgressSpinner } from "primereact/progressspinner";
import { dictionary } from "../../../dictionary";
import {
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isWeekend,
} from "date-fns";

const TeamsMenu = (props) => {
  const properties = props.properties;
  const users = properties.eldData[0].users;
  const qct = users.filter((user) => user.team === "qct").length;
  const devt = users.filter((user) => user.team === "devt").length;
  const loct = users.filter((user) => user.team === "loct").length;
  const indet = users.filter((user) => user.team === "indet").length;
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showCalendar, setShowCalendar] = useState(true);
  const [activeBtn, setActiveBtn] = useState(null);
  const [showComponent, setShowComponent] = useState(false);
  const [teamIdToFilter, setTeamToFilter] = useState(null);
  const [postsByMonth, setPostsByMonth] = useState({});
  const [teamName, setTeamName] = useState(null);
  const holidays = [
    "01-01",
    "01-06",
    "05-01",
    "05-03",
    "08-15",
    "11-01",
    "11-11",
    "12-25",
    "12-26",
  ];
  //rcp handlers
  const [fullJobs, setFullJobs] = useState(0);
  const rate = 7.75;

  const isHoliday = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return holidays.includes(`${month}-${day}`);
  };

  const getWorkingDays = (start, end) => {
    const days = eachDayOfInterval({ start, end });
    return days.filter((day) => !isWeekend(day) && !isHoliday(day)).length;
  };

  const generateWorkingDaysArray = () => {
    const currentYear = new Date().getFullYear();
    const start = new Date(currentYear, 2, 1); // Marzec
    const end = new Date(currentYear + 1, 1, 28); // Luty następnego roku
    const months = eachMonthOfInterval({ start, end });
    return months.map((month) =>
      getWorkingDays(startOfMonth(month), endOfMonth(month))
    );
  };

  const wd = generateWorkingDaysArray();

  const chartDateIndex = () => {
    let date = new Date();
    let month = date.getMonth();
    let year = date.getFullYear();

    if (month === 0) {
        month = 11;
        year--;
    } else {
        month--;
    }

    const fiscalYearStart = 2;
    let index = (month - fiscalYearStart + 12) % 12;

    return index;
};


  const handleFetchData = async () => {
    const response = await fetch(
      "https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts/monthly?esp=empty", {
        method: 'GET',
        mode: 'cors',
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(response.message || "Failed to fetch posts!");
    }
    setPostsByMonth(responseData);
    setShowCalendar(false);
    setShowComponent(true);
    setLoading(false);
  };

  const handleButtonClick = (btnId) => {
    setActiveBtn(btnId);
  };

  const teams = [
    { name: "QC Team", filter: "qct", jobs: qct, buttonIndex: 1 },
    { name: "Content Team", filter: "indet", jobs: indet, buttonIndex: 2 },
    { name: "Development Team", filter: "devt", jobs: devt, buttonIndex: 3 },
    { name: "Localization Team", filter: "loct", jobs: loct, buttonIndex: 4 },
  ];

  const renderButton = (team, index) => (
    <Button
      key={index}
      label={dictionary[`productivity_teams_btn_${team.filter}`]}
      icon="pi pi-table"
      onClick={(e) => {
        setTeamToFilter(team.filter);
        setFullJobs(team.jobs);
        setTeamName(team.name);
        handleButtonClick(team.buttonIndex);
        setDisabled(false);
      }}
      disabled={activeBtn === team.buttonIndex}
    />
  );

  const start = (
    <div className="productivity-nav">
      <Button
        icon="pi pi-arrow-circle-left"
        onClick={() => {
          properties.setClient(null);
          properties.setClientChoice(false);
          setDisabled(true);
          setShowCalendar(true);
          setActiveBtn(null);
          setShowComponent(false);
          setTeamToFilter(null);
        }}
      />
      <Button
        className="productivity-export"
        icon="pi pi-file-excel"
        label={dictionary.export_btn}
        onClick={() => {
          properties.onDownload();
          properties.resetStateToInitialValues();
        }}
      />
    </div>
  );
  const end = <img alt="logo" src={EldLogo} className="mr-2"></img>;

  const tableProps = {
    eldData: properties.eldData,
    postsByMonth: postsByMonth,
    teamIdToFilter: teamIdToFilter,
    monthNames: properties.monthNames,
    wd: wd,
    fullJobs: fullJobs,
    rate: rate,
    setFixRef: properties.setFixRef,
    chartDateIndex: chartDateIndex(),
    teamName: teamName,
  };

  return (
    <>
      {showCalendar ? (
        <div className="field-calendar-wrapper">
          {loading && (
            <div className="loading-wrapper">
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="2"
                animationDuration="3s"
              />
            </div>
          )}
          <div className="button-wrapper">{teams.map(renderButton)}</div>
          <div className="export-btn-wrapper">
            <Button
              label={dictionary.export_dialog_preview_btn}
              icon="pi pi-eye"
              disabled={disabled}
              onClick={() => {
                setLoading(true);
                handleFetchData();
              }}
            />
          </div>
        </div>
      ) : (
        <div className="productivity-teams-wrapper">
          <Menubar start={start} end={end} />
          {showComponent ? <SumTeamReport tableProps={tableProps} /> : null}
        </div>
      )}
    </>
  );
};

export default TeamsMenu;
