import React, { useState, useEffect, useRef } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import { Checkbox } from "primereact/checkbox";
import EldLogo from "../../../assets/logos/Logo-ELD-02 transparent.png";
import ProductivityResources from "./resources/ProductivityResources";
import ProductivityManagement from "./resources/ProductivityManagement";
import ProductivityTrainings from "./resources/ProductivityTrainings";
import ProductivityRcp from "./resources/ProductivityRcp";
import { ProgressSpinner } from "primereact/progressspinner";
import ProductivityCounties from "./tables/ProductivityCountries";
import ProductivityINT from "./tables/ProductivityINT";
import ProductivityLogistic from "./tables/ProductivityLogistic";
import SummaryHoursSales from "./tables/SummaryHoursSales";
import SummaryHoursLogistic from "./tables/SummaryHoursLogistic";
import ESPSales from "./tables/ESPSales";
import ESPLogistic from "./tables/ESPLogistic";
import IntEployeesHours from "./tables/IntEmployeesHours";
import SalesSum from "./tables/SalesSum";
import LogisticSum from "./tables/LogisticSum";
import CountrySum from "./tables/CountrySum";
import Proportion from "./tables/Proportion";
import CostSales from "./tables/CostSales";
import CostLogistic from "./tables/CostsLogistic";
import BillForCountry from "./tables/BillForCountry";
import { dictionary } from "../../../dictionary";

const Productivity = (props) => {
  const settings = props.properties;
  const [loading, setLoading] = useState(false);
  const emplRate = 7.75;
  const emplRate2 = 6.75;
  const intRate = 7.75;
  const costConstant = 1.1;
  const costRate = 23;

  const [date, setDate] = useState(new Date());
  const [date2, setDate2] = useState(new Date());

  const [workingDays, setWorkingDays] = useState(21);
  const [employees, setEmployyes] = useState(23);
  const [employees2, setEmployyes2] = useState(1);
  const [intEmployees, setIntEmployyes] = useState(2);
  const [trainings, setTrainings] = useState(0);
  const [absences, setAbsences] = useState(0);
  const [sickLeave, setSickLeave] = useState(0);
  const [specialLeave, setSpecialLeave] = useState(0);
  const [childcare, setChildcare] = useState(0);
  const [holidays, setHolidays] = useState(0);
  const [managerAbsence, setManagerAbsence] = useState(0);
  const [showProductivity, setShowProductivity] = useState(false);
  const [showCalendar, setShowCalendar] = useState(true);
  const [monthlyData, setMonthlyData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [disabledCalendar, setDisabledCalendar] = useState(true);
  const [checkedCalendar, setCheckedCalendar] = useState(true);
  const tableRef = useRef(null);
  const [countriesCostsSalesArray, setCountriesCostsSalesArray] = useState([]);
  const [countriesCostsLogisticArray, setCountriesCostsLogisticArray] =
    useState([]);
  const [countriesProportionArray, setcountriesProportionArray] = useState([]);
  const [intOtherModulesSumArray, setIntOtherModulesSumArray] = useState([]);

  const handleFetchData = async () => {
    let url = "https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts/";
    disabledCalendar
      ? (url += `productivity/${date}`)
      : (url += `productivity/${date}/${date2}`);

    fetch(url, {
      method: "GET",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((posts) => {
        setMonthlyData(posts);
        setShowCalendar(false);
        setShowProductivity(true);
        setLoading(false);
      });
  };

  const start = (
    <div className="productivity-nav">
      <Button
        icon="pi pi-arrow-circle-left"
        onClick={() => {
          settings.setClient(null);
          settings.setClientChoice(false);
        }}
      />
      <Button
        className="productivity-export"
        icon="pi pi-file-excel"
        label={dictionary.export_btn}
        onClick={() => {
          settings.onDownload();
          settings.resetStateToInitialValues();
        }}
      />
    </div>
  );
  const end = <img alt="logo" src={EldLogo} className="mr-2"></img>;

  //INT:
  const intFtf20Sum = monthlyData
    .filter((data) => data.client === "INT" && data.module_type === "FtF_2.0")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const intMa30Sum = monthlyData
    .filter(
      (data) =>
        data.client === "INT" &&
        (data.module_type === "MA_3.0" || data.module_type === "MA")
    )
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const locMan = monthlyData
    .filter(
      (data) =>
        data.client === "INT" && data.module_type === "Localization_management"
    )
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const intNuggetsSum = monthlyData
    .filter((data) => data.client === "INT" && data.module_type === "Nuggets")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const intSingleModuleSum = (module) => {
    const res = monthlyData
      .filter((data) => data.client === "INT" && data.module === module.name)
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  //Summary:
  const summarySalesAllCountires = monthlyData
    .filter(
      (data) =>
        data.client === "COUNTRY" &&
        data.module_type !== "Logistics" &&
        data.country &&
        data.module_type !== null &&
        data.esp === ""
    )
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const summarySalesAllCountiresSingleCountry = (country) => {
    const res = monthlyData
      .filter(
        (data) =>
          data.client === "COUNTRY" &&
          data.module_type !== "Logistics" &&
          data.country === country.name &&
          data.module_type !== null &&
          data.esp === ""
      )
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const summaryHoursLogistic = monthlyData
    .filter((data) => data.client === "LOGISTICS")
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const summaryHoursLogisticCountry = monthlyData
    .filter(
      (data) =>
        data.client === "COUNTRY" &&
        data.module_type === "Logistics" &&
        data.esp === ""
    )
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const summaryHoursLogisticsSingleCountry = (country) => {
    const res = monthlyData
      .filter(
        (data) =>
          data.client === "COUNTRY" &&
          data.country === country.name &&
          data.module_type === "Logistics" &&
          data.esp === ""
      )
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const espSalesAllCountrySum = monthlyData
    .filter(
      (data) =>
        data.client === "COUNTRY" &&
        data.esp !== "" &&
        data.module_type !== "Logistics"
    )
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const espSalesCountrySum = (country) => {
    const res = monthlyData
      .filter(
        (data) =>
          data.client === "COUNTRY" &&
          data.country === country.name &&
          data.esp !== "" &&
          data.module_type !== "Logistics"
      )
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const espSalesSum = monthlyData
    .filter(
      (data) =>
        data.client === "COUNTRY" &&
        data.esp !== "" &&
        data.module_type !== "Logistics"
    )
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const espLogisticsSum = monthlyData
    .filter(
      (data) =>
        data.client === "COUNTRY" &&
        data.esp !== "" &&
        data.module_type === "Logistics"
    )
    .map((post) => post.hours)
    .reduce((acc, hours) => acc + hours, 0);

  const espLogisticsCountrySum = (country) => {
    const res = monthlyData
      .filter(
        (data) =>
          data.client === "COUNTRY" &&
          data.country === country.name &&
          data.esp !== "" &&
          data.module_type === "Logistics"
      )
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };

  const allCountriesCountrySum =
    summarySalesAllCountires +
    espSalesAllCountrySum +
    summaryHoursLogisticCountry +
    espLogisticsSum;
  const sumAllIntOtherModulesSumArray = intOtherModulesSumArray.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const sumAllCountriesProportionArray = countriesProportionArray.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const sumAllCountriesCostsSalesArray = countriesCostsSalesArray.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const sumAllCountriesCostsLogisticArray = countriesCostsLogisticArray.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const salesModuleSum =
    sumAllIntOtherModulesSumArray +
    intFtf20Sum +
    intMa30Sum +
    intNuggetsSum +
    locMan;
  const proportion2 =
    Math.round(
      (salesModuleSum /
        (salesModuleSum + summaryHoursLogistic + allCountriesCountrySum)) *
        100 *
        10
    ) / 10;
  const proportion3 =
    Math.round(
      (summaryHoursLogistic /
        (salesModuleSum + summaryHoursLogistic + allCountriesCountrySum)) *
        100 *
        10
    ) / 10;
  const proportion1 = Math.round(
    proportion2 + proportion3 + sumAllCountriesProportionArray
  );
  const costSalesINT = parseFloat(
    (salesModuleSum * costRate * costConstant).toFixed(2)
  );
  const costLogisicsINT = parseFloat(
    (summaryHoursLogistic * costRate * costConstant).toFixed(2)
  );

  useEffect(() => {
    settings.setFixRef(tableRef.current);
  }, [showProductivity]);

  useEffect(() => {
    const newProportions = settings.eldData[0].countries.map((country, idx) => {
      let ctySum =
        summarySalesAllCountiresSingleCountry(country) +
        espSalesCountrySum(country) +
        summaryHoursLogisticsSingleCountry(country) +
        espLogisticsCountrySum(country);
      let res =
        ((ctySum /
          (salesModuleSum + summaryHoursLogistic + allCountriesCountrySum)) *
          100 *
          10) /
        10;
      return res;
    });
    setcountriesProportionArray(newProportions);
  }, [salesModuleSum]);

  useEffect(() => {
    const newCostsSales = settings.eldData[0].countries.map((country, idx) => {
      let ctySum =
        summarySalesAllCountiresSingleCountry(country) +
        espSalesCountrySum(country);
      let res = ctySum * costRate * costConstant;
      return res;
    });
    setCountriesCostsSalesArray(newCostsSales);
  }, [monthlyData]);

  useEffect(() => {
    const newCostsLogistic = settings.eldData[0].countries.map(
      (country, idx) => {
        let ctySum =
          summaryHoursLogisticsSingleCountry(country) +
          espLogisticsCountrySum(country);
        let res = ctySum * costRate * costConstant;
        return res;
      }
    );
    setCountriesCostsLogisticArray(newCostsLogistic);
  }, [monthlyData]);

  useEffect(() => {
    const newIntOtherModulesSum = settings.eldData[0].int[0].other.map(
      (module, idx) => {
        let modSum = intSingleModuleSum(module);
        return modSum;
      }
    );
    setIntOtherModulesSumArray(newIntOtherModulesSum);
  }, [monthlyData]);

  const styles = {
    header: {
      background: "#FFF2CC",
      border: "solid black",
      borderWidth: "thin",
      width: "auto",
      padding: ".5rem",
    },
    blank: {
      background: "#D9D9D9",
      border: "solid black",
      borderWidth: "thin",
    },
    ftf: {
      background: "#F4B084",
      border: "solid black",
      borderWidth: "thin",
    },
    ma: { background: "#92D050", border: "solid black", borderWidth: "thin" },
    nugget: {
      background: "#D0C3DF",
      border: "solid black",
      borderWidth: "thin",
    },
    nuggetint: {
      background: "#BDD7EE",
      border: "solid black",
      borderWidth: "thin",
    },
    other: {
      background: "#C6E0B4",
      border: "solid black",
      borderWidth: "thin",
    },
    logistics: {
      background: "#A6A6A6",
      border: "solid black",
      borderWidth: "thin",
    },
    int: { background: "#FFD966", border: "solid black", borderWidth: "thin" },
    logistics_modules: {
      background: "#8497B0",
      border: "solid black",
      borderWidth: "thin",
    },
    ctysum: {
      background: "#A9D08E",
      border: "solid black",
      borderWidth: "thin",
    },
    proportion: {
      background: "fff",
      color: "red",
      border: "solid black",
      borderWidth: "thin",
    },
    salesRate: {
      background: "#C6E0B4",
      color: "red",
      border: "solid black",
      borderWidth: "thin",
    },
    logisticsRate: {
      background: "#8497B0",
      color: "red",
      border: "solid black",
      borderWidth: "thin",
    },
    bill: {
      background: "#A9D08E",
      color: "red",
      border: "solid black",
      borderWidth: "thin",
    },
    default: { background: "#fff", border: "solid black", borderWidth: "thin" },
  };

  const resourcesStyles = {
    darktd: {
      background: "#808080",
      color: "white",
      border: "solid black",
      borderWidth: "thin",
      padding: ".5rem",
    },
    lighttd: {
      background: "#F2F2F2",
      color: "black",
      border: "solid black",
      borderWidth: "thin",
      padding: ".5rem",
    },
    bluetd: {
      background: "#B4C6E7",
      color: "black",
      border: "solid black",
      borderWidth: "thin",
      padding: ".5rem",
    },
    default: { background: "#fff", border: "solid black", borderWidth: "thin" },
  };

  const properties = {
    workingDays: workingDays,
    employees: employees,
    employees2: employees2,
    intEmployees: intEmployees,
    emplRate: emplRate,
    emplRate2: emplRate2,
    costRate: costRate,
    summarySalesAllCountires: summarySalesAllCountires,
    salesModuleSum: salesModuleSum,
    managerAbsence: managerAbsence,
    monthlyData: monthlyData,
    summaryHoursLogisticCountry: summaryHoursLogisticCountry,
    summaryHoursLogistic: summaryHoursLogistic,
    countries: settings.eldData[0].countries,
    other: settings.eldData[0].int[0].other,
    styles: styles,
    intRate: intRate,
    trainings: trainings,
    absences: absences,
    sickLeave: sickLeave,
    specialLeave: specialLeave,
    childcare: childcare,
    holidays: holidays,
    intFtf20Sum: intFtf20Sum,
    intMa30Sum: intMa30Sum,
    intNuggetsSum: intNuggetsSum,
    logistics: settings.eldData[0].logistics,
    espSalesSum: espSalesSum,
    espLogisticsSum: espLogisticsSum,
    espSalesAllCountrySum: espSalesAllCountrySum,
    allCountriesCountrySum: allCountriesCountrySum,
    proportion1: proportion1,
    proportion2: proportion2,
    proportion3: proportion3,
    sumAllCountriesCostsSalesArray: sumAllCountriesCostsSalesArray,
    sumAllCountriesCostsLogisticArray: sumAllCountriesCostsLogisticArray,
    costLogisicsINT: costLogisicsINT,
    costSalesINT: costSalesINT,
    costConstant: costConstant,
    locMan: locMan,
    summarySalesAllCountiresSingleCountry:
      summarySalesAllCountiresSingleCountry,
    summaryHoursLogisticsSingleCountry: summaryHoursLogisticsSingleCountry,
    espSalesCountrySum: espSalesCountrySum,
    espLogisticsCountrySum: espLogisticsCountrySum,
    intSingleModuleSum: intSingleModuleSum,
    setWorkingDays: setWorkingDays,
    setEmployyes: setEmployyes,
    setEmployyes2: setEmployyes2,
    setIntEmployyes: setIntEmployyes,
    setManagerAbsence: setManagerAbsence,
    setTrainings: setTrainings,
    setAbsences: setAbsences,
    setSickLeave: setSickLeave,
    setSpecialLeave: setSpecialLeave,
    setChildcare: setChildcare,
    setHolidays: setHolidays,
  };

  return (
    <div className="productivity-container">
      {loading && (
        <div className="loading-wrapper">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="2"
            animationDuration="3s"
          />
        </div>
      )}
      {showCalendar ? (
        <div className="field-calendar-wrapper">
          <label>{dictionary.export_calendar_from}</label>
          <Calendar
            style={{ width: "100%" }}
            placeholder={dictionary.export_dialog_calendar_placeholder}
            view="month"
            dateFormat="mm/yy"
            value={date.length > 1 ? null : date}
            onChange={(e) => {
              setDate(e.value);
            }}
            showIcon
          />
          <label>{dictionary.export_calendar_to}</label>
          <Calendar
            style={{ width: "100%" }}
            placeholder={dictionary.export_dialog_calendar_placeholder}
            view="month"
            dateFormat="mm/yy"
            value={date2}
            onChange={(e) => {
              setDate2(e.value);
            }}
            showIcon
            disabled={disabled}
          />
          <div className="checkbox-wrapper">
            <Checkbox
              onChange={(e) => {
                setCheckedCalendar(e.checked);
                disabled ? setDisabled(false) : setDisabled(true);
                disabledCalendar
                  ? setDisabledCalendar(false)
                  : setDisabledCalendar(true);
              }}
              checked={checkedCalendar}
            ></Checkbox>
            <small className="checkbox-info">
              {dictionary.export_dialog_checkbox_month}
            </small>
          </div>
          <div className="export-btn-wrapper">
            <Button
              label={dictionary.export_dialog_preview_btn}
              icon="pi pi-eye"
              onClick={() => {
                setLoading(true);
                handleFetchData();
              }}
            />
          </div>
        </div>
      ) : null}
      {showProductivity ? (
        <div className="productivity-wrapper">
          <Menubar start={start} end={end} />
          {disabledCalendar ? (
            <h2>
              {"Productivity" +
                dictionary.export_dialog_raport_title +
                " " +
                date.toLocaleString("en-EN", { month: "long" }) +
                " " +
                date.getFullYear()}
            </h2>
          ) : (
            <h2>
              {"Productivity" +
                dictionary.export_dialog_raport_title +
                " " +
                dictionary.export_dialog_from +
                date.toLocaleString("en-EN", { month: "long" }) +
                " " +
                date.getFullYear() +
                " " +
                dictionary.export_dialog_to +
                date2.toLocaleString("en-EN", { month: "long" }) +
                " " +
                date2.getFullYear()}
            </h2>
          )}
          <div className="rcp-table-wrapper">
            <ProductivityRcp properties={properties} />
          </div>
          <div className="productivity-table-wrapper">
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th className="header" style={styles.header}>
                    {dictionary.productivity_table_th1}
                  </th>
                  <th className="header" style={styles.header}>
                    {dictionary.productivity_table_th2}
                  </th>
                  <th className="header" style={styles.header}>
                    {dictionary.productivity_table_th3}
                  </th>
                  <th className="header" style={styles.header}>
                    {dictionary.productivity_table_th4}
                  </th>
                  <th className="header" style={styles.header}>
                    {dictionary.productivity_table_th5}
                  </th>
                  {settings.eldData[0].countries.map((country, idx) => {
                    return (
                      <th key={idx} className="header" style={styles.header}>
                        {country.code}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <ProductivityCounties properties={properties} />
                <ProductivityINT properties={properties} />
                <ProductivityLogistic properties={properties} />
                <tr>
                  <th>{dictionary.productivity_table_summary_overview}</th>
                </tr>
                <SummaryHoursSales properties={properties} styles={styles} />
                <SummaryHoursLogistic properties={properties} styles={styles} />
                <ESPSales properties={properties} styles={styles} />
                <ESPLogistic properties={properties} styles={styles} />
                <IntEployeesHours properties={properties} styles={styles} />
                <SalesSum properties={properties} styles={styles} />
                <LogisticSum properties={properties} styles={styles} />
                <CountrySum properties={properties} styles={styles} />
                <Proportion properties={properties} styles={styles} />
                <CostSales properties={properties} styles={styles} />
                <CostLogistic properties={properties} styles={styles} />
                <BillForCountry properties={properties} styles={styles} />
                <tr className="res-row">
                  <th>{dictionary.productivity_table_resources}</th>
                </tr>
                <ProductivityResources
                  properties={properties}
                  resourcesStyles={resourcesStyles}
                />
                <tr className="res-row">
                  <th style={{ color: "red" }}>
                    {dictionary.productivity_table_management}
                  </th>
                </tr>
                <ProductivityManagement
                  properties={properties}
                  resourcesStyles={resourcesStyles}
                />
                <tr className="res-row">
                  <th style={{ color: "red" }}>
                    {dictionary.productivity_table_management_trainings}
                  </th>
                </tr>
                <ProductivityTrainings
                  properties={properties}
                  resourcesStyles={resourcesStyles}
                />
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Productivity;
