import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { dictionary } from "../../../../dictionary";

const TeamsRcpData = (props) => {
  const { calcProps, calcStyles } = props;
  const { fullJobs, wd } = calcProps;
  const [newJobs, setNewJobs] = useState(
    Array.from({ length: wd.length }, () => fullJobs)
  );
  const [jobvalue, setJobvalue] = useState(false);

  const changeFullJobs = () => {
    setNewJobs(
      jobvalue ? Array.from({ length: wd.length }, () => fullJobs) : newJobs
    );
    setJobvalue(!jobvalue);
  };

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    setNewJobs((state) =>
      state.map((val, i) => (i !== index ? val : parseFloat(value)))
    );
  };
  
  const renderCell = (value, index) => (
    <td key={index} className="center" style={calcStyles.center}>
      {isNaN(value) ? "0" : value}
    </td>
  );

  return (
    <>
      <tr>
        <th>{dictionary.productivity_teams_table_rcp_label1}</th>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={calcStyles.default}>
          {dictionary.productivity_teams_table_rcp_label2}
        </th>
        {wd.map((day, index) => renderCell(day, index))}
        <td className="default" style={calcStyles.default}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={calcStyles.default}>
          {dictionary.productivity_teams_table_rcp_label3}
        </th>
        {wd.map((_, index) => (
          <td key={index} className="center" style={calcStyles.center}>
            <InputText
              type="number"
              value={newJobs[index]}
              onClick={changeFullJobs}
              onChange={(e) => handleInputChange(e, index)}
            />
          </td>
        ))}
        <td className="default" style={calcStyles.default}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={calcStyles.default}>
          {dictionary.productivity_teams_table_rcp_label4}
        </th>
        {wd.map((day, index) => renderCell(day * 8, index))}
        <td className="center" style={calcStyles.center}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={calcStyles.default}>
          {dictionary.productivity_teams_table_rcp_label5}
        </th>
        {wd.map((day, index) => renderCell(day * newJobs[index] * 8, index))}
        <td className="center" style={calcStyles.center}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={calcStyles.default}>
          {dictionary.productivity_teams_table_rcp_label6}
        </th>
        {wd.map((day, index) =>
          renderCell(
            calcProps.monthlySum[index] - day * newJobs[index] * 8,
            index
          )
        )}
        <td className="green" style={calcStyles.green}></td>
      </tr>
    </>
  );
};

export default TeamsRcpData;
