import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { useSelector, useDispatch } from "react-redux";
import { hideIntDialog } from "../../redux/actions/dialogActions.js";
import {
  clearDate,
  setComment,
  setDate,
  setHour,
  setModule,
  setModType,
  setStage,
  setTask,
  clearModule,
  clearStage,
} from "../../redux/actions/logActions.js";
import { dictionary } from "../../dictionary.js";
import DialogOverlay from "./DialogOverlay.js";

const IntComponent = (props) => {
  const dispatch = useDispatch();
  const dialogProps = props.dialogProps;
  const [dateLog, setDateLog] = useState(
    new Date(useSelector((state) => state.date.value))
  );
  const moduleLog = useSelector((state) => state.module.value);
  const modTypeLog = useSelector((state) => state.modType.value);
  const stageLog = useSelector((state) => state.stage.value);
  const taskLog = useSelector((state) => state.task.value);
  const hourLog = useSelector((state) => state.hours.value);
  const commentLog = useSelector((state) => state.comment.value);
  const toast = useRef(null);

  const showMsg = () => {
    toast.current.show({
      severity: "warn",
      summary: dictionary.toast_summary_warn,
      detail: dictionary.toast_create_log_detail,
      life: 7000,
    });
  };

  const resetIntButtons = () => {
    dispatch(clearModule());
    dispatch(clearStage());
  };

  return (
    <>
      <Dialog
        header={dictionary.int_dialog_header}
        visible={dialogProps.isDialogOpen}
        style={{ width: "50vw" }}
        onHide={() => {
          dialogProps.setValuesOnInitialState();
          dialogProps.setShowOverlay(true);
          dispatch(hideIntDialog());
        }}
      >
        <div className="field field-wrapper">
          <Toast ref={toast} />
          <label>{dictionary.dialog_label_date}</label>
          {dialogProps.showOverlay && (
            <DialogOverlay dialogProps={dialogProps} />
          )}
          <Calendar
            disabledDays={dialogProps.disabledDays}
            style={{ width: "100%" }}
            placeholder={dateLog}
            value={dateLog}
            onChange={(e) => {
              if (dialogProps.checkIfDayIsPlanned(e.value)) {
                dispatch(setDate(e.value));
                setDateLog(e.value);
                dialogProps.setShowOverlay(false);
              } else {
                showMsg();
                dispatch(clearDate());
                setDateLog(new Date());
              }
            }}
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <>
          <div className="field field-wrapper">
            <label>{dictionary.dialog_label_module_types}</label>
            <div
              className={
                modTypeLog === null && dialogProps.formSubmitted
                  ? "row row-invalid"
                  : "row"
              }
            >
              <div className="col">
                <Button
                  className="buttons"
                  label={dictionary.dialog_button_label1}
                  onClick={() => {
                    resetIntButtons();
                    dispatch(setModType("FtF_2.0"));
                  }}
                />
                <Button
                  className="buttons"
                  label={dictionary.dialog_button_label2}
                  onClick={() => {
                    resetIntButtons();
                    dispatch(setModType("MA_3.0"));
                  }}
                />
                <Button
                  className="buttons"
                  label={dictionary.dialog_button_label3}
                  onClick={() => {
                    resetIntButtons();
                    dispatch(setModType("Nuggets"));
                  }}
                />
              </div>
              <div className="col">
                <Button
                  className="buttons"
                  label={dictionary.dialog_button_label4}
                  onClick={() => {
                    resetIntButtons();
                    dispatch(setModType("Other"));
                  }}
                />
                <Button
                  className="buttons"
                  label={dictionary.dialog_button_label5}
                  onClick={() => {
                    resetIntButtons();
                    dispatch(setModType("Localization_management"));
                    dispatch(setModule("Localization_management"));
                  }}
                />
              </div>
            </div>
          </div>
          {modTypeLog === null && dialogProps.formSubmitted ? (
            <small className="p-error">Module type is required.</small>
          ) : null}
        </>
        {modTypeLog !== null && modTypeLog === "FtF_2.0" ? (
          <div className="field field-wrapper">
            <label>{dictionary.dialog_button_label1}</label>
            <div className="field-dropdown-wrapper">
              <Dropdown
                style={{ width: "100%" }}
                value={moduleLog}
                options={
                  dialogProps.eldData[0].length !== 0
                    ? dialogProps.eldData[0].int[0].ftf_2_0.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                    : null
                }
                filter
                onChange={(e) => {
                  dispatch(setModule(e.value.name));
                }}
                optionLabel="name"
                placeholder={
                  moduleLog !== null
                    ? moduleLog
                    : dictionary.dialog_placeholder_module
                }
                className={
                  moduleLog === null && dialogProps.formSubmitted
                    ? "p-invalid"
                    : null
                }
              />
              {moduleLog === null && dialogProps.formSubmitted ? (
                <small className="p-error">
                  {dictionary.dialog_error_module}
                </small>
              ) : null}
              <Dropdown
                style={{ width: "100%", marginTop: "0.5rem" }}
                value={stageLog}
                options={dialogProps.eldData[0].stages_development}
                filter
                onChange={(e) => {
                  dispatch(setStage(e.value.name));
                }}
                optionLabel="name"
                placeholder={
                  stageLog !== null
                    ? stageLog
                    : dictionary.dialog_placeholder_stage
                }
                className={
                  stageLog === null && dialogProps.formSubmitted
                    ? "p-invalid"
                    : null
                }
              />
              {stageLog === null && dialogProps.formSubmitted ? (
                <small className="p-error">
                  {dictionary.dialog_error_stage}
                </small>
              ) : null}
            </div>
          </div>
        ) : null}
        {modTypeLog !== null && modTypeLog === "MA_3.0" ? (
          <div className="field field-wrapper">
            <label>{dictionary.dialog_button_label2}</label>
            <div className="field-dropdown-wrapper">
              <Dropdown
                style={{ width: "100%" }}
                value={moduleLog}
                options={
                  dialogProps.eldData[0].length !== 0
                    ? dialogProps.eldData[0].int[0].ma.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                    : null
                }
                filter
                onChange={(e) => {
                  dispatch(setModule(e.value.name));
                }}
                optionLabel="name"
                placeholder={
                  moduleLog !== null
                    ? moduleLog
                    : dictionary.dialog_placeholder_module
                }
                className={
                  moduleLog === null && dialogProps.formSubmitted
                    ? "p-invalid"
                    : null
                }
              />
              {moduleLog === null && dialogProps.formSubmitted ? (
                <small className="p-error">
                  {dictionary.dialog_error_module}
                </small>
              ) : null}
              <Dropdown
                style={{ width: "100%", marginTop: "0.5rem" }}
                value={stageLog}
                options={dialogProps.eldData[0].stages_development}
                filter
                onChange={(e) => {
                  dispatch(setStage(e.value.name));
                }}
                optionLabel="name"
                placeholder={
                  stageLog !== null
                    ? stageLog
                    : dictionary.dialog_placeholder_stage
                }
                className={
                  stageLog === null && dialogProps.formSubmitted
                    ? "p-invalid"
                    : null
                }
              />
              {stageLog === null && dialogProps.formSubmitted ? (
                <small className="p-error">
                  {dictionary.dialog_error_stage}
                </small>
              ) : null}
            </div>
          </div>
        ) : null}
        {modTypeLog !== null && modTypeLog === "Nuggets" ? (
          <div className="field field-wrapper">
            <label>{dictionary.dialog_button_label3}</label>
            <div className="field-dropdown-wrapper">
              <Dropdown
                style={{ width: "100%" }}
                value={moduleLog}
                options={
                  dialogProps.eldData[0].length !== 0
                    ? dialogProps.eldData[0].int[0].nuggets.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                    : null
                }
                filter
                onChange={(e) => {
                  dispatch(setModule(e.value.name));
                }}
                optionLabel="name"
                placeholder={
                  moduleLog !== null
                    ? moduleLog
                    : dictionary.dialog_placeholder_module
                }
                className={
                  moduleLog === null && dialogProps.formSubmitted
                    ? "p-invalid"
                    : null
                }
              />
              {moduleLog === null && dialogProps.formSubmitted ? (
                <small className="p-error">
                  {dictionary.dialog_error_module}
                </small>
              ) : null}
              <Dropdown
                style={{ width: "100%", marginTop: "0.5rem" }}
                value={stageLog}
                options={dialogProps.eldData[0].stages_development}
                filter
                onChange={(e) => {
                  dispatch(setStage(e.value.name));
                }}
                optionLabel="name"
                placeholder={
                  stageLog !== null
                    ? stageLog
                    : dictionary.dialog_placeholder_stage
                }
                className={
                  stageLog === null && dialogProps.formSubmitted
                    ? "p-invalid"
                    : null
                }
              />
              {stageLog === null && dialogProps.formSubmitted ? (
                <small className="p-error">
                  {dictionary.dialog_error_stage}
                </small>
              ) : null}
            </div>
          </div>
        ) : null}
        {modTypeLog !== null && modTypeLog === "Other" ? (
          <div className="field field-wrapper">
            <label>{dictionary.dialog_button_label4}</label>
            <div className="field-dropdown-wrapper">
              <Dropdown
                style={{ width: "100%" }}
                value={moduleLog}
                options={
                  dialogProps.eldData[0].length !== 0
                    ? dialogProps.eldData[0].int[0].other.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                    : null
                }
                filter
                onChange={(e) => {
                  dispatch(setModule(e.value.name));
                }}
                optionLabel="name"
                placeholder={
                  moduleLog !== null
                    ? moduleLog
                    : dictionary.dialog_placeholder_module
                }
                className={
                  moduleLog === null && dialogProps.formSubmitted
                    ? "p-invalid"
                    : null
                }
              />
              {moduleLog === null && dialogProps.formSubmitted ? (
                <small className="p-error">
                  {dictionary.dialog_error_module}
                </small>
              ) : null}
              {moduleLog !== "INT_Spotkania" ? (
                <>
                  {moduleLog !== "INT_Drobne_projekty" ? (
                    <>
                      <Dropdown
                        style={{ width: "100%", marginTop: "0.5rem" }}
                        value={stageLog}
                        options={dialogProps.eldData[0].stages_development}
                        filter
                        onChange={(e) => {
                          dispatch(setStage(e.value.name));
                        }}
                        optionLabel="name"
                        placeholder={
                          stageLog !== null
                            ? stageLog
                            : dictionary.dialog_placeholder_stage
                        }
                      />
                      <InputText
                        style={{ width: "100%", marginTop: "0.5rem" }}
                        value={taskLog !== null ? taskLog : ""}
                        placeholder={dictionary.dialog_placeholder_task_write}
                        onChange={(e) => {
                          dispatch(setTask(e.target.value));
                        }}
                        className={
                          taskLog === null && dialogProps.formSubmitted
                            ? "p-invalid"
                            : null
                        }
                      />
                    </>
                  ) : (
                    <Dropdown
                      style={{ width: "100%", marginTop: "0.5rem" }}
                      value={taskLog}
                      options={dialogProps.eldData[0].projects_int.sort(
                        (a, b) => a.name.localeCompare(b.name)
                      )} //INT_Drobne_projekty
                      filter
                      onChange={(e) => {
                        dispatch(setTask(e.value.name));
                      }}
                      optionLabel="name"
                      placeholder={
                        taskLog !== null
                          ? taskLog
                          : dictionary.dialog_placeholder_task
                      }
                      className={
                        taskLog === null && dialogProps.formSubmitted
                          ? "p-invalid"
                          : null
                      }
                    />
                  )}

                  {taskLog === null && dialogProps.formSubmitted ? (
                    <small className="p-error">
                      {dictionary.dialog_error_task}
                    </small>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {modTypeLog !== null && modTypeLog === "Localization_management" ? (
          <>
            <div className="field field-wrapper">
              <label>{dictionary.dialog_button_label5}</label>
            </div>
          </>
        ) : null}
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_hours}</label>
          <Dropdown
            style={{ width: "100%" }}
            value={hourLog}
            options={dialogProps.eldData[0].hours}
            filter
            onChange={(e) => {
              dispatch(setHour(e.value.hour));
            }}
            optionLabel="hour"
            placeholder={
              hourLog !== 0 ? hourLog : dictionary.dialog_placeholder_hours
            }
            className={
              hourLog === 0 && dialogProps.formSubmitted ? "p-invalid" : null
            }
          />
          {hourLog === 0 && dialogProps.formSubmitted ? (
            <small className="p-error">{dictionary.dialog_error_hours}</small>
          ) : null}
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_comment}</label>
          <InputText
            style={{ width: "100%" }}
            value={commentLog !== null ? commentLog : ""}
            placeholder={dictionary.dialog_placeholder_comment}
            onChange={(e) => {
              dispatch(setComment(e.target.value));
            }}
          />
        </div>
        <div className="field field-wrapper">
          {!dialogProps.postEditing ? (
            <Button
              label={dictionary.dialog_save_btn}
              icon="pi pi-save"
              className="field-report-btn"
              onClick={() => {
                dialogProps.setFormSubbmited(true);
                dialogProps.setShowOverlay(true);
                if (
                  modTypeLog !== null &&
                  moduleLog !== null &&
                  stageLog !== null &&
                  hourLog !== 0
                ) {
                  dialogProps.onLogSave();
                } else if (
                  modTypeLog === "Other" &&
                  moduleLog !== null &&
                  taskLog !== null &&
                  hourLog !== 0
                ) {
                  dialogProps.onLogSave();
                } else if (
                  moduleLog === "Localization_management" &&
                  hourLog !== 0
                ) {
                  dialogProps.onLogSave();
                } else if (moduleLog === "INT_Spotkania") {
                  dialogProps.onLogSave();
                }
              }}
            />
          ) : (
            <Button
              label={dictionary.dialog_update_btn}
              icon="pi pi-save"
              className="field-report-btn"
              onClick={() => {
                dialogProps.setFormSubbmited(true);
                dialogProps.setShowOverlay(true);
                if (
                  modTypeLog !== null &&
                  moduleLog !== null &&
                  stageLog !== null &&
                  hourLog !== 0
                ) {
                  dialogProps.onRowEditComplete();
                } else if (
                  modTypeLog === "Other" &&
                  moduleLog !== null &&
                  taskLog !== null &&
                  hourLog !== 0
                ) {
                  dialogProps.onRowEditComplete();
                } else if (
                  modTypeLog === "Localization_management" &&
                  hourLog !== 0
                ) {
                  dialogProps.onRowEditComplete();
                } else if (moduleLog === "INT_Spotkania") {
                  dialogProps.onRowEditComplete();
                }
              }}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default IntComponent;
