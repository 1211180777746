import React from "react";

const ProductivityLogistic = (props) => {
  const properties = props.properties;
  const logisticsSingleModuleSum = (module) => {
    const res = properties.monthlyData
      .filter((data) => data.client === "LOGISTICS" && data.module === module.name)
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
    return res;
  };
  return (
    <>
      <tr>
        <th>Logistics</th>
      </tr>
      {properties.logistics.map((module, idx) => {
        return (
          <tr key={idx}>
            <th className="logistics_modules" style={properties.styles.logistics_modules}>
              {" "}
              {module.name}
            </th>
            <td className="logistics_modules" style={properties.styles.logistics_modules}></td>
            <td className="logistics_modules" style={properties.styles.logistics_modules}>
              {logisticsSingleModuleSum(module).toString().replace(".", ",")}
            </td>
            <td className="blank" style={properties.styles.blank}></td>
            <td className="logistics_modules" style={properties.styles.logistics_modules}>
              {logisticsSingleModuleSum(module).toString().replace(".", ",")}
            </td>
            {properties.countries.map((_, idx) => {
              return <td key={idx} className="blank" style={properties.styles.blank}></td>;
            })}
          </tr>
        );
      })}
    </>
  );
};

export default ProductivityLogistic;
