import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import allReducers from "../reducers/rootReducer";

const store = configureStore({ reducer: allReducers, middleware: [thunk] });
// console.log("initial state", store.getState());

// // const unsubscribe = store.subscribe(() => {
// //   console.log("Updated state", store.getState());
// // });

// // unsubscribe();

export default store;
