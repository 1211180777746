import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { dictionary } from "../../dictionary";
import { ProgressSpinner } from "primereact/progressspinner";

const Logout = () => {
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();

  const addSuccessMsg = () => {
    toast.current.show({
      severity: "success",
      summary: dictionary.toast_summary_success,
      detail: dictionary.logout_info,
    });
  };

  const handleLogout = async () => {
    setLoading(true);
    try{
      const response = await fetch('https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/auth/logout', {
        method: 'GET',
        mode: 'cors',
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      });

      if(response.ok){
        addSuccessMsg();
        const timer = setTimeout(() => {
          sessionStorage.clear();
          setLoading(false);
          navigate("/home");
        }, 1000);
        return () => clearTimeout(timer);
      }else{
        const message = await response.text();
        console.error(message);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div className="dialog-wrapper">
      {loading && (
        <div className="loading-wrapper">
            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="2" animationDuration="3s" />
        </div>
      )}
      <Toast ref={toast} />
    </div>
  );
};

export default Logout;
