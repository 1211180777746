import React from "react";
import { dictionary } from "../../../../dictionary";

const SummaryHoursSales = (props) => {
  const properties = props.properties;
  const styles = props.styles;
  
  return (
    <>
      <tr>
        <th className="default" style={styles.default}>
          {dictionary.productivity_table_summary_sales_hours}
        </th>
        <td className="default" style={styles.default}></td>
        <td className="default" style={styles.default}>
          {(properties.summarySalesAllCountires + properties.salesModuleSum)
            .toString()
            .replace(".", ",")}
        </td>
        <td className="default" style={styles.default}>
          {properties.salesModuleSum.toString().replace(".", ",")}
        </td>
        <td className="default" style={styles.default}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="default" style={styles.default}>
              {properties.summarySalesAllCountiresSingleCountry(country)
                .toString()
                .replace(".", ",")}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default SummaryHoursSales;
