import { FETCH_USER_POSTS_REQUEST, FETCH_USER_POSTS_SUCCESS, FETCH_USER_POSTS_FAILURE } from "../actions/postActions";

const initData = {
  data: [],
  isLoading: false,
  error: null,
  posts:[]
};

const postsReducer = (state = initData, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER_POSTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_USER_POSTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    case FETCH_USER_POSTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default postsReducer;
