import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Divider } from "primereact/divider";
import EldLogo from "../../assets/logos/Logo-ELD-02 transparent.png";
import { Menubar } from "primereact/menubar";
import Productivity from "./produtivity/Productivity";
import TeamsMenu from "./productivityTeams/teamsMenu";
import { ProgressSpinner } from "primereact/progressspinner";
import DefaultReportTable from "./DefaultReportTable";
import { dictionary } from "../../dictionary.js";
import { useNavigate } from "react-router-dom";
import ReportButtonComponent from "./utility/ReportButtonComponent";
import DropdownComponent from "./utility/DropdownComponent";
import CheckboxComponent from "./utility/CheckboxComponent";
import CalendarComponent from "./utility/CalendarCompoinent";

const ExportDialog = (props) => {
  const navigate = useNavigate();
  const admin = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user")).admin
    : null;
  const [formSubmitted, setFormSubbmited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState(new Date());
  const [dates2, setDates2] = useState(new Date());

  const [client, setClient] = useState(null);
  const [clientChoice, setClientChoice] = useState(false);
  const [logsToDisplay, setLogsToDisplay] = useState(null);
  const [countryLogistic, setCountryLogistic] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [fixRef, setFixRef] = useState(null);
  const [countryLog, setCountryLog] = useState(null);
  const [moduleTypeLog, setModuleTypeLog] = useState(null);
  const [checkedCalendar, setCheckedCalendar] = useState(true);
  const [disabledCalendar, setDisabledCalendar] = useState(true);

  const [uniqueModule, setUniqueModule] = useState([]);
  const [uniqueStage, setUniqueStage] = useState([]);
  const [minorProjects, setMinorProjects] = useState([]);
  const tableRef = useRef(null);
  const costRate = 25;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const start = (
    <div className="raport-nav">
      <Button
        icon="pi pi-arrow-circle-left"
        onClick={() => {
          resetStateToInitialValues();
        }}
      />
      <Button
        label={dictionary.export_dialog_exp_btn}
        icon="pi pi-file-excel"
        onClick={() => {
          onDownload();
          resetStateToInitialValues();
        }}
      />
    </div>
  );
  const end = <img alt="logo" src={EldLogo} className="mr-2"></img>;
  const buttonData = [
    {
      label: dictionary.export_btn_label1,
      icon: "pi pi-table",
      onClick: () => {
        setClient("ELD");
        setClientChoice(true);
      },
    },
    {
      label: dictionary.export_btn_label2,
      icon: "pi pi-table",
      onClick: () => {
        setClient("INT");
        setClientChoice(true);
      },
    },
    {
      label: dictionary.export_btn_label3,
      icon: "pi pi-table",
      onClick: () => {
        setClient("LOGISTICS");
        setClientChoice(true);
      },
    },
    {
      label: dictionary.export_btn_label4,
      icon: "pi pi-table",
      onClick: () => {
        setClient("COUNTRY");
        setClientChoice(true);
      },
    },
    {
      label: dictionary.export_btn_label5,
      icon: "pi pi-table",
      onClick: () => {
        setClient("COUNTRY");
        setModuleTypeLog({ name: "Logistics" });
        setClientChoice(true);
        setCountryLogistic(true);
      },
    },
  ];

  const adminButtonData = [
    {
      label: dictionary.export_btn_label6,
      icon: "pi pi-table",
      onClick: () => {
        setClient("Productivity");
        setClientChoice(true);
      },
    },
    {
      label: dictionary.export_btn_label7,
      icon: "pi pi-table",
      onClick: () => {
        setClient("Productivity teams");
        setClientChoice(true);
      },
    },
  ];

  const resetStateToInitialValues = () => {
    setDates(new Date());
    setDates2(new Date());
    setClient(null);
    setClientChoice(false);
    setLogsToDisplay(null);
    setShowPreview(false);
    setFixRef(null);
    setCountryLog(null);
    setCheckedCalendar(true);
    setDisabledCalendar(true);
    setModuleTypeLog(null);
    setUniqueModule([]);
    setUniqueStage([]);
    setCountryLogistic(false);
    setMinorProjects([]);
    setFormSubbmited(false);
  };

  useEffect(() => {
    setFixRef(tableRef.current);
  }, [showPreview]);

  const getFileName = () => {
    let name;
    if (countryLog !== null && moduleTypeLog === null) {
      name = countryLog.name + dictionary.export_filename_sum;
    } else if (countryLog !== null && moduleTypeLog !== null) {
      name =
        countryLog.name +
        "_" +
        moduleTypeLog.name +
        dictionary.export_filename_sum;
    } else {
      name = client + dictionary.export_filename_sum;
    }
    return name;
  };

  const getSheetName = () => {
    let m, y, sname;
    m = new Date().getMonth() + 1;
    y = new Date().getFullYear();
    sname = m + "_" + y;
    return sname;
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: fixRef,
    filename: getFileName() + "_" + getSheetName(),
    sheet: getSheetName(),
  });

  const updateHolderAndMinorProjects = (logs, holder, minorProjectsArr) => {
    logs.forEach((log) => {
      const key = `${log.module}`;
      holder[key] = (holder[key] || 0) + log.hours;
      if (
        log.module === "INT_Drobne_projekty" ||
        log.module === "Drobne_projekty"
      ) {
        minorProjectsArr.push({
          task: log.task,
          hours: log.hours,
        });
      }
    });
  };

  const createPreview = (logs) => {
    let holder = {};
    let result = [];
    let minorProjectsArr = [];
    if (countryLog === null) {
      updateHolderAndMinorProjects(logs, holder, minorProjectsArr);
      for (let prop in holder) {
        result.push({
          module: prop,
          hours: holder[prop],
        });
      }
      setMinorProjects(minorProjectsArr);
      setLogsToDisplay(result);
    } else {
      let minorProjectsArr = [];
      for (let i = 0; i < logs.length; i++) {
        const key =
          logs[i].module + "$" + logs[i].stage + "$" + logs[i].module_type;
        if (logs[i].module === "Drobne_projekty") {
          minorProjectsArr.push({
            comment: logs[i].comment,
            task: logs[i].task,
            hours: logs[i].hours,
          });
        }
        if (!holder[key]) {
          holder[key] = 0;
        }
        holder[key] += logs[i].hours;
      }
      setMinorProjects(minorProjectsArr);
      const mergeResult = {};
      for (const key in holder) {
        const module = key.split("$")[0];
        if (!mergeResult[module]) {
          mergeResult[module] = {
            module,
            stage: [],
            hours: [],
            module_type: [],
          };
        }
        mergeResult[module].stage.push(key.split("$")[1]);
        mergeResult[module].module_type.push(key.split("$")[2]);
        mergeResult[module].hours.push(holder[key]);
      }
      const inputArray = Object.values(mergeResult);
      const outputArray = inputArray.reduce((acc, cur) => {
        cur.stage.forEach((stage, i) => {
          const index = acc.findIndex(
            (item) => item.module === cur.module && item.stage === stage
          );
          if (index >= 0) {
            acc[index].hours += cur.hours[i];
          } else {
            acc.push({
              module: cur.module,
              stage: stage,
              hours: cur.hours[i],
              module_type: cur.module_type,
            });
          }
        });
        return acc;
      }, []);
      setUniqueStage([...new Set(outputArray.map((item) => item.stage))]);
      if (countryLogistic) {
        const outputLogistic = outputArray.filter(
          (out) =>
            out.module_type[0] === "Logistics" ||
            out.module === "Drobne_projekty"
        );
        const unique = [
          ...new Set(
            outputArray.map((item) => {
              if (
                item.module_type[0] === "Logistics" ||
                item.module === "Drobne_projekty"
              ) {
                return item.module;
              }
            })
          ),
        ];
        const checkUnique = unique.filter((name) => name !== undefined);
        const modules = checkUnique.filter(
          (item) => item !== "Drobne_projekty"
        );
        modules.push("Drobne_projekty");
        setUniqueModule(modules);
        setLogsToDisplay(outputLogistic);
      } else {
        const uniqueModules = [
          ...new Set(outputArray.map((item) => item.module)),
        ];
        const modules = uniqueModules.filter(
          (item) => item !== "Hour management" && item !== "Drobne_projekty"
        );
        modules.push("Hour management", "Drobne_projekty");
        setUniqueModule(modules);
        setLogsToDisplay(outputArray);
      }
    }
  };

  const sumValues = uniqueModule.map((module) => {
    const hoursArray = logsToDisplay
      .filter((item) => item.module === module)
      .map((item) => item.hours);
    const sum = hoursArray.reduce((acc, curr) => acc + curr, 0);

    return sum;
  });

  const handlePreview = () => {
    let url = "https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts/";

    if (countryLog === null && moduleTypeLog === null) {
      disabledCalendar
        ? (url += `date/${dates}/${client}`)
        : (url += `date/${dates}/${dates2}/${client}`);
    } else if (
      !countryLogistic &&
      countryLog !== null &&
      moduleTypeLog === null
    ) {
      disabledCalendar
        ? (url += `country/date/${dates}/${countryLog.name}`)
        : (url += `country/date/${dates}/${dates2}/${countryLog.name}`);
    } else if (
      countryLogistic &&
      countryLog !== null &&
      moduleTypeLog !== null
    ) {
      disabledCalendar
        ? (url += `country/date/moduleType/${dates}/${countryLog.name}/${moduleTypeLog.name}`)
        : (url += `country/date/moduleType/${dates}/${dates2}/${countryLog.name}/${moduleTypeLog.name}`);
    }
    fetch(url, {
      method: "GET",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((posts) => {
        createPreview(posts);
        setLoading(false);
        posts !== null
          ? setShowPreview(true)
          : alert(
              "ATTENTION! Data does not exist for the selected Customer or Country. Select a different range."
            );
      });
  };

  const onDropDownChange = (set, e) => {
    set(e.value);
  };

  const properties = {
    client: client,
    uniqueModule: uniqueModule,
    uniqueStage: uniqueStage,
    logsToDisplay: logsToDisplay,
    minorProjects: minorProjects,
    dates: dates,
    dates2: dates2,
    sumValues: sumValues,
    eldData: props.eldData,
    monthNames: monthNames,
    countryLog: countryLog,
    tableRef: tableRef,
    costRate: costRate,
    setClient: setClientChoice,
    setClientChoice: setClientChoice,
    onDownload: onDownload,
    resetStateToInitialValues: resetStateToInitialValues,
    setFixRef: setFixRef,
    setDates: setDates,
  };

  return (
    <>
      <Dialog
        header={dictionary.export_header}
        visible={true}
        onHide={() => {
          resetStateToInitialValues();
          navigate("/timesheet");
        }}
        style={{ width: "50vw" }}
      >
        {loading && (
          <div className="loading-wrapper">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="2"
              animationDuration="3s"
            />
          </div>
        )}
        <h3>{dictionary.export_dialog_subtitle1}</h3>
        <div className="field export-wrapper">
          {!clientChoice ? (
            <>
              <div className="button-wrapper">
                {buttonData.map((button, index) => (
                  <ReportButtonComponent key={index} {...button} />
                ))}
              </div>
              {admin ? (
                <div className="productivity-wrapper">
                  <Divider />
                  <h3>{dictionary.export_dialog_subtitle2}</h3>
                  <div className="button-wrapper">
                    {adminButtonData.map((button, index) => (
                      <ReportButtonComponent key={index} {...button} />
                    ))}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div className="export-details-wrapper">
              {!showPreview ? (
                <>
                  {client === "COUNTRY" && (
                    <div className="field-dropdown-wrapper">
                      <DropdownComponent
                        name="countries"
                        style={{ width: "100%" }}
                        value={countryLog}
                        options={props.eldData[0].countries}
                        onChange={(e) => {
                          onDropDownChange(setCountryLog, e);
                        }}
                        optionLabel="name"
                        placeholder={dictionary.dialog_placeholder_country}
                        className={
                          countryLog === null && formSubmitted
                            ? "p-invalid"
                            : null
                        }
                      />
                      {countryLog === null && formSubmitted ? (
                        <small className="p-error">
                          {dictionary.dialog_error_country}
                        </small>
                      ) : null}
                      <CalendarComponent
                        style={{ width: "100%" }}
                        placeholder={
                          dictionary.export_dialog_calendar_placeholder
                        }
                        view="month"
                        dateFormat="mm/yy"
                        value={dates}
                        value2={dates2}
                        onChange={(e) => {
                          setDates(e.value);
                        }}
                        onChange2={(e) => {
                          setDates2(e.value);
                        }}
                        showIcon={true}
                        disabled={disabledCalendar}
                      />
                      <div className="checkbox-wrapper">
                        <CheckboxComponent
                          onChange={(e) => {
                            setCheckedCalendar(e.checked);
                            setDates(dates);
                            disabledCalendar
                              ? setDisabledCalendar(false)
                              : setDisabledCalendar(true);
                          }}
                          checked={checkedCalendar}
                        />
                        <small className="checkbox-info">
                          {dictionary.export_dialog_checkbox_month}
                        </small>
                      </div>
                      <div className="export-btn-wrapper">
                        <ReportButtonComponent
                          label={dictionary.export_dialog_preview_btn}
                          icon="pi pi-eye"
                          onClick={() => {
                            setFormSubbmited(true);
                            if (countryLog !== null) {
                              setLoading(true);
                              handlePreview();
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {(client === "ELD" ||
                    client === "INT" ||
                    client === "LOGISTICS") && (
                    <>
                      <CalendarComponent
                        style={{ width: "100%" }}
                        placeholder={
                          dictionary.export_dialog_calendar_placeholder
                        }
                        view="month"
                        dateFormat="mm/yy"
                        value={dates}
                        value2={dates2}
                        onChange={(e) => {
                          setDates(e.value);
                        }}
                        onChange2={(e) => {
                          setDates2(e.value);
                        }}
                        showIcon={true}
                        disabled={disabledCalendar}
                      />
                      <div className="checkbox-wrapper">
                        <CheckboxComponent
                          onChange={(e) => {
                            setCheckedCalendar(e.checked);
                            setDates(dates);
                            disabledCalendar
                              ? setDisabledCalendar(false)
                              : setDisabledCalendar(true);
                          }}
                          checked={checkedCalendar}
                        />
                        <small className="checkbox-info">
                          {dictionary.export_dialog_checkbox_month}
                        </small>
                      </div>
                      <div className="export-btn-wrapper">
                        <ReportButtonComponent
                          label={dictionary.export_dialog_preview_btn}
                          icon="pi pi-eye"
                          onClick={() => {
                            setFormSubbmited(true);
                            setLoading(true);
                            handlePreview();
                          }}
                        />
                      </div>
                    </>
                  )}
                  {client === "Productivity" && (
                    <Productivity properties={properties} />
                  )}
                  {client === "Productivity teams" && (
                    <TeamsMenu properties={properties} />
                  )}
                </>
              ) : (
                <div className="raport-wrapper">
                  <Menubar start={start} end={end} />
                  {countryLog !== null ? (
                    <h2>
                      {countryLog.code +
                        " " +
                        client +
                        " " +
                        dictionary.export_dialog_raport_title}
                    </h2>
                  ) : (
                    <h2>
                      {client + " " + dictionary.export_dialog_raport_title}
                    </h2>
                  )}
                  <DefaultReportTable properties={properties} />
                </div>
              )}
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default ExportDialog;
