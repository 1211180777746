export const dictionary = {
  main_page_title: "Meet ELD Timer.",
  main_page_subtitle: "One tool for reporting work hours and create reports!",
  main_page_list_item_title1: "Sign in. ",
  main_page_list_item1: "Select yourself from the list of users.",
  main_page_list_item_title2: "Create log. ",
  main_page_list_item2: "Select the appropriate parameters, click save and the entry will appear in the table.",
  main_page_list_item_title3: "Edit log. ",
  main_page_list_item3: "If you make a mistake, you can easily edit the log!",
  main_page_list_item_title4: "Create a report. ",
  main_page_list_item4: "With just one click and selecting the time period and country, you can easily create a LAZ report.",
  sign_in: "Sign in",
  your_timesheet: "Your timesheet",
  copyright: "Copyright © E-Learning Department 2024",
  login_access: "Access",
  login_access_user: 'User name:',
  login_access_password: 'Password:',
  login_access_password_enter: 'Enter password',
  login_access_btn: 'Get access',
  login_header: "User selection",
  login_title: "Login as:",
  login_btn: "Log in",
  toast_summary_success: "Success!",
  toast_summary_warn: "Warning",
  toast_login_detail: "Successfully logged as ",
  toast_create_log_detail: "You are trying to add an entry on an unscheduled day. Plan your day with Work Planner first.",
  toast_access: "Access granted.",
  content_hello: "Hello ",
  content_tools: "Tools:",
  admin_panel_btn: "Admin Panel",
  create_log_hour_report: "Hour Report:",
  eld_btn_label: "ELD",
  int_btn_label: "INT",
  countries_btn_label: "Countries",
  log_btn_label: "Logistics",
  other_btn_label: "Other",
  dialog_overlay_info: "Would you like to add an entry for this date?",
  dialog_overlay_btn_label: "Yes",
  dialog_label_date: "Date:",
  dialog_label_module: "Module:",
  dialog_placeholder_module: "Select module",
  dialog_error_module: "Module is required.",
  dialog_label_stage: "Stage:",
  dialog_placeholder_stage: "Select stage",
  dialog_error_stage: "Stage is required.",
  dialog_label_task: "Task:",
  dialog_placeholder_task: "Select task",
  dialog_placeholder_task_write: "Write a task",
  dialog_error_task: "Task is required.",
  dialog_label_esp: "ESP (optional):",
  dialog_placeholder_esp: "Select ESP (external companies)",
  dialog_label_country: "Country:",
  dialog_placeholder_country: "Select country",
  dialog_error_country: "Country is required.",
  dialog_label_issue: "Issue:",
  dialog_placeholder_issue: "Select issue",
  dialog_error_issue: "Issue is required.",
  dialog_label_module_types: "Module types:",
  dialog_placeholder_module_types: "Select Module Type",
  dialog_error_module_types: "Module type is required.",
  dialog_error_client: "Client is required.",
  dialog_label_hours: "Hours:",
  dialog_placeholder_hours: "Select time",
  dialog_error_hours: "Time is required.",
  dialog_label_comment: "Comment:",
  dialog_placeholder_comment: "Add comment",
  dialog_error_comment: "Enter the name of the task in the comment when adding Minor Projects.",
  dialog_save_btn: "Save",
  dialog_update_btn: "Update",
  dialog_button_label1: "Ftf_2.0",
  dialog_button_label2: "MA_3.0",
  dialog_button_label3: "Nuggets",
  dialog_button_label4: "Other",
  dialog_button_label5: "Localization management",
  dialog_button_label6: "Clear the ESP field",
  dialog_button_label7: "FtF",
  dialog_button_label8: "Logistic",
  dialog_button_label9: "MA",
  dialog_button_label10: "MA_2.0",
  dialog_button_label11: "Nugget AUDI",
  dialog_button_label12: "Nugget INT",
  dialog_button_label13: "Hour management",
  dialog_button_label14: "Localization",
  dialog_button_label15: "Development",
  eld_dialog_header: "ELD time report",
  int_dialog_header: "INT time report",
  country_dialog_header: "Country time report",
  log_dialog_header: "Logistics time report",
  other_dialog_header: "Other time report",
  work_planner_btn: "Work Planner",
  work_planner_header: "Work planner",
  work_planner_calendar_label: "Select working days by clicking on the calendar:",
  work_planner_form_hours: "Working hours:",
  work_planner_form_hours_start: "Start hours",
  work_planner_form_hours_end: "End hours",
  work_planner_form_work: "Work from:",
  work_planner_form_placeholder_work: "Select work place",
  work_planner_form_proj: "Activity:",
  work_planner_form_placeholder_proj: "Select activity",
  work_planner_form_other: "Other:",
  work_planner_form_placeholder_other: "Select absence",
  work_planner_form_submmit: "OK",
  work_planner_preview_title: "Schedule preview:",
  work_planner_preview_info: "(Selected days will appear below).",
  work_planner_preview_table_th1: "Day",
  work_planner_preview_table_th2: "Working hours",
  work_planner_preview_table_th3: "Work place",
  work_planner_preview_table_th4: "Activity",
  export_btn: "Export data",
  export_dialog_exp_btn: "Export to excel",
  export_filename_sum: "_summary",
  export_header: "Export data",
  export_dialog_subtitle1: "Create an hourly report for:",
  export_dialog_subtitle2: "Productivity reports:",
  export_btn_label1: "ELD",
  export_btn_label2: "INT Sales",
  export_btn_label3: "INT Logistics",
  export_btn_label4: "Country Sales",
  export_btn_label5: "Country Logistic",
  export_btn_label6: "Productivity",
  export_btn_label7: "Productivity teams",
  export_dialog_checkbox_modules: "Select all modules",
  export_dialog_checkbox_month: "Create a report for the selected month",
  export_dialog_preview_btn: "Preview",
  export_dialog_calendar_placeholder: "Select date range",
  export_dialog_raport_title: " Report",
  export_dialog_from: "From: ",
  export_dialog_to: "to: ",
  export_table_th_hours: "Hours",
  export_table_th_costs: "Costs ",
  export_table_th_loc: "Localization",
  export_table_th_dev: "Development",
  export_table_summary: "SUM",
  export_table_task_missing : "Missing task name!",
  export_table_minor_proj_int: "INT_Minor_projects",
  export_table_minor_proj_country: "Minor_projects",
  export_calendar_from: "From:",
  export_calendar_to: "To:",
  productivity_table_th1: "Task",
  productivity_table_th2: "Internal order number",
  productivity_table_th3: "SUM",
  productivity_table_th4: "INT",
  productivity_table_th5: "INT Betrieb",
  productivity_table_summary_overview: "Summary Overview",
  productivity_table_summary_sales_hours: "Summary Hours sales",
  productivity_table_summary_log_hours: "Summary Hours logistics",
  productivity_table_summary_esp_sales: "External Service Providers sales",
  productivity_table_summary_esp_log: "External Service Providers logistics",
  productivity_table_summary_int_empl_hours: "INT employees hours",
  productivity_table_summary_sales_sum: "Sales sum",
  productivity_table_summary_log_sum: "Logistics sum",
  productivity_table_summary_country_sum: "Country sum",
  productivity_table_summary_proportion: "Proporcion",
  productivity_table_summary_cost_sales: "Costs sales (+10%)",
  productivity_table_summary_cost_log: "Costs logistics (+10%)",
  productivity_table_summary_bill: "Bill for country (total)",
  productivity_table_resources: "Resources",
  productivity_table_management: "Management: 10%",
  productivity_table_management_trainings: "Management: 10% + Trainings",
  productivity_teams_btn_qct: "QC team Sum",
  productivity_teams_btn_indet: "Content team Sum",
  productivity_teams_btn_devt: "Development team Sum",
  productivity_teams_btn_loct: "Localization team Sum",
  productivity_teams_jobs_info: "You can edit the number of current employee positions in the RCP Data (Full-time jobs) table.",
  productivity_teams_loc_support: "Localization support",
  productivity_teams_table_row_header: "Month",
  productivity_teams_table_row_label1: "Localization management",
  productivity_teams_table_row_label2: "INT",
  productivity_teams_table_row_label_sharepoint: "Sharepoint INT",
  productivity_teams_table_row_label3: "Logistics",
  productivity_teams_table_row_label4: "ELD - internal tasks",
  productivity_teams_table_row_label5: "Work organization",
  productivity_teams_table_row_label6: "Meetings",
  productivity_teams_table_row_label7: "Trainings",
  productivity_teams_table_row_label8: "Vacation",
  productivity_teams_table_row_label9: "Absence",
  productivity_teams_table_row_label10: "Early leave",
  productivity_teams_table_row_label11: "SUM",
  productivity_teams_table_rcp_label1: "RCP Data",
  productivity_teams_table_rcp_label2: "Working days",
  productivity_teams_table_rcp_label3: "Full-time jobs",
  productivity_teams_table_rcp_label4: "Working hours",
  productivity_teams_table_rcp_label5: "Hours",
  productivity_teams_table_rcp_label6: "Difference",
  admin_panel_nav_label1: "Employees work plans",
  admin_panel_nav_label2: "User management",
  admin_panel_nav_label3: "Module management",
  admin_panel_nav_label4: "Close admin panel",
  admin_panel_mod_mgmt_info:
    "*Remember that depending on the client's choice, some modules need to be added twice. For example, the HIL module for Logistics (client) must be added for Logistics and per Country for Logistics.",
  admin_panel_toast_detail: "New module added: ",
  admin_panel_module_mgmt_header: "Add New Module",
  admin_panel_label_client: "Select Client:",
  admin_panel_placeholder_client: "Select a client",
  admin_panel_label_mod_type: "Select Module Type:",
  admin_panel_placeholder_mod_type: "Select a module type",
  admin_panel_label_module: "Module Name:",
  admin_panel_label_minor_proj: "Minor project:",
  admin_panel_label_minor_info_label: "By clicking this button you will add a module to the Drobne_projekty list. This option is not obligatory if you only want to add a module to Others.",
  admin_panel_placeholder_module: "Write module name",
  admin_panel_info_module: "Remember to use English for module names!",
  admin_panel_add_minor_proj_btn: "Click here to add Drobne_projekty",
  admin_panel_add_minor_proj_back_btn: "Back",
  admin_panel_add_module_btn: "Add Module",
  admin_panel_export_info: "The list of all modules in the database is available by clicking the export button.",
  admin_panel_user_mgmt_toast_detail: "New user added: ",
  admin_panel_user_mgmt_toast_delete_detail: "User removed: ",
  admin_panel_user_mgmt_toast_update_detail: "User details updated: ",
  admin_panel_user_mgmt_role1: "Administrator role",
  admin_panel_user_mgmt_role2: "User role",
  admin_panel_user_mgmt_header: "User Management",
  admin_panel_user_mgmt_dialog_header: " details",
  admin_panel_user_mgmt_dialog_hours: "User Working Hours:",
  admin_panel_user_mgmt_dialog_hours_info: "Select whether the employee is full-time (normal) or 7-hour (special).",
  admin_panel_user_mgmt_dialog_team: "Team:",
  admin_panel_user_mgmt_dialog_team_placeholder: "Select team",
  admin_panel_user_mgmt_dialog_admin: "Admin role:",
  admin_panel_user_mgmt_dialog_admin_placeholder: "Select team",
  admin_panel_user_mgmt_dialog_delete_header: "Delete user: ",
  admin_panel_user_mgmt_dialog_delete_info1: "Attention! You are trying to delete a user. The process is irreversible. Enter username ",
  admin_panel_user_mgmt_dialog_delete_info2: " to remove it.",
  admin_panel_user_mgmt_dialog_delete_placeholder: "Write user name",
  admin_panel_user_mgmt_dialog_delete_btn: "Remove",
  admin_panel_user_mgmt_list_title: "List of ELD users:",
  admin_panel_user_mgmt_form_title: "Add New User",
  admin_panel_user_mgmt_form_name: "User Name:",
  admin_panel_user_mgmt_form_name_info: "For last name, enter only initials! e.g Joe D.",
  admin_panel_user_mgmt_form_name_placeholder: "Write user name",
  admin_panel_user_mgmt_form_hours: "User Working Hours:",
  admin_panel_user_mgmt_form_hours_info: "Select whether the employee is full-time (normal) or 7-hour (special).",
  admin_panel_user_mgmt_form_hours_placeholder: "Select working hours",
  admin_panel_user_mgmt_form_submmit: "Add User",
  admin_panel_plan_header: "Employees work plans",
  admin_panel_plan_btn_title_all: "All employees",
  admin_panel_plan_btn_subtitle: "Work plan",
  admin_panel_plan_btn_title_cont: "Content Team",
  admin_panel_plan_btn_title_dev: "Development Team",
  admin_panel_plan_btn_title_loc: "Localization Team",
  admin_panel_plan_btn_title_qc: "Quality Control Team",
  admin_panel_plan_btn_title_free: "Junior Experts and Expert",
  admin_panel_plan_btn_compressed_view: "Team Compressed view",
  admin_panel_plan_btn_compressed_view_hide: "Hide Team Compressed view",
  admin_work_plan_compressed_day: "Day",
  admin_work_plan_compressed_wh: "Work hours",
  admin_work_plan_compressed_wpl: "Work place",
  admin_work_plan_compressed_task: "Task",
  admin_panel_plan_detail_table_th1: "Day",
  admin_panel_plan_detail_table_th2: "Work hours",
  admin_panel_plan_detail_table_th3: "Reported hours",
  admin_panel_plan_detail_table_th4: "Work place",
  admin_panel_plan_detail_table_th5: "Client",
  admin_panel_plan_detail_table_th6: "Country",
  admin_panel_plan_detail_table_th7: "Task",
  admin_panel_plan_detail_table_th8: "Stage",
  admin_panel_plan_detail_table_th9: "Module hours",
  admin_panel_plan_detail_table_th10: "Comment",
  admin_chart_label1: "Date: ",
  admin_chart_label2: "Available desks: ",
  admin_chart_label3: "All desks: ",
  user_logs_table_tabkeys_header: "Tab keys:",
  user_logs_table_btn_label_expand: "Expand All",
  user_logs_table_btn_label_collapse: "Collapse All",
  user_logs_table_dialog_client: "Client is missing, please select it from the list.",
  user_logs_table_dialog_client_btn_label1: "ELD",
  user_logs_table_dialog_client_btn_label2: "INT",
  user_logs_table_dialog_client_btn_label3: "Countries",
  user_logs_table_dialog_client_btn_label4: "Logistics",
  user_logs_table_dialog_client_btn_label5: "Other",
  user_logs_table_absence: "ABSENCE",
  user_logs_table_month_counter1: "There are ",
  user_logs_table_month_counter2: " days left until the end of the month.",
  user_logs_table_no_posts: "No user entries, plan your week in Work Planner.",
  mobile_overlay_info: "The application is not available on mobile devices.",
  logout_btn_label: "Log out",
  widget_title: "Releases",
  widget_bug_1: "Have you noticed a ",
  widget_bug_2: "bug",
  widget_bug_3: "? Report it directly on ",
  widget_bug_4: "JIRA",
  widget_bug_5: " sub-task by clicking:",
  widget_bug_report: "Report bug",
  widget_bug_report_info: "Remember to describe the error as best as possible. Include screenshots if you think they are needed.",
  widget_fields: "Fill in the fields: ",
  widget_field_1: "Summary",
  widget_field_2: "Description",
  widget_field_3: "Attachment",
  widget_field_4: "Issue",
  widget_field_5: "Assignee",
  widget_assign: " Assign the task to the person responsible for the project.",
  logout_info: "User logged out.",
  slider_info1: "Pamiętaj o edycji zaplanowanego wpisu w Work Planerze. Nie zostawiaj pustych wpisów w tabeli!",
  slider_info2: "Nie możesz dodać wpisu w danym dniu? Dodaj go w Work Planerze.",
  slider_info3: "Chcesz dodać nowy moduł, którego nie ma liście? Zgłoś się do swojego przełożonego.",
  slider_info4: "Pamiętaj o kopiowaniu podobnych wpisów. Zaoszczędzi Ci to czas raportowania godzin!",
  slider_info5: "Work planner zapamiętuje ostatnio dodany wpis z listy planowania. Od teraz planowanie pracy jest jeszcze szybsze!",
  slide_info6: "Aplikacja nie działa poprawnie po aktualizacji? Wciśnij CTRL + SHIFT + R aby ją odświeżyć.",
  currency: "€",
  app_version: "v:0.2.5.9",
};
