import { SET_HOUR, CLEAR_HOUR } from "../actions/logActions";

const initHour = {
  value: 0,
};

const hourLogReducer = (state = initHour, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_HOUR:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_HOUR:
      return { value: 0 };
    default:
      return state;
  }
};

export default hourLogReducer;
