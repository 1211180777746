import { SET_TASK, CLEAR_TASK } from "../actions/logActions";

const initTask = {
  value: null,
};

const taskLogReducer = (state = initTask, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TASK:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_TASK:
      return { value: null };
    default:
      return state;
  }
};

export default taskLogReducer;
