import React from "react";
import { dictionary } from "../../../../dictionary";

const ESPLogistic = (props) => {
  const properties = props.properties;
  const styles = props.styles;

  return (
    <>
      <tr>
        <th className="nugget" style={styles.nugget}>
          {dictionary.productivity_table_summary_esp_log}
        </th>
        <td className="nugget" style={styles.nugget}></td>
        <td className="nugget" style={styles.nugget}>
          {properties.espLogisticsSum.toString().replace(".", ",")}
        </td>
        <td className="nugget" style={styles.nugget}></td>
        <td className="nugget" style={styles.nugget}></td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="nugget" style={styles.nugget}>
              {properties.espLogisticsCountrySum(country).toString().replace(".", ",")}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default ESPLogistic;
