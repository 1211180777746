import React from "react";
import StopWatchIcon from "../../assets/icons/stopwatch.png";
import AppOverView from "../../assets/img/app_overview.png";
import TopBar from "../common/Topbar.js";
import FooterMenu from "../common/FooterMenu";
import { dictionary } from "../../dictionary";
import MobileOverlay from "./MobileOverlay";

const MainPage = () => {
  return (
    <div className="main-page">
      <MobileOverlay />
      <TopBar />
      <div className="col">
        <h1>{dictionary.main_page_title}</h1>
        <h2>{dictionary.main_page_subtitle}</h2>
        <ul>
          <li>
            <img src={StopWatchIcon} alt="stopwatch" />
            <h2>
              <span>{dictionary.main_page_list_item_title1}</span>
              {dictionary.main_page_list_item1}
            </h2>
          </li>
          <li>
            <img src={StopWatchIcon} alt="stopwatch" />
            <h2>
              <span>{dictionary.main_page_list_item_title2}</span>
              {dictionary.main_page_list_item2}
            </h2>
          </li>
          <li>
            <img src={StopWatchIcon} alt="stopwatch" />
            <h2>
              <span>{dictionary.main_page_list_item_title3}</span>
              {dictionary.main_page_list_item3}
            </h2>
          </li>
          <li>
            <img src={StopWatchIcon} alt="stopwatch" />
            <h2>
              <span>{dictionary.main_page_list_item_title4}</span>
              {dictionary.main_page_list_item4}
            </h2>
          </li>
        </ul>
      </div>
      <div className="col">
        <div className="img-wrapper">
          <img src={AppOverView} alt="app-overview" />
        </div>
      </div>
      <FooterMenu />
    </div>
  );
};

export default MainPage;
