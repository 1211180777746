import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { useSelector, useDispatch } from "react-redux";
import { hideCtyDialog } from "../../redux/actions/dialogActions.js";
import {
  clearDate,
  clearEsp,
  setComment,
  setDate,
  setHour,
  setModule,
  setModType,
  setEsp,
  setCty,
  setStage,
  setTask,
  clearTask,
  clearModule,
  clearStage,
} from "../../redux/actions/logActions.js";
import { dictionary } from "../../dictionary.js";
import DialogOverlay from "./DialogOverlay.js";

const CountryComponent = (props) => {
  const dispatch = useDispatch();
  const dialogProps = props.dialogProps;
  const [dateLog, setDateLog] = useState(
    new Date(useSelector((state) => state.date.value))
  );
  const espLog = useSelector((state) => state.esp.value);
  const ctyLog = useSelector((state) => state.cty.value);
  const moduleLog = useSelector((state) => state.module.value);
  const modTypeLog = useSelector((state) => state.modType.value);
  const stageLog = useSelector((state) => state.stage.value);
  const taskLog = useSelector((state) => state.task.value);
  const hourLog = useSelector((state) => state.hours.value);
  const commentLog = useSelector((state) => state.comment.value);
  const [index, setIndex] = useState(0);
  const [locChecked, setLockChecked] = useState(false);
  const [devChecked, setDevChecked] = useState(false);
  const toast = useRef(null);

  const showMsg = () => {
    toast.current.show({
      severity: "warn",
      summary: dictionary.toast_summary_warn,
      detail: dictionary.toast_create_log_detail,
      life: 7000,
    });
  };

  const reset = () => {
    setLockChecked(false);
    setDevChecked(false);
    dialogProps.setShowOverlay(true);
  };

  const resetCountryButtons = () => {
    dispatch(clearModule());
    dispatch(clearStage());
  };

  const handleModuleTypeLogChange = (type) => {
    if (dialogProps.eldData[0].length !== 0) {
      switch (type) {
        case "FtF":
          return dialogProps.eldData[0].countries[index].ftf.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        case "FtF_2.0":
          return dialogProps.eldData[0].countries[index].ftf_2_0.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        case "Logistics":
          return dialogProps.eldData[0].countries[index].logistics.sort(
            (a, b) => a.name.localeCompare(b.name)
          );
        case "MA":
          return dialogProps.eldData[0].countries[index].ma.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        case "MA_2.0":
          return dialogProps.eldData[0].countries[index].ma.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        case "MA_3.0":
          return dialogProps.eldData[0].countries[index].ma.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        case "Nugget AUDI":
          return dialogProps.eldData[0].countries[index].nugget_audi.sort(
            (a, b) => a.name.localeCompare(b.name)
          );
        case "Nugget INT":
          return dialogProps.eldData[0].countries[index].nugget_int.sort(
            (a, b) => a.name.localeCompare(b.name)
          );
        case "Other":
          return dialogProps.eldData[0].countries[index].other.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        default:
          return dialogProps.eldData[0].countries[0].ftf.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
      }
    }
  };

  return (
    <>
      <Dialog
        header={dictionary.country_dialog_header}
        visible={dialogProps.isDialogOpen}
        style={{ width: "50vw" }}
        onHide={() => {
          dialogProps.setValuesOnInitialState();
          reset();
          dispatch(hideCtyDialog());
        }}
      >
        <div className="field field-wrapper">
          <Toast ref={toast} />
          <label>{dictionary.dialog_label_date}</label>
          {dialogProps.showOverlay && (
            <DialogOverlay dialogProps={dialogProps} />
          )}
          <Calendar
            disabledDays={dialogProps.disabledDays}
            style={{ width: "100%" }}
            placeholder={dateLog}
            value={dateLog}
            onChange={(e) => {
              if (dialogProps.checkIfDayIsPlanned(e.value)) {
                dispatch(setDate(e.value));
                setDateLog(e.value);
                dialogProps.setShowOverlay(false);
              } else {
                showMsg();
                dispatch(clearDate());
                setDateLog(new Date());
              }
            }}
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_esp}</label>
          <Dropdown
            style={{ width: "100%" }}
            value={espLog}
            options={
              dialogProps.eldData[0].length !== 0
                ? dialogProps.eldData[0].esp.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )
                : null
            }
            onChange={(e) => {
              dispatch(setEsp(e.value.name));
            }}
            optionLabel="name"
            placeholder={
              espLog !== null ? espLog : dictionary.dialog_placeholder_esp
            }
          />
          <Button
            id="esp-clear-button"
            label={dictionary.dialog_button_label6}
            onClick={() => {
              dispatch(clearEsp());
            }}
          />
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_country}</label>
          <Dropdown
            style={{ width: "100%" }}
            value={ctyLog}
            options={dialogProps.eldData[0].countries}
            filter
            onChange={(e) => {
              dispatch(setCty(e.value.name));
              setIndex(dialogProps.eldData[0].countries.indexOf(e.value));
            }}
            optionLabel="code"
            placeholder={
              ctyLog !== null ? ctyLog : dictionary.dialog_placeholder_country
            }
            className={
              ctyLog === null && dialogProps.formSubmitted ? "p-invalid" : null
            }
          />
          {ctyLog === null && dialogProps.formSubmitted ? (
            <small className="p-error">{dictionary.dialog_error_country}</small>
          ) : null}
        </div>
        <div className="field field-wrapper">
          <div
            className={
              modTypeLog === null && dialogProps.formSubmitted
                ? "row row-invalid"
                : "row"
            }
          >
            <div className="col">
              <Button
                className="buttons"
                label={dictionary.dialog_button_label7}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModType("FtF"));
                  dispatch(clearTask());
                }}
              />
              <Button
                className="buttons"
                label={dictionary.dialog_button_label1}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModType("FtF_2.0"));
                  dispatch(clearTask());
                }}
              />
              <Button
                className="buttons"
                label={dictionary.dialog_button_label8}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModType("Logistics"));
                  dispatch(clearTask());
                }}
              />
              <Button
                className="buttons"
                label={dictionary.dialog_button_label9}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModType("MA"));
                  dispatch(clearTask());
                }}
              />
              <Button
                className="buttons"
                label={dictionary.dialog_button_label10}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModType("MA_2.0"));
                  dispatch(clearTask());
                }}
              />
            </div>
            <div className="col">
              <Button
                className="buttons"
                label={dictionary.dialog_button_label2}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModType("MA_3.0"));
                  dispatch(clearTask());
                }}
              />
              <Button
                className="buttons"
                label={dictionary.dialog_button_label11}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModType("Nugget AUDI"));
                  dispatch(clearTask());
                }}
              />
              <Button
                className="buttons"
                label={dictionary.dialog_button_label12}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModType("Nugget INT"));
                  dispatch(clearTask());
                }}
              />
              <Button
                className="buttons"
                label={dictionary.dialog_button_label4}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModType("Other"));
                  dispatch(clearTask());
                }}
              />
              <Button
                className="buttons"
                label={dictionary.dialog_button_label13}
                onClick={() => {
                  resetCountryButtons();
                  dispatch(setModule("Hour management"));
                  dispatch(setModType("Hour management"));
                  dispatch(clearTask());
                }}
              />
            </div>
          </div>
          {modTypeLog === null && dialogProps.formSubmitted ? (
            <small className="p-error">
              {dictionary.dialog_error_module_types}
            </small>
          ) : null}
        </div>
        <div className="field field-wrapper">
          {moduleLog === null || moduleLog !== "Hour management" ? (
            <>
              <label>{modTypeLog}:</label>
              <div className="field-dropdown-wrapper">
                <Dropdown
                  style={{ width: "100%" }}
                  value={moduleLog}
                  filter
                  options={
                    dialogProps.eldData[0].length !== 0
                      ? handleModuleTypeLogChange(modTypeLog)
                      : null
                  }
                  onChange={(e) => {
                    dispatch(setModule(e.value.name));
                  }}
                  optionLabel="name"
                  placeholder={
                    moduleLog !== null
                      ? moduleLog
                      : dictionary.dialog_placeholder_module
                  }
                  className={
                    moduleLog === null && dialogProps.formSubmitted
                      ? "p-invalid"
                      : null
                  }
                />
                {moduleLog === null && dialogProps.formSubmitted ? (
                  <small className="p-error">
                    {dictionary.dialog_error_module}
                  </small>
                ) : null}
                {moduleLog === "Drobne_projekty" ? (
                  <>
                    <Dropdown
                      style={{ width: "100%", marginTop: "0.5rem" }}
                      value={taskLog}
                      options={dialogProps.eldData[0].projects_countries.sort(
                        (a, b) => a.name.localeCompare(b.name)
                      )} //Drobne_projekty
                      filter
                      onChange={(e) => {
                        dispatch(setTask(e.value.name));
                      }}
                      optionLabel="name"
                      placeholder={
                        taskLog !== null
                          ? taskLog
                          : dictionary.dialog_placeholder_task
                      }
                      className={
                        taskLog === null && dialogProps.formSubmitted
                          ? "p-invalid"
                          : null
                      }
                    />
                    {taskLog === null && dialogProps.formSubmitted ? (
                      <small className="p-error">
                        {dictionary.dialog_error_task}
                      </small>
                    ) : null}
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <label>{modTypeLog}</label>
          )}
        </div>
        <div className="field field-stage-wrapper">
          <div className="row">
            <div className="col">
              <Button
                label={dictionary.dialog_button_label14}
                onClick={() => {
                  setLockChecked(true);
                  setDevChecked(false);
                  dispatch(clearStage());
                }}
                disabled={
                  moduleLog === "Drobne_projekty" ||
                  moduleLog === "Hour management"
                    ? true
                    : locChecked
                }
              />
            </div>
            <div className="col">
              <Button
                label={dictionary.dialog_button_label15}
                onClick={() => {
                  setDevChecked(true);
                  setLockChecked(false);
                  dispatch(clearStage());
                }}
                disabled={
                  moduleLog === "Drobne_projekty" ||
                  moduleLog === "Hour management"
                    ? true
                    : devChecked
                }
              />
            </div>
          </div>
          {moduleLog !== "Hour management" ? (
            <div className="stage-dropdown-wrapper">
              {locChecked ? (
                <>
                  <label>{dictionary.dialog_label_stage}</label>
                  <Dropdown
                    style={{ width: "100%" }}
                    value={stageLog}
                    options={dialogProps.eldData[0].stages_localization}
                    filter
                    onChange={(e) => {
                      dispatch(setStage(e.value.name));
                    }}
                    optionLabel="name"
                    placeholder={
                      stageLog !== null
                        ? stageLog
                        : dictionary.dialog_placeholder_stage
                    }
                    className={
                      stageLog === null && dialogProps.formSubmitted
                        ? "p-invalid"
                        : null
                    }
                  />
                  {stageLog === null && dialogProps.formSubmitted ? (
                    <small className="p-error">
                      {dictionary.dialog_error_stage}
                    </small>
                  ) : null}
                </>
              ) : null}
              {devChecked ? (
                <>
                  <label>{dictionary.dialog_label_stage}</label>
                  <Dropdown
                    style={{ width: "100%" }}
                    value={stageLog}
                    options={dialogProps.eldData[0].stages_development}
                    filter
                    onChange={(e) => {
                      dispatch(setStage(e.value.name));
                    }}
                    optionLabel="name"
                    placeholder={
                      stageLog !== null
                        ? stageLog
                        : dictionary.dialog_placeholder_stage
                    }
                    className={
                      stageLog === null && dialogProps.formSubmitted
                        ? "p-invalid"
                        : null
                    }
                  />
                  {stageLog === null && dialogProps.formSubmitted ? (
                    <small className="p-error">
                      {dictionary.dialog_error_stage}
                    </small>
                  ) : null}
                </>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_hours}</label>
          <Dropdown
            style={{ width: "100%" }}
            value={hourLog}
            options={dialogProps.eldData[0].hours}
            filter
            onChange={(e) => {
              dispatch(setHour(e.value.hour));
            }}
            optionLabel="hour"
            placeholder={
              hourLog !== 0 ? hourLog : dictionary.dialog_placeholder_hours
            }
            className={
              hourLog === 0 && dialogProps.formSubmitted ? "p-invalid" : null
            }
          />
          {hourLog === 0 && dialogProps.formSubmitted ? (
            <small className="p-error">{dictionary.dialog_error_hours}</small>
          ) : null}
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_comment}</label>
          <InputText
            style={{ width: "100%" }}
            value={commentLog !== null ? commentLog : ""}
            placeholder={dictionary.dialog_placeholder_comment}
            onChange={(e) => {
              dispatch(setComment(e.target.value));
            }}
            className={
              moduleLog === "Drobne_projekty" &&
              commentLog === null &&
              dialogProps.formSubmitted
                ? "p-invalid"
                : null
            }
          />
          {moduleLog === "Drobne_projekty" &&
          commentLog === null &&
          dialogProps.formSubmitted ? (
            <small className="p-error">{dictionary.dialog_error_comment}</small>
          ) : null}
        </div>
        <div className="field field-wrapper">
          {!dialogProps.postEditing ? (
            <Button
              label={dictionary.dialog_save_btn}
              icon="pi pi-save"
              className="field-report-btn"
              onClick={() => {
                dialogProps.setFormSubbmited(true);
                if (
                  ctyLog !== null &&
                  modTypeLog !== null &&
                  moduleLog !== null &&
                  stageLog !== null &&
                  hourLog !== 0
                ) {
                  dialogProps.onLogSave();
                  reset();
                  resetCountryButtons();
                } else if (
                  ctyLog !== null &&
                  modTypeLog !== null &&
                  moduleLog === "Drobne_projekty" &&
                  taskLog !== null &&
                  hourLog !== 0 &&
                  commentLog !== null
                ) {
                  dialogProps.onLogSave();
                  reset();
                  resetCountryButtons();
                } else if (moduleLog === "Hour management" && hourLog !== 0) {
                  dialogProps.onLogSave();
                  reset();
                  resetCountryButtons();
                }
              }}
            />
          ) : (
            <Button
              label={dictionary.dialog_update_btn}
              icon="pi pi-save"
              className="field-report-btn"
              onClick={() => {
                dialogProps.setFormSubbmited(true);
                if (
                  ctyLog !== null &&
                  modTypeLog !== null &&
                  moduleLog !== null &&
                  stageLog !== null &&
                  hourLog !== 0
                ) {
                  dialogProps.onRowEditComplete();
                  reset();
                  resetCountryButtons();
                } else if (
                  ctyLog !== null &&
                  modTypeLog !== null &&
                  moduleLog === "Drobne_projekty" &&
                  taskLog !== null &&
                  hourLog !== 0 &&
                  commentLog !== null
                ) {
                  dialogProps.onRowEditComplete();
                  reset();
                  resetCountryButtons();
                } else if (moduleLog === "Hour management" && hourLog !== 0) {
                  dialogProps.onRowEditComplete();
                  reset();
                  resetCountryButtons();
                }
              }}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CountryComponent;
