import React from "react";
import { dictionary } from "../../../../dictionary";

const SummaryHoursLogistic = (props) => {
  const properties = props.properties;
  const styles = props.styles;

  return (
    <>
      <tr>
        <th className="logistics_modules" style={styles.logistics_modules}>
          {dictionary.productivity_table_summary_log_hours}
        </th>
        <td className="logistics_modules" style={styles.logistics_modules}></td>
        <td className="logistics_modules" style={styles.logistics_modules}>
          {(properties.summaryHoursLogisticCountry + properties.summaryHoursLogistic)
            .toString()
            .replace(".", ",")}
        </td>
        <td className="logistics_modules" style={styles.logistics_modules}></td>
        <td className="logistics_modules" style={styles.logistics_modules}>
          {properties.summaryHoursLogistic.toString().replace(".", ",")}
        </td>
        {properties.countries.map((country, idx) => {
          return (
            <td
              key={idx}
              className="logistics_modules"
              style={styles.logistics_modules}
            >
              {properties.summaryHoursLogisticsSingleCountry(country)
                .toString()
                .replace(".", ",")}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default SummaryHoursLogistic;
