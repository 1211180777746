import { SET_WORKINGH, CLEAR_WORKINGH } from "../actions/logActions";

const initWorkingH = {
  value: null,
};

const workingHLogReducer = (state = initWorkingH, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_WORKINGH:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_WORKINGH:
      return { value: null };
    default:
      return state;
  }
};

export default workingHLogReducer;
