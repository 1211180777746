import React from "react";
import Content from "./components/common/Content";
import "./assets/styles/custom.css";
import { Routes, Route, Navigate } from "react-router-dom";
import MainPage from "./components/common/MainPage";
import Access from "./components/login/AccessComponent.js";
import LoginComponent from "./components/login/loginComponent.js";
import AdminPanel from "./components/common/adminpanel/AdminPanel";
import ExternalRedirect from "./components/external/ExternalRedirect";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Bcg from "./assets/img/bcg.png";

const App = () => {
  const sortLogsByDates = (a, b) => {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  };

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<MainPage />} />
        <Route path="/access" element={<Access />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/timesheet/*" element={<Content sortLogsByDates={sortLogsByDates} />} />
        <Route path="/adminpanel" element={<AdminPanel />} />
        <Route path="/documentation" element={<ExternalRedirect to="https://confluence.schwarz/pages/viewpage.action?pageId=412453542" />} />
      </Routes>
      <img className="app-bcg" src={Bcg} alt="background" />
    </div>
  );
};

export default App;
