import { SET_CLIENT, CLEAR_CLIENT } from "../actions/logActions";

const initClient = {
  value: null,
};

const clientLogReducer = (state = initClient, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CLIENT:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_CLIENT:
      return { value: null };
    default:
      return state;
  }
};

export default clientLogReducer;
