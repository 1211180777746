import React from "react";
import { InputText } from "primereact/inputtext";

const ProductivityRcp = (props) => {
  const properties = props.properties;
  const styles = {
    default: { border: "solid black", borderWidth: "thin", width: "auto", padding: ".5rem", fontWeight: "bold" },
  };

  return (
    <>
      <table style={{ width: "800px" }}>
        <caption
          style={{
            border: "solid black",
            borderWidth: "thin",
            width: "auto",
            padding: ".5rem",
            fontWeight: "bold",
          }}
        >
          RCP data
        </caption>
        <thead>
          <tr>
            <th className="default" style={styles.default}>
              working days
            </th>
            <th className="default" style={styles.default}>
              employees
            </th>
            <th className="default" style={styles.default}>
              employees2
            </th>
            <th className="default" style={styles.default}>
              INT employees
            </th>
            <th className="default" style={styles.default}>
              trainings
            </th>
            <th className="default" style={styles.default}>
              absences
            </th>
            <th className="default" style={styles.default}>
              sick leave
            </th>
            <th className="default" style={styles.default}>
              special leave
            </th>
            <th className="default" style={styles.default}>
              childcare
            </th>
            <th className="default" style={styles.default}>
              holidays
            </th>
            <th className="default" style={styles.default}>
              Iwona absence
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="default" style={styles.default}>
              <InputText value={properties.workingDays} onChange={(e) => properties.setWorkingDays(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.employees} onChange={(e) => properties.setEmployyes(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.employees2} onChange={(e) => properties.setEmployyes2(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.intEmployees} onChange={(e) => properties.setIntEmployyes(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.trainings} onChange={(e) => properties.setTrainings(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.absences} onChange={(e) => properties.setAbsences(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.sickLeave} onChange={(e) => properties.setSickLeave(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.specialLeave} onChange={(e) => properties.setSpecialLeave(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.childcare} onChange={(e) => properties.setChildcare(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.holidays} onChange={(e) => properties.setHolidays(e.target.value)} />
            </td>
            <td className="default" style={styles.default}>
              <InputText value={properties.managerAbsence} onChange={(e) => properties.setManagerAbsence(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td className="default" style={styles.default}>
              {properties.emplRate.toString().replace(".", ",")}
            </td>
            <td className="default" style={styles.default}>
              {properties.emplRate2.toString().replace(".", ",")}
            </td>
            <td className="default" style={styles.default}>
              {properties.intRate.toString().replace(".", ",")}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ProductivityRcp;
