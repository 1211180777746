import { SET_STAGE, CLEAR_STAGE } from "../actions/logActions";

const initStage = {
  value: null,
};

const stageLogReducer = (state = initStage, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_STAGE:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_STAGE:
      return { value: null };
    default:
      return state;
  }
};

export default stageLogReducer;
