import React from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

const TeamsBruttoData = (props) => {
  const calcProps = props.calcProps;
  const styles = props.calcStyles;
  if (!calcProps.filteredPostsByMonth.length) {
    return;
  }
console.log(calcProps)
  const monthName = calcProps.filteredPostsByMonth[calcProps.chartDateIndex].month;
  const handleResources = (wd, jobs) => {
    return wd * jobs * parseFloat(calcProps.rate);
  };

  const sumAllCountryHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.client === "COUNTRY" && post.country !== "" && post.country !== null) {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllIntHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.client === "INT") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllLogisticsHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.client === "LOGISTIC") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllLocManagementHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Localization_management") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllEldHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.client === "ELD") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllWOrgHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Organizacja pracy") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllMeetingsHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Spotkania") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllTrainingsHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Szkolenia") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllVacationsHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Urlop") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const sumAllAbsenceHoursByMonth = (index) => {
    let temp = [];
    calcProps.filteredPostsByMonth[index].posts.forEach((post) => {
      if (post.module === "Nieobecność") {
        temp.push(post.hours);
      }
    });
    return Math.floor(temp.reduce((acc, curr) => acc + curr, 0));
  };

  const chartLaz = sumAllCountryHoursByMonth(calcProps.chartDateIndex) + sumAllIntHoursByMonth(calcProps.chartDateIndex) + sumAllLogisticsHoursByMonth(calcProps.chartDateIndex);
  const chartLocMan = sumAllLocManagementHoursByMonth(calcProps.chartDateIndex);
  const chartEld = sumAllEldHoursByMonth(calcProps.chartDateIndex);
  const chartWorg = sumAllWOrgHoursByMonth(calcProps.chartDateIndex);
  const chartMeet = sumAllMeetingsHoursByMonth(calcProps.chartDateIndex);
  const chartTraining = sumAllTrainingsHoursByMonth(calcProps.chartDateIndex);
  const chartVac = sumAllVacationsHoursByMonth(calcProps.chartDateIndex);
  const chartAbs = sumAllAbsenceHoursByMonth(calcProps.chartDateIndex);

  const chartDataBrutto = {
    labels: ["LAZ summary", "Localization management", "ELD technology", "Work organization", "Meetings", "Trainings", "Vacation", "Sick leave and others"],
    datasets: [
      {
        data: [chartLaz, chartLocMan, chartEld, chartWorg, chartMeet, chartTraining, chartVac, chartAbs],
        backgroundColor: ["#4472C4", "#E2F0D9", "#385723", "#548235", "#A9D18E", "#70AD47", "#ED7D31", "#FFC000"],
      },
    ],
  };

  const handlePercentage = (data) => {
    const chartData = data.datasets[0].data;
    const reducer = (acc, curr) => acc + curr;
    const total = chartData.reduce(reducer);
    const percentageArr = [];
    chartData.forEach((val) => {
      percentageArr.push(((val * 100) / total).toFixed(2) + "%");
    });
    return percentageArr;
  };

  const chartOptionsBrutto = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: monthName + " " + new Date().getFullYear() +" brutto",
        position: "top",
      },
    },
  };

  const legendItems = chartDataBrutto.labels.map((label, index) => (
    <li key={index}>
      <div style={{ backgroundColor: chartDataBrutto.datasets[0].backgroundColor[index] }}></div>
      {label + " " + "(" + handlePercentage(chartDataBrutto)[index] + ")"}
    </li>
  ));

  const itemsPerColumn = Math.ceil(chartDataBrutto.labels.length / 2);
  const colItems1 = legendItems.slice(0, itemsPerColumn);
  const colItems2 = legendItems.slice(itemsPerColumn);

  return (
    <>
      <tr>
        <th>brutto</th>
      </tr>
      <tr style={{ position: "relative" }}>
        <th colSpan="2" className="default" style={styles.default}>
          Resourcess
        </th>
        {calcProps.wd.map((wd, index) => {
          return (
            <td key={index} className="defaultBold" style={styles.defaultBold}>
              {handleResources(wd, calcProps.fullJobs).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="default" style={styles.default}></td>
        <td className="chart-cell brutto" style={calcProps.teamIdToFilter !== "devt" &&
          calcProps.teamIdToFilter !== "indet" ? {top: "-700px "} : {top: "-400px"}}>
          <div className="col">
            <div className="chart-wrapper">
              <Chart type="pie" data={chartDataBrutto} options={chartOptionsBrutto} />
            </div>
            <div className="legend-wrapper">
              <ul>
                <div className="col">{colItems1}</div>
                <div className="col">{colItems2}</div>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          LAZ Summary
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="defaultBold" style={styles.defaultBold}>
              {sumAllCountryHoursByMonth(index) + sumAllIntHoursByMonth(index) + sumAllLogisticsHoursByMonth(index)}
            </td>
          );
        })}
        <td className="center" style={styles.center}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          Localization management
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="center" style={styles.center}>
              {sumAllLocManagementHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="center" style={styles.center}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          ELD Technology
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="center" style={styles.center}>
              {sumAllEldHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          Work organization
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="center" style={styles.center}>
              {sumAllWOrgHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          Meetings
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="center" style={styles.center}>
              {sumAllMeetingsHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          Trainings
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="center" style={styles.center}>
              {sumAllTrainingsHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          Vacations
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="center" style={styles.center}>
              {sumAllVacationsHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
      </tr>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          Sick leave and others
        </th>
        {calcProps.wd.map((_, index) => {
          return (
            <td key={index} className="center" style={styles.center}>
              {sumAllAbsenceHoursByMonth(index).toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="green" style={styles.green}></td>
      </tr>
    </>
  );
};

export default TeamsBruttoData;
