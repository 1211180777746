import { SET_CTY, CLEAR_CTY } from "../actions/logActions";

const initCountry = {
  value: null,
};

const ctyLogReducer = (state = initCountry, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CTY:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_CTY:
      return { value: null };
    default:
      return state;
  }
};

export default ctyLogReducer;
