import { SET_ESP, CLEAR_ESP } from "../actions/logActions";

const initEsp = {
  value: null,
};

const espLogReducer = (state = initEsp, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ESP:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_ESP:
      return { value: null };
    default:
      return state;
  }
};

export default espLogReducer;
