import React from "react";
import { dictionary } from "../../../../dictionary";

const Proportion = (props) => {
  const properties = props.properties;
  const styles = props.styles;

  return (
    <>
      <tr>
        <th className="default" style={styles.default}>
          {dictionary.productivity_table_summary_proportion}
        </th>
        <td className="proportion" style={styles.proportion}>
          10,00%
        </td>
        <td className="default" style={styles.default}>
          {properties.proportion1}%
        </td>
        <td className="default" style={styles.default}>
          {properties.proportion2.toString().replace(".", ",")}%
        </td>
        <td className="default" style={styles.default}>
          {properties.proportion3.toString().replace(".", ",")}%
        </td>
        {properties.countries.map((country, idx) => {
          let ctySum =
            properties.summarySalesAllCountiresSingleCountry(country) +
            properties.espSalesCountrySum(country) +
            properties.summaryHoursLogisticsSingleCountry(country) +
            properties.espLogisticsCountrySum(country);
          let res = (
            ((ctySum /
              (properties.salesModuleSum +
                properties.summaryHoursLogistic +
                properties.allCountriesCountrySum)) *
              100 *
              10) /
            10
          ).toFixed(1);
          return (
            <td key={idx} className="default" style={styles.default}>
              {res.toString().replace(".", ",")}%
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default Proportion;
