import { fetchEldDataRequest, fetchEldDataSuccess, fetchEldDataFailure } from "../actions/dataActions";
import { fetchUserPostsRequest, fetchUserPostsSuccess, fetchUserPostsFailure } from "../actions/postActions";

export const fetchEldData = () => {
  return async (dispatch) => {
    dispatch(fetchEldDataRequest());

    try {
      const response = await fetch("https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/eld",{
        method: 'GET',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      dispatch(fetchEldDataSuccess(data));
    } catch (error) {
      dispatch(fetchEldDataFailure(error));
    }
  };
};

export const fetchInitialPosts = (username) => {
  return async (dispatch) => {
    dispatch(fetchUserPostsRequest());

    try {
      const response = await fetch(`https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts/${username}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const posts = await response.json();
      dispatch(fetchUserPostsSuccess(posts));
    } catch (error) {
      dispatch(fetchUserPostsFailure(error));
    }
  };
};
