import React from "react";
import { dictionary } from "../../../../dictionary";

const SalesSum = (props) => {
  const properties = props.properties;
  const styles = props.styles;
  const sum =
    properties.salesModuleSum +
    properties.summarySalesAllCountires +
    properties.espSalesAllCountrySum;

  return (
    <>
      <tr>
        <th className="default" style={styles.default}>
          {dictionary.productivity_table_summary_sales_sum}
        </th>
        <td className="default" style={styles.default}></td>
        <td className="default" style={styles.default}>
          {sum.toString().replace(".", ",")}
        </td>
        <td className="default" style={styles.default}>
          {properties.salesModuleSum.toString().replace(".", ",")}
        </td>
        <td className="default" style={styles.default}>
          0
        </td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="default" style={styles.default}>
              {(
                properties.summarySalesAllCountiresSingleCountry(country) +
                properties.espSalesCountrySum(country)
              )
                .toString()
                .replace(".", ",")}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default SalesSum;
