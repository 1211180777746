import React, { useState, useRef, useEffect } from "react";
import TeamsBruttoData from "./tables/TeamsBruttoData";
import TeamsNettoData from "./tables/TeamsNettoData";
import TeamsPercentData from "./tables/TeamsPercentData";
import TeamsRcpData from "./tables/TeamsRcpData";
import { dictionary } from "../../../dictionary";

const SumTeamReport = (props) => {
  const tableProps = props.tableProps;
  const users = tableProps.eldData[0].users;
  const filteredPostsByMonth = tableProps.postsByMonth.map((monthData) => {
    const filteredPosts = monthData.posts.filter((post) =>
      users.some(
        (user) =>
          user.name === post.user && user.team === tableProps.teamIdToFilter
      )
    );
    return { month: monthData.month, posts: filteredPosts };
  });
  const tableRef = useRef(null);
  const [monthlySum, setMonthlySum] = useState([]);

  useEffect(() => {
    tableProps.setFixRef(tableRef.current);
  }, []);

  useEffect(() => {
    let temp = [];
    filteredPostsByMonth.forEach((month) => {
      const hours = calculateHours(month.posts, "", "", true);
      temp.push(hours);
    });
    setMonthlySum(temp);
  }, []);

  const handleSumYearlyHours = () => {
    let temp = [];
    filteredPostsByMonth.forEach((month) => {
      month.posts.forEach((post) => {
        temp.push(post.hours);
      });
    });
    let res = temp.reduce((acc, curr) => acc + curr, 0);
    return res.toString().replace(".", ",");
  };

  const handleSummary = (posts, key, value) => {
    let temp = [];
    posts.forEach((month) => {
      month.posts.forEach((post) => {
        if (post[key] === value) {
          temp.push(post.hours);
        }
      });
    });
    let res = temp.reduce((acc, curr) => acc + curr, 0);
    return res.toString().replace(".", ",");
  };

  const renderHeader = () => (
    <thead>
      <tr>
        <th colSpan="2" className="default" style={styles.default}>
          {dictionary.productivity_teams_table_row_header}
        </th>
        {filteredPostsByMonth.map((month, idx) => (
          <th key={idx} className="header" style={styles.header}>
            {month.month}
          </th>
        ))}
        <th className="header" style={styles.header}>
          SUM
        </th>
      </tr>
    </thead>
  );

  const renderCountryRow = (country, index) => (
    <tr key={index}>
      <th className="gray" style={styles.gray}>
        {country.code}
      </th>
      <th className="gray" style={styles.gray}>
        {country.name}
      </th>
      {filteredPostsByMonth.map((month, idx) => {
        const hoursForCountry = month.posts
          .filter((post) => post.country === country.name)
          .map((post) => post.hours)
          .reduce((acc, hours) => acc + hours, 0);
        return (
          <td key={idx} className="blank" style={styles.blank}>
            {hoursForCountry.toString().replace(".", ",")}
          </td>
        );
      })}
      <td className="blank" style={styles.blank}>
        {handleSummary(filteredPostsByMonth, "country", country.name)}
      </td>
    </tr>
  );

  const renderEldRow = (task, index) => (
    <tr key={index}>
      <th colSpan="2" className="gray" style={styles.green}>
        {task.name}
      </th>
      {filteredPostsByMonth.map((month, idx) => {
        const hoursForEld = month.posts
          .filter((post) => post.module === task.name)
          .map((post) => post.hours)
          .reduce((acc, hours) => acc + hours, 0);
        return (
          <td key={idx} className="blank" style={styles.blank}>
            {hoursForEld.toString().replace(".", ",")}
          </td>
        );
      })}
      <td className="blank" style={styles.blank}>
        {handleSummary(filteredPostsByMonth, "module", task.name)}
      </td>
    </tr>
  );

  const calculateHours = (posts, filterKey, filterValue, isTotal = false) => {
    return posts
      .filter((post) => isTotal || post[filterKey] === filterValue)
      .map((post) => post.hours)
      .reduce((acc, hours) => acc + hours, 0);
  };

  const renderRow = (
    header,
    filterKey,
    filterValue,
    style,
    isTotal = false
  ) => {
    return (
      <tr key={Math.random()}>
        <th colSpan="2" className={style} style={styles[style]}>
          {header}
        </th>
        {filteredPostsByMonth.map((month, idx) => {
          const hours = calculateHours(
            month.posts,
            filterKey,
            filterValue,
            isTotal
          );
          return (
            <td key={idx} className="blank" style={styles.blank}>
              {hours.toString().replace(".", ",")}
            </td>
          );
        })}
        <td className="blank" style={styles.blank}>
          {isTotal
            ? handleSumYearlyHours()
            : handleSummary(filteredPostsByMonth, filterKey, filterValue)}
        </td>
      </tr>
    );
  };

  const styles = {
    default: {
      background: "#FFF",
      border: "solid black",
      borderWidth: "thin",
      width: "auto",
      padding: ".5rem",
    },
    header: {
      background: "#FFF2CC",
      border: "solid black",
      borderWidth: "thin",
      padding: ".5rem",
    },
    gray: {
      background: "#D9D9D9",
      border: "solid black",
      borderWidth: "thin",
      padding: ".5rem",
    },
    blank: { background: "#FFF", padding: ".5rem", textAlign: "center" },
    blue: {
      background: "#9BC2E6",
      border: "solid black",
      borderWidth: "thin",
      padding: ".5rem",
    },
    green: {
      background: "#A9D08E",
      border: "solid black",
      borderWidth: "thin",
      padding: ".5rem",
    },
    orange: {
      background: "#F8CBAD",
      border: "solid black",
      borderWidth: "thin",
      padding: ".5rem",
    },
  };

  const calcStyles = {
    default: {
      background: "#FFF",
      border: "solid black",
      borderWidth: "thin",
      width: "auto",
      padding: ".5rem",
    },
    defaultBold: {
      border: "solid black",
      borderWidth: "thin",
      width: "auto",
      padding: ".5rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    center: {
      border: "solid black",
      borderWidth: "thin",
      width: "auto",
      padding: ".5rem",
      textAlign: "center",
    },
    green: {
      border: "solid black",
      color: "green",
      borderWidth: "thin",
      width: "auto",
      padding: ".5rem",
      textAlign: "center",
    },
    greentd: {
      backgroundColor: "#E2EFDA",
      border: "solid black",
      borderWidth: "thin",
      width: "auto",
      padding: ".5rem",
      textAlign: "center",
    },
  };

  const calcProps = {
    filteredPostsByMonth: filteredPostsByMonth,
    teamIdToFilter: tableProps.teamIdToFilter,
    wd: tableProps.wd,
    setWd: tableProps.setWd,
    fullJobs: tableProps.fullJobs,
    monthlySum: monthlySum,
    rate: tableProps.rate,
    chartDateIndex: tableProps.chartDateIndex,
  };

  return (
    <>
      <h2>{tableProps.teamName}</h2>
      <p>{dictionary.productivity_teams_jobs_info}</p>
      <table ref={tableRef}>
        {renderHeader()}
        <tbody>
          {tableProps.teamIdToFilter !== "devt" &&
          tableProps.teamIdToFilter !== "indet"
            ? [
                ...tableProps.eldData[0].countries.map(renderCountryRow),
                renderRow(
                  dictionary.productivity_teams_table_row_label1,
                  "module",
                  "Localization_management",
                  "blue"
                ),
              ]
            : renderRow(
                dictionary.productivity_teams_loc_support,
                "client",
                "COUNTRY",
                "blue"
              )}

          {renderRow(
            dictionary.productivity_teams_table_row_label2,
            "client",
            "INT",
            "header"
          )}
          {tableProps.teamIdToFilter === "devt" ||
          tableProps.teamIdToFilter === "indet"
            ? renderRow(
                dictionary.productivity_teams_table_row_label_sharepoint,
                "task",
                "Sharepoint INT",
                "header"
              )
            : null}
          {renderRow(
            dictionary.productivity_teams_table_row_label3,
            "client",
            "LOGISTICS",
            "header"
          )}
          {tableProps.teamIdToFilter !== "devt" &&
          tableProps.teamIdToFilter !== "indet"
            ? renderRow(
                dictionary.productivity_teams_table_row_label4,
                "client",
                "ELD",
                "green"
              )
            : tableProps.eldData[0].eld.map(renderEldRow)}
          {renderRow(
            dictionary.productivity_teams_table_row_label5,
            "module",
            "Organizacja pracy",
            "orange"
          )}
          {renderRow(
            dictionary.productivity_teams_table_row_label6,
            "module",
            "Spotkania",
            "orange"
          )}
          {renderRow(
            dictionary.productivity_teams_table_row_label7,
            "module",
            "Szkolenia",
            "orange"
          )}
          {renderRow(
            dictionary.productivity_teams_table_row_label8,
            "module",
            "Urlop",
            "orange"
          )}
          {renderRow(
            dictionary.productivity_teams_table_row_label9,
            "module",
            "Nieobecność",
            "orange"
          )}
          {renderRow(
            dictionary.productivity_teams_table_row_label10,
            "module",
            "Wcześniejsze wyjście",
            "orange"
          )}
          {renderRow(
            dictionary.productivity_teams_table_row_label11,
            "",
            "",
            "default",
            true
          )}
          <TeamsRcpData calcProps={calcProps} calcStyles={calcStyles} />
          <TeamsBruttoData calcProps={calcProps} calcStyles={calcStyles} />
          <TeamsNettoData calcProps={calcProps} calcStyles={calcStyles} />
          <TeamsPercentData calcProps={calcProps} calcStyles={calcStyles} />
        </tbody>
      </table>
    </>
  );
};

export default SumTeamReport;
