import { combineReducers } from "redux";
import dialogReducer from "./dialogReducers";
import widgetReducer from "./widgetReducers";
import dateLogReducer from "./dateLogReducer";
import clientLogReducer from "./clientLogReducer";
import commentLogReducer from "./commentLogReducer";
import ctyLogReducer from "./countryLogReducer";
import espLogReducer from "./espLogReducer";
import hourLogReducer from "./hourLogReducer";
import modTypeLogReducer from "./modTypeLogReducer";
import moduleLogReducer from "./moduleLogReducer";
import stageLogReducer from "./stageLogReducer";
import taskLogReducer from "./taskLogReducer";
import wplLogReducer from "./wplLogReducer";
import workingHLogReducer from "./workingHLogReducer";
import dataReducer from "./eldDataReducer";
import postReducer from "./postReducer";

const allReducers = combineReducers({
  widget: widgetReducer,
  showDialog: dialogReducer,
  date: dateLogReducer,
  client: clientLogReducer,
  comment: commentLogReducer,
  cty: ctyLogReducer,
  esp: espLogReducer,
  hours: hourLogReducer,
  modType: modTypeLogReducer,
  module: moduleLogReducer,
  stage: stageLogReducer,
  task: taskLogReducer,
  wpl: wplLogReducer,
  wh: workingHLogReducer,
  fetch: dataReducer,
  posts: postReducer,
});

export default allReducers;
