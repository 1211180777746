import { SET_DATE, CLEAR_DATE } from "../actions/logActions";

const initDate = {
  value: new Date().toDateString(),
};

const dateLogReducer = (state = initDate, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DATE:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_DATE:
      return {
        value: new Date().toDateString(),
      };
    default:
      return state;
  }
};

export default dateLogReducer;
