import React from "react";
import { dictionary } from "../../dictionary";
import Logo from "../../assets/logos/e-timer_logo2.png";
import EldLogo from "../../assets/logos/Logo-ELD-02 transparent.png";

const MobileOverlay = () => {
  return (
    <div className="mobile-overlay animation-fade-in">
        <img src={EldLogo} className='leon-logo' alt="leon logo" />
      <img src={Logo} alt="app logo" />
      <p>
        <i className="pi pi-mobile"></i>
        {dictionary.mobile_overlay_info}
      </p>
    </div>
  );
};

export default MobileOverlay;
