import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { dictionary } from "../../../dictionary";

const UserManagement = (props) => {
  const [userName, setUserName] = useState("");
  const [userDeleteName, setUserDeleteName] = useState("");
  const [selectedWh, setSelectedWh] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [formSubmitted, setFormSubbmited] = useState(false);
  const [deleteFormSubmitted, setDeleteFormSubbmited] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userEditIndex, setUserEditIndex] = useState(null);
  const [userData, setUserData] = useState({ ...props.eldData[0] });
  const toast = useRef(null);
  const person = [
    { label: "Normal", value: "Normal" },
    { label: "Special", value: "Special" },
  ];

  const teams = [
    { label: "Content Team", value: "indet" },
    { label: "Development Team", value: "devt" },
    { label: "Localization Team", value: "loct" },
    { label: "Quality Control Team", value: "qct" },
    { label: "Junior Experts and Expert", value: "free" },
  ];

  const dialogFuncMap = {
    edit: setShowEditDialog,
    delete: setShowDeleteDialog,
  };

  const handleOpenDialog = (funcmap, name) => {
    funcmap[`${name}`](true);
  };

  const onDialogHide = (funcmap, name) => {
    funcmap[`${name}`](false);
  };

  const addSuccessMsg = () => {
    toast.current.show({
      severity: "success",
      summary: dictionary.toast_summary_success,
      detail: dictionary.admin_panel_user_mgmt_toast_detail + userName,
    });
  };

  const addEditMsg = () => {
    toast.current.show({
      severity: "success",
      summary: dictionary.toast_summary_success,
      detail: dictionary.admin_panel_user_mgmt_toast_update_detail + userName,
    });
  };

  const addDeleteMsg = () => {
    toast.current.show({
      severity: "success",
      summary: dictionary.toast_summary_success,
      detail: dictionary.admin_panel_user_mgmt_toast_delete_detail + userName,
    });
  };

  const roles = [
    { label: dictionary.admin_panel_user_mgmt_role1, value: true },
    { label: dictionary.admin_panel_user_mgmt_role2, value: false },
  ];

  const handlePersonWorkHours = (e) => {
    setSelectedWh(e.value);
  };

  const handleTeams = (e) => {
    setSelectedTeam(e.value);
  };

  const handleRoles = (e) => {
    setSelectedRole(e.value);
  };

  const reset = () => {
    setUserName("");
    setUserDeleteName("");
    setSelectedWh(null);
    setSelectedTeam(null);
    setSelectedRole(null);
    setFormSubbmited(false);
    setShowEditDialog(false);
    setShowDeleteDialog(false);
    setUserEditIndex(null);
    setUserData({ ...props.eldData[0] });
  };

  const generateUniqueId = () => {
    const timestamp = new Date().getTime().toString();
    const randomStr = Math.random().toString(36).substring(2);
    return `${timestamp}${randomStr}`;
  };

  const handleShowEditDialog = (index) => {
    const user = userData["users"][index];
    const userTeam = teams.find((team) => team.value === user.team)?.value;
    const userRole = roles.find((role) => role.value === user.admin)?.value;
    setUserName(user.name);
    setSelectedWh(user.person);
    setSelectedTeam(userTeam);
    setSelectedRole(userRole);
    setUserEditIndex(index);
  };

  const handleShowDeleteDialog = (index) => {
    const user = userData["users"][index];
    setUserName(user.name);
    setUserEditIndex(index);
  };

  const handleDeleteUser = () => {
    if (userDeleteName === userName) {
      const userId = userData.users[userEditIndex]._id;
  
      fetch(`https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/eld/${userData._id}/users/${userId}`, {
        method: "DELETE",
        mode: 'cors',
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.ok) {
          const data = { ...userData };
          data["users"].splice(userEditIndex, 1);
          setUserData(data);
          addDeleteMsg();
          onDialogHide(dialogFuncMap, "delete");
          reset();
          return response.json();
        } else {
          console.error("Error while DELETE:", response.statusText);
        }
      });
    }
  };

const handleEditUser = () => {
    const updatedUser = {
      _id: userData.users[userEditIndex]._id,
      name: userName,
      person: selectedWh.toLowerCase(),
      team: selectedTeam,
      admin: selectedRole,
    };

  
    fetch(`https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/eld/${userData._id}/users/${updatedUser._id}`, {
      method: "PATCH",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedUser),
    }).then((response) => {
      if (response.ok) {
        const data = { ...userData };
        const userIndex = data.users.findIndex(user => user._id === updatedUser._id);
        data.users[userIndex] = updatedUser;
        setUserData(data);
        addEditMsg();
        onDialogHide(dialogFuncMap, "edit");
        reset();
        return response.json();
      } else {
        console.error("Error while PATCH:", response.statusText);
      }
    });
  };

  const handleAddUser = () => {
    const log = {
      _id: generateUniqueId(),
      name: userName,
      person: selectedWh.toLowerCase(),
      team: selectedTeam,
      admin: selectedRole,
    };
    fetch(`https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/eld/${userData._id}/users`, {
      method: "POST",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(log),
    }).then((response) => {
      if (response.ok) {
        const data = { ...userData };
        data["users"].push(log);
        setUserData(data);
        addSuccessMsg();
        return response.json();
      } else {
        console.error("Error while POST:", response.statusText);
      }
    });
  };  

  return (
    <>
      <Toast ref={toast} />
      <h2>{dictionary.admin_panel_user_mgmt_header}</h2>
      <div className="management-wrapper">
        <Dialog
          header={userName + dictionary.admin_panel_user_mgmt_dialog_header}
          visible={showEditDialog}
          style={{ width: "50vw" }}
          onHide={() => {
            reset();
            onDialogHide(dialogFuncMap, "edit");
          }}
        >
          <div className="p-field">
            <label>{dictionary.admin_panel_user_mgmt_dialog_hours}</label>
            <small>{dictionary.admin_panel_user_mgmt_dialog_hours_info}</small>
            <Dropdown value={selectedWh} options={person} onChange={handlePersonWorkHours} placeholder={selectedWh} />
          </div>
          <div className="p-field">
            <label>{dictionary.admin_panel_user_mgmt_dialog_team}</label>
            <Dropdown value={selectedTeam} options={teams} onChange={handleTeams} placeholder={selectedTeam} />
          </div>
          <div className="p-field">
            <label>{dictionary.admin_panel_user_mgmt_dialog_admin}</label>
            <Dropdown value={selectedRole} options={roles} onChange={handleRoles} placeholder={selectedRole} />
          </div>
          <div className="p-field b-field">
            <Button
              label={dictionary.dialog_save_btn}
              onClick={() => {
                handleEditUser();
              }}
            />
          </div>
        </Dialog>
        <Dialog
          header={dictionary.admin_panel_user_mgmt_dialog_delete_header + userName}
          visible={showDeleteDialog}
          style={{ width: "50vw" }}
          onHide={() => {
            reset();
            onDialogHide(dialogFuncMap, "delete");
          }}
        >
          <div className="p-field">
            <small>
              {dictionary.admin_panel_user_mgmt_dialog_delete_info1} <span className="p-error">{userName}</span> {dictionary.admin_panel_user_mgmt_dialog_delete_info2}
            </small>
            <InputText
              value={userDeleteName}
              className={userDeleteName === "" && deleteFormSubmitted ? "p-invalid" : null}
              onChange={(e) => setUserDeleteName(e.target.value)}
              placeholder={dictionary.admin_panel_user_mgmt_dialog_delete_placeholder}
            />
          </div>
          <div className="p-field b-field">
            <Button
              label={dictionary.admin_panel_user_mgmt_dialog_delete_btn}
              id="remove_user_btn"
              onClick={() => {
                setDeleteFormSubbmited(true);
                handleDeleteUser();
              }}
            />
          </div>
        </Dialog>
        <div className="row">
          <div className="col col-1">
            <h3>{dictionary.admin_panel_user_mgmt_list_title}</h3>
            {props.eldData && (
              <ol>
                {userData.users.map((obj, i) => {
                  return (
                    <li key={i}>
                      <p>{i + 1}.</p>
                      <p className="pi pi-user"></p>
                      <p>
                        <b>{obj.name}</b>
                      </p>
                      <div className="management-btn-wrapper">
                        <Button
                          className="edit-button"
                          id="edit"
                          icon="pi pi-pencil"
                          onClick={() => {
                            handleOpenDialog(dialogFuncMap, "edit");
                            handleShowEditDialog(i);
                          }}
                        />
                        <Button
                          className="delete-button"
                          id="delete"
                          icon="pi pi-times"
                          onClick={() => {
                            handleOpenDialog(dialogFuncMap, "delete");
                            handleShowDeleteDialog(i);
                          }}
                        />
                      </div>
                    </li>
                  );
                })}
              </ol>
            )}
          </div>
          <div className="col">
            <h3>{dictionary.admin_panel_user_mgmt_form_title}</h3>
            <div className="p-field">
              <label>{dictionary.admin_panel_user_mgmt_form_name}</label>
              <small>{dictionary.admin_panel_user_mgmt_form_name_info}</small>
              <InputText
                className={userName === "" && formSubmitted ? "p-invalid" : null}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder={dictionary.admin_panel_user_mgmt_form_name_placeholder}
              />
              {userName === "" && formSubmitted ? <small className="p-error">User name is required.</small> : null}
            </div>
            <div className="p-field">
              <label>{dictionary.admin_panel_user_mgmt_form_hours}</label>
              <small>{dictionary.admin_panel_user_mgmt_form_hours_info}</small>
              <Dropdown
                className={selectedWh === null && formSubmitted ? "p-invalid" : null}
                value={selectedWh}
                options={person}
                onChange={handlePersonWorkHours}
                placeholder={dictionary.admin_panel_user_mgmt_form_hours_placeholder}
              />
              {selectedWh === null && formSubmitted ? <small className="p-error">User working hours is required.</small> : null}
            </div>
            <div className="p-field">
              <label>{dictionary.admin_panel_user_mgmt_dialog_team}</label>
              <Dropdown
                className={selectedTeam === null && formSubmitted ? "p-invalid" : null}
                value={selectedTeam}
                options={teams}
                onChange={handleTeams}
                placeholder={dictionary.admin_panel_user_mgmt_dialog_team_placeholder}
              />
              {selectedTeam === null && formSubmitted ? <small className="p-error">Team is required.</small> : null}
            </div>
            <div className="p-field">
              <label>{dictionary.admin_panel_user_mgmt_dialog_admin}</label>
              <Dropdown
                className={selectedRole === null && formSubmitted ? "p-invalid" : null}
                value={selectedRole}
                options={roles}
                onChange={handleRoles}
                placeholder={dictionary.admin_panel_user_mgmt_dialog_admin_placeholder}
              />
              {selectedRole === null && formSubmitted ? <small className="p-error">User role is required.</small> : null}
            </div>
            <div className="p-field b-field">
              <Button
                label={dictionary.admin_panel_user_mgmt_form_submmit}
                onClick={() => {
                  setFormSubbmited(true);
                  if (userName !== "" && selectedWh !== null && selectedTeam !== null && selectedRole !== null) {
                    handleAddUser();
                    reset();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
