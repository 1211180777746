import React from "react";
import { dictionary } from "../../dictionary";

const FooterMenu = () => {
  return (
    <div className="footer">
      <div className="footer-txt">{dictionary.copyright}</div>
    </div>
  );
};

export default FooterMenu;
