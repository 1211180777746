import { SET_COMMENT, CLEAR_COMMENT } from "../actions/logActions";

const initComment = {
  value: null,
};

const commentLogReducer = (state = initComment, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_COMMENT:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_COMMENT:
      return { value: null };
    default:
      return state;
  }
};

export default commentLogReducer;
