import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { useSelector, useDispatch } from "react-redux";
import { hideOthDialog } from "../../redux/actions/dialogActions.js";
import {
  clearDate,
  setComment,
  setDate,
  setHour,
  setModule,
  clearHour,
} from "../../redux/actions/logActions.js";
import { dictionary } from "../../dictionary.js";
import DialogOverlay from "./DialogOverlay.js";

const OtherComponent = (props) => {
  const dispatch = useDispatch();
  const dialogProps = props.dialogProps;
  const [dateLog, setDateLog] = useState(
    new Date(useSelector((state) => state.date.value))
  );
  const moduleLog = useSelector((state) => state.module.value);
  const hourLog = useSelector((state) => state.hours.value);
  const commentLog = useSelector((state) => state.comment.value);
  const [issues, setIssues] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    if (dialogProps.eldData[0].length !== 0) {
      setIssues(dialogProps.eldData[0].perso.slice(0, -2));
    }
  }, [dialogProps.eldData]);

  const showMsg = () => {
    toast.current.show({
      severity: "warn",
      summary: dictionary.toast_summary_warn,
      detail: dictionary.toast_create_log_detail,
      life: 7000,
    });
  };

  return (
    <>
      <Dialog
        header={dictionary.other_dialog_header}
        visible={dialogProps.isDialogOpen}
        style={{ width: "50vw" }}
        onHide={() => {
          dialogProps.setValuesOnInitialState();
          dialogProps.setShowOverlay(true);
          dispatch(hideOthDialog());
        }}
      >
        <div className="field field-wrapper">
          <Toast ref={toast} />
          <label>{dictionary.dialog_label_date}</label>
          {dialogProps.showOverlay && (
            <DialogOverlay dialogProps={dialogProps} />
          )}
          <Calendar
            disabledDays={dialogProps.disabledDays}
            style={{ width: "100%" }}
            placeholder={dateLog}
            value={dateLog}
            onChange={(e) => {
              if (dialogProps.checkIfDayIsPlanned(e.value)) {
                dispatch(setDate(e.value));
                setDateLog(e.value);
                dialogProps.setShowOverlay(false);
              } else {
                showMsg();
                dispatch(clearDate());
                setDateLog(new Date());
              }
            }}
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_issue}</label>
          <Dropdown
            style={{ width: "100%" }}
            value={moduleLog}
            options={issues}
            filter
            onChange={(e) => {
              dispatch(setModule(e.value.name));
              if (e.value.name === "Urlop" || e.value.name === "Nieobecność") {
                dispatch(setHour(8));
                setDisabled(true);
              } else {
                if (hourLog === 8) {
                  dispatch(clearHour());
                }
                setDisabled(false);
              }
            }}
            optionLabel="name"
            placeholder={
              moduleLog !== null
                ? moduleLog
                : dictionary.dialog_placeholder_issue
            }
            className={
              moduleLog === null && dialogProps.formSubmitted
                ? "p-invalid"
                : null
            }
          />
          {moduleLog === null && dialogProps.formSubmitted ? (
            <small className="p-error">{dictionary.dialog_error_issue}</small>
          ) : null}
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_hours}</label>
          <Dropdown
            style={{ width: "100%" }}
            value={hourLog}
            options={dialogProps.eldData[0].hours}
            filter
            onChange={(e) => {
              dispatch(setHour(e.value.hour));
            }}
            optionLabel="hour"
            placeholder={
              hourLog !== 0 ? hourLog : dictionary.dialog_placeholder_hours
            }
            className={
              hourLog === 0 && dialogProps.formSubmitted ? "p-invalid" : null
            }
            disabled={disabled}
          />
          {hourLog === 0 && dialogProps.formSubmitted ? (
            <small className="p-error">{dictionary.dialog_error_hours}</small>
          ) : null}
        </div>
        <div className="field field-wrapper">
          <label>{dictionary.dialog_label_comment}</label>
          <InputText
            style={{ width: "100%" }}
            value={commentLog !== null ? commentLog : ""}
            placeholder={dictionary.dialog_placeholder_comment}
            onChange={(e) => {
              dispatch(setComment(e.target.value));
            }}
          />
        </div>
        <div className="field field-wrapper">
          {!dialogProps.postEditing ? (
            <Button
              label={dictionary.dialog_save_btn}
              icon="pi pi-save"
              className="field-report-btn"
              onClick={() => {
                dialogProps.setFormSubbmited(true);
                dialogProps.setShowOverlay(true);
                if (moduleLog !== null && hourLog !== 0) {
                  dialogProps.onLogSave();
                }
              }}
            />
          ) : (
            <Button
              label={dictionary.dialog_update_btn}
              icon="pi pi-save"
              className="field-report-btn"
              onClick={() => {
                dialogProps.setFormSubbmited(true);
                dialogProps.setShowOverlay(true);
                if (moduleLog !== null && hourLog !== 0) {
                  dialogProps.onRowEditComplete();
                }
              }}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default OtherComponent;
