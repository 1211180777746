import { SET_WPL, CLEAR_WPL } from "../actions/logActions";

const initWpl = {
  value: null,
};

const wplLogReducer = (state = initWpl, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_WPL:
      return {
        ...state,
        value: payload,
      };
    case CLEAR_WPL:
      return { value: null };
    default:
      return state;
  }
};

export default wplLogReducer;
