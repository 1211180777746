import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import "../../styles/custom.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchEldData } from "../../redux/thunk/thunk";
import { dictionary } from "../../dictionary";
import MobileOverlay from "../common/MobileOverlay";

const Login = () => {
  const dispatch = useDispatch();
  const eldData = useSelector((state) => state.fetch.data);
  const [users, setUsers] = useState([]);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchEldData());
  }, [dispatch]);

  useEffect(() => {
    if (eldData !== null) {
      setUsers(eldData[0].users);
    }
  }, [eldData]);

  const addSuccessMsg = (user) => {
    toast.current.show({
      severity: "success",
      summary: dictionary.toast_summary_success,
      detail: dictionary.toast_login_detail + user.name,
    });
  };

  const handleSubmit = async (user) => {
    sessionStorage.setItem("isLoggedIn", true);
    sessionStorage.setItem("userName", user.name);
    sessionStorage.setItem("user", JSON.stringify(user));

    addSuccessMsg(user);
    const timer = setTimeout(() => {
      setLoading(false);
      navigate("/timesheet", { replace: true });
    }, 2000);
    return () => clearTimeout(timer);
  };

  return (
    <>
    <MobileOverlay />
      {eldData ? (
        <Dialog
          header={dictionary.login_header}
          style={{ width: "50vw" }}
          visible={visible}
          onHide={() => {
            setVisible(false);
            navigate("/home");
          }}
        >
          {loading && (
            <div className="loading-wrapper">
              <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="2" animationDuration="3s" />
            </div>
          )}
          <Toast ref={toast} />
          <h3>{dictionary.login_title}</h3>

          <ol className="users-wrapper">
            {users.map((obj, i) => {
              return (
                <li key={i}>
                  <p>{i + 1}.</p>
                  <p className="pi pi-user"></p>
                  <p>
                    <b>{obj.name}</b>
                  </p>
                  <Button
                    label={dictionary.login_btn}
                    icon="pi pi-chevron-right"
                    onClick={() => {
                      handleSubmit(obj);
                      setLoading(true);
                    }}
                  />
                </li>
              );
            })}
          </ol>
        </Dialog>
      ) : (
        <div className="loading-wrapper">
          <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="2" animationDuration="3s" />
        </div>
      )}
    </>
  );
};

export default Login;
