import React, { useState, useEffect } from 'react';
import { dictionary } from '../../dictionary';

const DaysToEndOfMonth = () => {
    const [daysToEnd, setDaysToEnd] = useState(0);

    useEffect(() => {
        calculateDaysToEnd();
        const interval = setInterval(calculateDaysToEnd, 1000 * 60 * 60 * 24);
        return () => clearInterval(interval);
    }, []);

    const calculateDaysToEnd = () => {
        const now = new Date();
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        const differenceInTime = endOfMonth.getTime() - now.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
        setDaysToEnd(differenceInDays);
    };

    return (
        <div>
            <p>{dictionary.user_logs_table_month_counter1} <span>{daysToEnd}</span> {dictionary.user_logs_table_month_counter2}</p>
        </div>
    );
};

export default DaysToEndOfMonth;
