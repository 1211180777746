import React from "react";
import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";
import { dictionary } from "../../../dictionary";

const Widget = (props) => {
  const customWidgetHeader = (
    <React.Fragment>
      <h3>{dictionary.widget_title}</h3>
    </React.Fragment>
  );

  return (
    <Sidebar visible={props.widgetVisible} icons={customWidgetHeader} onHide={() => props.setWidgetVisible(false)}>
      <div className="widget-link-info">
        {dictionary.widget_bug_1}<span className="widget-bug">{dictionary.widget_bug_2}</span>{dictionary.widget_bug_3}<span className="widget-link">{dictionary.widget_bug_4}</span>{dictionary.widget_bug_5}
        <br />
        <span className="bug-wrapper">
          <a href="https://jira.schwarz/secure/CreateSubTaskIssue.jspa?parentIssueId=1302607&pid=18200&issuetype=Sub-task" target="_blank">
            <i className="fa fa-bug" ></i>
            {dictionary.widget_bug_report}
          </a>
        </span>
        <p>{dictionary.widget_bug_report_info}</p>
        <p>
          {dictionary.widget_fields}<b>{dictionary.widget_field_1}</b>, <b>{dictionary.widget_field_2}</b>, <b>{dictionary.widget_field_3}</b>, <b>{dictionary.widget_field_4}</b>, <b>{dictionary.widget_field_1}</b>.{dictionary.widget_assign}
        </p>
      </div>
      <Divider type="solid" />
      <ul>
        {props.widgetInfo.map((items, index) => (
          <li key={index}>
            <span>{items.title}</span>
            <h4>{items.vnr}</h4>
            <p>{items.info}</p>
            <Divider type="solid" />
          </li>
        ))}
      </ul>
    </Sidebar>
  );
};

export default Widget;
