import React from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

const ProductivityManagement = (props) => {
  const properties = props.properties;
  const styles = props.resourcesStyles;
  const resources = properties.workingDays * properties.employees * properties.emplRate + properties.workingDays * properties.employees2 * properties.emplRate2;
  const allEmployyes = parseInt(properties.employees) + parseInt(properties.employees2) + parseInt(properties.intEmployees);
  const eldSum = properties.monthlyData.reduce((total, obj) => {
    if (obj.client === "ELD") {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const meetingsSum = properties.monthlyData.reduce((total, obj) => {
    if (obj.module === "Spotkania") {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const trainingsSum = properties.monthlyData.reduce((total, obj) => {
    if (obj.module === "Szkolenia") {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const vacationSum = properties.monthlyData.reduce((total, obj) => {
    if (obj.module === "Urlop") {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const absenceSum = properties.monthlyData.reduce((total, obj) => {
    if (obj.module === "Nieobecność") {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const workOrgSum = properties.monthlyData.reduce((total, obj) => {
    if (obj.module === "Organizacja pracy") {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const lazSum = properties.summarySalesAllCountires + properties.salesModuleSum;

  const chartDataBrutto = {
    labels: ["LAZ summary", "ELD Management", "Localization management", "ELD technology", "Work organization", "Meetings", "Trainings", "Vacation", "Sick leave and others"],
    datasets: [
      {
        data: [lazSum + (lazSum * 10) / 100, 0, 0, eldSum, workOrgSum, meetingsSum, trainingsSum, vacationSum, absenceSum],
        backgroundColor: ["#4472C4", "#C5E0B4", "#E2F0D9", "#385723", "#548235", "#A9D18E", "#70AD47", "#ED7D31", "#FFC000"],
      },
    ],
  };

  const chartDataNetto = {
    labels: [
      "LAZ summary",
      "ELD Management",
      "Localization management",
      "ELD technology",
      "Work organization",
      "Meetings",
      "Trainings",
      "Vacation",
      "Sick leave and others",
    ],
    datasets: [
      {
        data: [lazSum + (lazSum * 10) / 100 + vacationSum, 0, 0, eldSum, workOrgSum, meetingsSum, trainingsSum, vacationSum, absenceSum],
        backgroundColor: ["#4472C4", "#C5E0B4", "#E2F0D9", "#385723", "#548235", "#A9D18E", "#70AD47", "#ED7D31", "#FFC000"],
      },
    ],
  };

  const handlePercentage = (data) => {
    const chartData = data.datasets[0].data;
    const reducer = (acc, curr) => acc + curr;
    const total = chartData.reduce(reducer);
    const percentageArr = [];
    chartData.forEach((val) => {
      percentageArr.push(((val * 100) / total).toFixed(2) + "%");
    });
    return percentageArr;
  };

  const chartOptionsBrutto = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Work Performance Brutto",
        position: "top",
      },
      subtitle: {
        display: true,
        text: "Active employyes: " + allEmployyes + " persons",
        position: "top",
      },
    },
  };

  const chartOptionsNetto = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Work Performance Netto",
        position: "top",
      },
      subtitle: {
        display: true,
        text: "Active employyes: " + allEmployyes + " persons",
        position: "top",
      },
    },
  };

  const dataObj = {
    resources: {
      name: "Resources",
      val: resources.toString().replace(".", ","),
    },
    laz: {
      name: "LAZ summary",
      val: (lazSum + (lazSum * 10) / 100).toString().replace(".", ","),
    },
    laznetto: {
      name: "",
      val: (lazSum + (lazSum * 10) / 100 + vacationSum).toString().replace(".", ","),
    },
    eldman: {
      name: "ELD Management",
      val: 0,
    },
    eldmannetto: {
      name: "",
      val: 0,
    },
    locman: {
      name: "Localization management",
      val: 0,
    },
    locmannetto: {
      name: "",
      val: 0,
    },
    eldtech: {
      name: "ELD technology",
      val: eldSum.toString().replace(".", ","),
    },
    eldtechnetto: {
      name: "",
      val: eldSum.toString().replace(".", ","),
    },
    worg: {
      name: "Work organization",
      val: workOrgSum.toString().replace(".", ","),
    },
    worgnetto: {
      name: "",
      val: workOrgSum.toString().replace(".", ","),
    },
    meet: {
      name: "Meetings",
      val: meetingsSum.toString().replace(".", ","),
    },
    meetnetto: {
      name: "",
      val: meetingsSum.toString().replace(".", ","),
    },
    train: {
      name: "Trainings",
      val: trainingsSum.toString().replace(".", ","),
    },
    trainnetto: {
      name: "",
      val: trainingsSum.toString().replace(".", ","),
    },
    vac: {
      name: "Vacations",
      val: vacationSum.toString().replace(".", ","),
    },
    vacnetto: {
      name: "",
      val: vacationSum.toString().replace(".", ","),
    },
    abs: {
      name: "Sick leave and others",
      val: absenceSum.toString().replace(".", ","),
    },
    absnetto: {
      name: "",
      val: absenceSum.toString().replace(".", ","),
    },
  };

  const keysWithoutNetto = Object.keys(dataObj).filter((key) => !key.toLocaleLowerCase().includes("netto"));

  const legendItemsBrutto = chartDataBrutto.labels.map((label, index) => (
    <li key={index}>
      <div style={{ backgroundColor: chartDataBrutto.datasets[0].backgroundColor[index] }}></div>
      {label + " " + "(" + handlePercentage(chartDataBrutto)[index] + ")"}
    </li>
  ));

  const legendItemsNetto = chartDataNetto.labels.map((label, index) => (
    <li key={index}>
      <div style={{ backgroundColor: chartDataNetto.datasets[0].backgroundColor[index] }}></div>
      {label + " " + "(" + handlePercentage(chartDataNetto)[index] + ")"}
    </li>
  ));

  const itemsPerColumnBrutto = Math.ceil(chartDataBrutto.labels.length / 2);
  const itemsPerColumnNetto = Math.ceil(chartDataNetto.labels.length / 2);
  const colItemsBrutto1 = legendItemsBrutto.slice(0, itemsPerColumnBrutto);
  const colItemsBrutto2 = legendItemsBrutto.slice(itemsPerColumnBrutto);
  const colItemsNetto1 = legendItemsNetto.slice(0, itemsPerColumnNetto);
  const colItemsNetto2 = legendItemsNetto.slice(itemsPerColumnNetto);

  return (
    <>
      {keysWithoutNetto.map((key, index) => {
        return (
          <tr key={index}>
            <th className="darktd" style={index <= 3 || index > 7 ? styles.darktd : styles.lighttd}>
              {dataObj[key].name}
            </th>
            <td className="darktd" style={index <= 3 || index > 7 ? styles.darktd : styles.lighttd}></td>
            <td className="darktd" style={index <= 3 || index > 7 ? styles.darktd : styles.bluetd}>
              {dataObj[key].val}
            </td>
            {index === 0 ? (
              <>
                <td className="default" style={styles.default}>
                  netto
                </td>
                <td className="chart-cell">
                  <div className="col">
                    <div className="chart-wrapper">
                      <Chart type="pie" data={chartDataBrutto} options={chartOptionsBrutto} />
                    </div>
                    <div className="legend-wrapper">
                      <ul>
                        <div className="col">{colItemsBrutto1}</div>
                        <div className="col">{colItemsBrutto2}</div>
                      </ul>
                    </div>
                  </div>
                  <div className="col">
                    <div className="chart-wrapper">
                      <Chart type="pie" data={chartDataNetto} options={chartOptionsNetto} />
                    </div>
                    <div className="legend-wrapper">
                      <ul>
                        <div className="col">{colItemsNetto1}</div>
                        <div className="col">{colItemsNetto2}</div>
                      </ul>
                    </div>
                  </div>
                </td>
              </>
            ) : (
              <td className="darktd" style={index <= 3 || index > 7 ? styles.darktd : styles.lighttd}>
                {dataObj[`${key}netto`].val}
              </td>
            )}
          </tr>
        );
      })}
    </>
  );
};

export default ProductivityManagement;
