import React from "react";
import { dictionary } from "../../../../dictionary";

const CostSales = (props) => {
  const properties = props.properties;
  const styles = props.styles;

  return (
    <>
      <tr>
        <th className="other" style={styles.other}>
          {dictionary.productivity_table_summary_cost_sales}
        </th>
        <td className="salesRate" style={styles.salesRate}>
          {properties.costRate + dictionary.currency}
        </td>
        <td className="other" style={styles.other}>
          {(properties.sumAllCountriesCostsSalesArray + properties.costSalesINT)
            .toFixed(2)
            .toString()
            .replace(".", ",") + dictionary.currency}
        </td>
        <td className="other" style={styles.other}>
          {properties.costSalesINT.toString().replace(".", ",") + dictionary.currency}
        </td>
        <td className="other" style={styles.other}>
          0.00{dictionary.currency}
        </td>
        {properties.countries.map((country, idx) => {
          let ctySum =
            properties.summarySalesAllCountiresSingleCountry(country) +
            properties.espSalesCountrySum(country);
          return (
            <td key={idx} className="other" style={styles.other}>
              {(ctySum * properties.costRate * properties.costConstant)
                .toFixed(2)
                .toString()
                .replace(".", ",") + dictionary.currency}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default CostSales;
