import React from "react";
import { dictionary } from "../../../../dictionary";

const BillForCountry = (props) => {
  const properties = props.properties;
  const styles = props.styles;
  const sum =
    properties.sumAllCountriesCostsSalesArray +
    properties.costSalesINT +
    properties.sumAllCountriesCostsLogisticArray +
    properties.costLogisicsINT;

  return (
    <>
      <tr>
        <th className="ctysum" style={styles.ctysum}>
          {dictionary.productivity_table_summary_bill}
        </th>
        <td className="bill" style={styles.bill}></td>
        <td className="bill" style={styles.bill}>
          {sum.toFixed(2).toString().replace(".", ",") + dictionary.currency}
        </td>
        <td className="bill" style={styles.bill}>
          {(0 + properties.costSalesINT)
            .toFixed(2)
            .toString()
            .replace(".", ",") + dictionary.currency}
        </td>
        <td className="bill" style={styles.bill}>
          {(properties.costLogisicsINT + 0).toString().replace(".", ",") +
            dictionary.currency}
        </td>
        {properties.countries.map((country, idx) => {
          return (
            <td key={idx} className="ctysum" style={styles.ctysum}>
              {(
                (properties.summarySalesAllCountiresSingleCountry(country) +
                  properties.espSalesCountrySum(country)) *
                  properties.costRate *
                  properties.costConstant +
                (properties.summaryHoursLogisticsSingleCountry(country) +
                  properties.espLogisticsCountrySum(country)) *
                  properties.costRate *
                  properties.costConstant
              )
                .toFixed(2)
                .toString()
                .replace(".", ",") + dictionary.currency}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default BillForCountry;
