import React from "react";
import CountryReportTable from "./CountryReportTable";
import MinorProjects from "./MinorProjects";
import { dictionary } from "../../dictionary.js";

const DefaultReportTable = (props) => {
  const properties = props.properties;
  const startDate =
    properties.dates.length > 1
      ? properties.monthNames[properties.dates[0].getMonth()] +
        properties.dates[0].getFullYear()
      : null;
  const endDate =
    properties.dates.length > 1
      ? properties.monthNames[properties.dates[1].getMonth()] +
        properties.dates[1].getFullYear()
      : null;
  const dateRange = !properties.dates.length
    ? properties.monthNames[properties.dates.getMonth()] +
      properties.dates.getFullYear()
    : null;
  const styles = {
    header: {
      background: "#FFF2CC",
      border: "solid black",
      borderWidth: "thin",
      width: "auto",
    },
    gray: {
      background: "#D9D9D9",
      border: "solid black",
      borderWidth: "thin",
    },
    empty: {
      border: "solid black",
      borderWidth: "thin",
      textAlign: "right",
    },
    green: {
      background: "#C6E0B4",
      border: "solid black",
      borderWidth: "thin",
      fontWeight: "bold",
    },
    greenSum: {
      background: "#C6E0B4",
      border: "solid black",
      borderWidth: "thin",
      textAlign: "right",
      fontWeight: "bold",
    },
  };

  return (
    <>
      <table ref={properties.tableRef}>
        {properties.countryLog !== null ? (
          <>
            <CountryReportTable properties={properties} />
          </>
        ) : (
          <>
            <thead>
              <tr>
                <th className="header" style={styles.header}>
                  {properties.dates.length > 1
                    ? dictionary.export_dialog_from +
                      startDate +
                      " " +
                      dictionary.export_dialog_to +
                      endDate
                    : dateRange}
                </th>
                <th className="header" style={styles.header}>
                  {dictionary.export_table_th_hours}
                </th>
                {/* <th className="header" style={styles.header}>
                  {dictionary.export_table_th_costs +
                    "(" +
                    properties.costRate +
                    dictionary.currency +
                    ")"}
                </th> */}
              </tr>
            </thead>
            <tbody>
              {properties.logsToDisplay !== null
                ? properties.logsToDisplay.map((item, index) => {
                    if (item.module.includes("Drobne_projekty")) {
                      item.module = item.module.replace(
                        "Drobne_projekty",
                        "Minor_projects"
                      );
                    }
                    return (
                      <tr key={index}>
                        <td className="gray" style={styles.gray}>
                          {item.module}
                        </td>
                        <td className="empty" style={styles.empty}>
                          {item.hours.toString().replace(".", ",")}
                        </td>
                        {/* <td className="empty" style={styles.empty}>
                          {(item.hours * properties.costRate)
                            .toString()
                            .replace(".", ",") + dictionary.currency}
                        </td> */}
                      </tr>
                    );
                  })
                : null}

              <tr>
                <td className="green" style={styles.green}>
                  {dictionary.export_table_summary}
                </td>
                <td className="green" style={styles.greenSum}>
                  {properties.logsToDisplay
                    .reduce((total, obj) => obj.hours + total, 0)
                    .toString()
                    .replace(".", ",")}
                </td>
                {/* <td className="green" style={styles.greenSum}>
                  {(
                    properties.logsToDisplay.reduce(
                      (total, obj) => obj.hours + total,
                      0
                    ) * properties.costRate
                  )
                    .toString()
                    .replace(".", ",") + dictionary.currency}
                </td> */}
              </tr>
              <tr>
                <td>
                  {properties.client !== "ELD" && (
                    <MinorProjects properties={properties} />
                  )}
                </td>
              </tr>
            </tbody>
          </>
        )}
      </table>
    </>
  );
};

export default DefaultReportTable;
