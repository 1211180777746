import React, { useState } from "react";
import { dictionary } from "../../dictionary";
import { Button } from "primereact/button";

const DialogOverlay = (props) => {
  const dialogProps = props.dialogProps;
  return (
    <div className="dialog-overlay">
      <div className="dialog-overlay-content">
        <p>{dictionary.dialog_overlay_info}</p>
        <Button label={dictionary.dialog_overlay_btn_label} onClick={() => dialogProps.setShowOverlay(false)}/>
      </div>
    </div>
  );
};

export default DialogOverlay;
