import { SHOW_ELD_DIALOG } from "../actions/dialogActions";
import { HIDE_ELD_DIALOG } from "../actions/dialogActions";
import { SHOW_INT_DIALOG } from "../actions/dialogActions";
import { HIDE_INT_DIALOG } from "../actions/dialogActions";
import { SHOW_CTY_DIALOG } from "../actions/dialogActions";
import { HIDE_CTY_DIALOG } from "../actions/dialogActions";
import { SHOW_LOG_DIALOG } from "../actions/dialogActions";
import { HIDE_LOG_DIALOG } from "../actions/dialogActions";
import { SHOW_OTH_DIALOG } from "../actions/dialogActions";
import { HIDE_OTH_DIALOG } from "../actions/dialogActions";

const initDialog = {
    show: false,
    type: null,
  };
  
  const dialogReducer = (state = initDialog, action) => {
    switch (action.type) {
      case SHOW_ELD_DIALOG:
        return {
          ...state,
          show: true,
          type: 'eld',
        };
      case HIDE_ELD_DIALOG:
        return {
          ...state,
          show: false,
          type: 'eld',
        };
      case SHOW_INT_DIALOG:
        return {
          ...state,
          show: true,
          type: 'int',
        };
      case HIDE_INT_DIALOG:
        return {
          ...state,
          show: false,
          type: 'int',
        };
      case SHOW_CTY_DIALOG:
        return {
          ...state,
          show: true,
          type: 'country',
        };
      case HIDE_CTY_DIALOG:
        return {
          ...state,
          show: false,
          type: 'country',
        };
      case SHOW_LOG_DIALOG:
        return {
          ...state,
          show: true,
          type: 'logistic',
        };
      case HIDE_LOG_DIALOG:
        return {
          ...state,
          show: false,
          type: 'logistic',
        };
      case SHOW_OTH_DIALOG:
        return {
          ...state,
          show: true,
          type: 'other',
        };
      case HIDE_OTH_DIALOG:
        return {
          ...state,
          show: false,
          type: 'other',
        };
      default:
        return state;
    }
  };

  export default dialogReducer;